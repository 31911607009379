import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

interface Message {
	text: string;
	removeTimer: NodeJS.Timeout | number;
}

@autoinject
export class Notify {
	private spinnerCount = 0;
	private messages: Message[] = [];
	public unitId?: number;

	constructor(private i18n: I18N) {
	}

	info(text: string, args?: { [key: string]: any }) {
		text = this.i18n.tr(text, args);
		let lastMessage = this.messages[0];
		if (lastMessage && lastMessage.text === text) {
			// reset timer for previous message
			clearTimeout(lastMessage.removeTimer);
			lastMessage.removeTimer = setTimeout(() => this.messages = this.messages.filter(x => x.removeTimer != lastMessage.removeTimer), 5000);
			return;
		}
		let message: Message = {
			text,
			removeTimer: setTimeout(() => this.messages = this.messages.filter(p => p !== message), 5000),
		};
		this.messages.push(message);
	}

	spinner(change: number) {
		this.spinnerCount += change;
	}
}
