import { I18N } from "aurelia-i18n";
import { Client, MyHttpApi, PrivilegeItem } from "./api";

const key = "corporation-select-value";
export function getSelectedCorporation(): PrivilegeItem | undefined {
  return getPrivilege();
}

export function getSelectedClient(): number | undefined {
  const privilege = getPrivilege();
  if (privilege?.type !== "CLIENT") {
    return undefined;
  }
  return privilege?.id;
}

export function getPrivilege(): PrivilegeItem | undefined {
  let item = localStorage.getItem(key);
  if (item) {
    return <PrivilegeItem>JSON.parse(item);
  }
  return undefined;
}

export function setCorporation(item?: PrivilegeItem) {
  localStorage.setItem(key, JSON.stringify(item));
}

export interface PosItemStub {
  clientId?: number;
  businessGroupId?: number;
  corporationId?: number;
}

/** Return stub of PrivilegeItem from PosItem(element), in case we would access edit-action via diract link  */
export function privilegeItemFromElement(element: PosItemStub) {
  if (!element.clientId && !element.businessGroupId && !element.corporationId) {
    return undefined;
  }

  let privilege: PrivilegeItem = {
    id: 0,
    type: "CLIENT",
    name: "",
  };
  if (element.clientId) {
    privilege.id = element.clientId;
  } else if (element.businessGroupId) {
    privilege.id = element.businessGroupId;
    privilege.type = "BUSINESS_GROUP";
  } else if (element.corporationId) {
    privilege.id = element.corporationId;
    privilege.type = "CORPORATION";
  }
  return privilege;
}

/** Get all privilege leves, expanded to client-level and return them as list and as hashes */
export async function getAllAccessLevelsViaClient(api: MyHttpApi) {
  let [clientList, businessGroupList, corporationList] = await Promise.all([
    api.clientList(),
    api.privilegeListBusinessGroupViaClients(),
    api.privilegeListCorporationViaClients(),
  ]);
  let clients: { [key: number]: Client; } = {};
  for (let c of clientList) {
    clients[c.id] = { ...c, name: c.nickname };
  }
  let businessGroups = MyHttpApi.toHash(businessGroupList);
  let corporations = MyHttpApi.toHash(corporationList);
  return { clients, businessGroups, corporations, clientList, businessGroupList, corporationList };
}

export async function privilegeToTypeAndName(api: MyHttpApi, i18n: I18N, privilege?: PrivilegeItem) {
  const id = privilege?.id;
  const type = privilege?.type;
  if (id && type) {
    let name = "";
    if (type === 'CLIENT') {
      name = (await api.clientById({ id })).nickname;
    } else if (type === 'BUSINESS_GROUP') {
      name = (await api.businessGroupById({ id })).name;
    } else if (type === 'CORPORATION') {
      name = (await api.corporationById({ id })).name;
    }
    if (name) {
      return i18n.tr("PrivilegeType." + type) + ": " + name;
    }
  }
  return "";
}

export async function clientIdToTypeAndName(api: MyHttpApi, i18n: I18N, clientId?: number) {
  return privilegeToTypeAndName(api, i18n, { type: "CLIENT", id: clientId || 0, name: "" });
}

export function supersededText(i18n: I18N, element: string) {
  const e1 = i18n.tr(`superseded.${element}`);
  const e2 = i18n.tr(`superseded.${element}2`);
  return i18n.tr("superseded.notAllowed", { element: e1, element2: e2 });
}
