import { PortionSize, Product, ProductUpdateRequest, StorageUnit } from "utils/api";

/**
 * Round a value to two decimals, rounding half away from zero.
 * Bias must be chosen correctly to ensure the right rounding. Specify the bias
 * as 0.2e-x, where x is the number of valid decimal digits in the result,
 * e.g. roundTwoDecimals(0.01 * 0.02, 0.2e-4) when multiplying two values
 * of scale 2 together.
 * 
 * The value returned will be stringified to at most 2 decimal places, but
 * it may in itself be slightly inaccurate floating point representation of
 * the value it stringifies to.
 */
export function roundTwoDecimals(value: number, bias: number) {
	if (value < 0) {
		bias = -bias;
	}
	return Math.round(value * 100 + bias) / 100;
}


/**
 * Calculate zeroVatPercent from current price
 * @param price 
 * @param vatPercent 
 * @returns 
 */
export function calculateZeroVat(price: number | undefined, vatPercent: number | undefined): number | undefined {
	if (price == undefined) {
		return undefined;
	}
	let factor = 1 + (vatPercent || 0) / 100;
	return Math.round((price / factor + 0.2e-4) * 100) / 100;
}

export function calculatePriceWithVat(zeroVatPrice: number | undefined, vatPercent: number | undefined): number | undefined {
	if (zeroVatPrice == undefined || vatPercent == undefined) {
		return undefined;
	}
	let vat = zeroVatPrice * vatPercent / 100;
	return roundTwoDecimals(zeroVatPrice + vat, 0.2e-2);
}

export function calculateCost(
	storageUnitMap: { [key: string]: StorageUnit; },
	product: Product | ProductUpdateRequest,
	portionSize: PortionSize | undefined,
	price: number | undefined
): number | undefined {
	// This is per StorageUnit, which needs to be calculated by our portion
	if (price == undefined || product.storageUnitId == undefined || !portionSize) {
		return undefined;
	}
	let storageUnit = storageUnitMap[product.storageUnitId];
	const amount = portionSize.isStorageSize ? storageUnit.value : portionSize.amount;
	if (!storageUnit.value || amount == undefined) {
		/* Avoid dividing by 0 */
		return undefined;
	}
	return price / storageUnit.value * amount;
}

export function calculateMarginEuro(
	cost: number | undefined,
	zeroVatPrice: number | undefined
): number | undefined {
	if (cost == undefined || zeroVatPrice == undefined) {
		return undefined;
	}
	return zeroVatPrice - cost;
}

export function calculateMarginPercent(
	cost: number | undefined,
	zeroVatPrice: number | undefined
): number | undefined {
	if (cost == undefined || !zeroVatPrice) {
		return undefined;
	}
	return (1 - (cost / zeroVatPrice)) * 100;
}
