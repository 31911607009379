/* eslint-disable @typescript-eslint/no-empty-interface */
import { autoinject } from 'aurelia-framework';
import env from '../../config/environment.json';
import { Notify } from './notify';
export type ParamsType = { [key: string]: string; };

export const strategyList = [
	{ id: "FENNOA_ACCOUNTING", name: "Fennoa" },
	{ id: "NETVISOR_ACCOUNTING", name: "Netvisor" },
	{ id: "PROCOUNTOR_ACCOUNTING", name: "Procountor" },
	{ id: "TALENOM_ACCOUNTING", name: "Talenom" },
	{ id: "FIVALDI_ACCOUNTING", name: "Fivaldi" },
];

/// GENERATED BEGIN ///
export interface ADSessionByIdInternalRequest {
	sessionId: string;
}

export interface ADSessionLogin2InternalRequest {
	code: string;
	state: string;
}

export interface ADSessionOtpRequest {
	sessionId: string;
	otpCode: number;
}

export interface AbstractCommonItem {
	createActorId?: number;
	createTime: Date;
	modifyActorId?: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
}

export interface AbstractPosItem extends AbstractPosItemClientSpecific {
	businessGroupId?: number;
	corporationId?: number;
	masterId?: string;
	supersededById?: string;
}

export interface AbstractPosItemClientSpecific {
	id: string;
	createActorId?: number;
	createTime: Date;
	modifyActorId?: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	modifyClientId?: number;
	createActorPos?: string;
	modifyActorPos?: string;
	deleteActorPos?: string;
	clientId?: number;
}

export interface AccountingLatestInternalRequest {
	clientId: number;
}

export interface AccountingManualSendRequest {
	id: number;
}

export interface AccountingManualSendResponse {
	count: number;
}

export interface Actor {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	firstName: string;
	lastName: string;
	username: string;
	otpInUse: boolean;
	canAdmin: boolean;
	canBelAdmin: boolean;
	canCreateUsers: boolean;
	canBookkeepingIntegration: boolean;
	canGiftCard: boolean;
	canIntegrationSettings: boolean;
	canManifestIntegration: boolean;
	canPosItemAdmin: boolean;
	canRemoteControl: boolean;
	canSuperuser: boolean;
}

export interface ActorAdminByIdInternalRequest {
	id: number;
}

export interface ActorAdminListBusinessGroupInternalRequest {
	id: number;
}

export interface ActorAdminListClientInternalRequest {
	id: number;
}

export interface ActorAdminListCorporationInternalRequest {
	id: number;
}

export interface ActorAdminUpdateRequest {
	id?: number;
	firstName: string;
	lastName: string;
	username: string;
	canAdmin: boolean;
	canBelAdmin: boolean;
	canCreateUsers: boolean;
	canBookkeepingIntegration: boolean;
	canGiftCard: boolean;
	canIntegrationSettings: boolean;
	canPosItemAdmin: boolean;
	canManifestIntegration: boolean;
	canRemoteControl: boolean;
	canSuperuser: boolean;
	clientId: number[];
	businessGroupId: number[];
	corporationId: number[];
	delete: boolean;
}

export interface ActorListBusinessGroupInternalRequest {
	actorId: number;
}

export interface ActorListResponse {
	id: number;
	createdBy: string;
	firstName: string;
	lastName: string;
	username: string;
	clientName: string[];
	otpInUse: boolean;
	canAdmin: boolean;
	canBelAdmin: boolean;
	canCreateUsers: boolean;
	canBookkeepingIntegration: boolean;
	canGiftCard: boolean;
	canIntegrationSettings: boolean;
	canManifestIntegration: boolean;
	canRemoteControl: boolean;
	canPosItemAdmin: boolean;
	canSuperuser: boolean;
	hasPassword: boolean;
}

export interface ActorListWithAccessByClientIdInternalRequest {
	id: number;
}

export interface ActorOtherByIdInternalRequest {
	id: number;
}

export interface ActorWithAccessResponse {
	actorId: number;
	username: string;
	createActorId?: number;
	viaClient: boolean;
	viaBusinessGroup: boolean;
	viaCorporation: boolean;
}

export interface ActorWithPermissionsResponse {
	actor: Actor;
	units: IdName[];
	groups: BusinessGroupsWithUnits[];
	corporations: CorporationWithGroups[];
	fullPermissions: IdName[];
	canDelete: boolean;
}

export type AliasType = "PRODUCT" | "PRODUCT_CATEGORY";

export interface ArchiveRequest {
	id: number;
}

export interface Area extends AbstractPosItemClientSpecific {
	locationId: string;
	billPrinterId?: string;
	name: string;
	requestPersonCountForNewTable: boolean;
	signOutAfterSave: boolean;
	closeBillsManually: boolean;
	signOutAfterIdleMinutes?: number;
	hidePersonChoice: boolean;
	eanInput: boolean;
	doNotPrintReceipts: boolean;
	freeSaleAllowed: boolean;
	lastReceiptInFrontPage: boolean;
	lastReceiptAskForEmail: boolean;
	tableReservationDefaults: number[];
	viewChangeDisallowed: boolean;
	hideTerminalStatusList: boolean;
	disallowPosUseDuringTransaction: boolean;
	terminalStatusListOrientation: PosTerminalStatusListOrientation;
	defaultView: PosAreaDefaultView;
}

export interface AreaByIdInternalRequest {
	id: string;
}

export interface AreaByIdWithPaymentTerminalsInternalRequest {
	id: string;
}

export interface AreaListForBusinessGroupInternalRequest {
	businessGroupId: number;
}

export interface AreaListInternalRequest {
	id?: number;
}

export interface AreaListWithStationsByLocationIdInternalRequest {
	locationId: string;
}

export interface AreaUpdateRequest extends PosItemUpdateRequestBase {
	locationId: string;
	billPrinterId?: string;
	name: string;
	requestPersonCountForNewTable: boolean;
	signOutAfterSave: boolean;
	closeBillsManually: boolean;
	signOutAfterIdleMinutes?: number;
	hidePersonChoice: boolean;
	eanInput: boolean;
	doNotPrintReceipts: boolean;
	lastReceiptInFrontPage: boolean;
	lastReceiptAskForEmail: boolean;
	freeSaleAllowed: boolean;
	tableReservationDefaults: number[];
	viewChangeDisallowed: boolean;
	hideTerminalStatusList: boolean;
	disallowPosUseDuringTransaction: boolean;
	terminalStatusListOrientation: PosTerminalStatusListOrientation;
	defaultView: PosAreaDefaultView;
}

export interface AreaWithPaymentTerminals {
	area: Area;
	paymentTerminalClientIds: UuidName[];
}

export interface AreaWithStations {
	area: Area;
	stations: Station[];
}

export interface AuditMemoExpense extends ClientAccountingExpense {
	businessDate: Date;
}

export interface AuditMemoExpenses {
	total: number;
	expenseList: AuditMemoExpense[];
}

export interface AuditMemoGiftCardDebt {
	creditTotal: number;
	debitTotal: number;
	rows: AuditMemoGiftCardRow[];
}

export interface AuditMemoGiftCardRow {
	debitClient?: string;
	debit?: number;
	creditClient?: string;
	credit?: number;
}

export interface AuditMemoGiftPurchasedGiftCard {
	paymentType: PosPaymentType;
	total: number;
}

export interface AuditMemoPurchasedGiftCards {
	total: number;
	giftCardPurchases: AuditMemoGiftPurchasedGiftCard[];
}

export interface AuditMemoSalesAndPaymentTypeRow {
	paymentType?: PosPaymentType;
	paymentTypeTotal?: number;
	category?: string;
	categoryTotal?: number;
}

export interface AuditMemoSalesAndPaymentTypes {
	salesTotal: number;
	paymentTypesTotal: number;
	rows: AuditMemoSalesAndPaymentTypeRow[];
}

export interface BackupMeta {
	name: string;
	backupTime: Date;
	backupSize: number;
}

export interface BillingUpdateBusinessDateRequest {
	id: number;
	businessDate: Date;
}

export interface BillingUpdateRequest {
	id: number;
	createTime?: Date;
	bookkeepingId?: number;
	billingCustomerName?: string;
	billingCustomerReference?: string;
	invoiceAddress?: string;
	invoiceOperator?: string;
	billingName?: string;
	billingOfficialName?: string;
	businessId?: string;
	billingName2?: string;
	billingAddress?: string;
	billingZip?: string;
	billingCity?: string;
	billingCountry?: string;
	billingInternalInfo?: string;
}

export interface BookkeepingAuditMemoResponse {
	missingAccountingsDates: Date[];
	unsentSalesInvoices: number;
	hiddenSalesInvoiceSum: number;
	unsentCombinedBilling: number;
	salesAndPaymentTypes: AuditMemoSalesAndPaymentTypes;
	expenses: AuditMemoExpenses;
	giftCards: AuditMemoPurchasedGiftCards;
	giftCardDebt: AuditMemoGiftCardDebt;
}

export interface BookkeepingComponentInvoice {
	id: number;
	clientId: number;
	businessDate: Date;
	billId: number;
	paymentTotal: number;
	paymentType: PosPaymentType;
	customerName: string;
	customerBusinessId: string;
	billingList: BookkeepingComponentInvoiceRow[];
	clientBilling: ClientBilling;
	productMessage?: string;
	combinedBillingSummary?: string;
}

export interface BookkeepingComponentInvoiceRow {
	productId: string;
	productCategoryName: string;
	productName: string;
	plainProductName: string;
	productRowMessage?: string;
	amount: number;
	total: number;
	price: number;
	vatPercent: number;
	discountPercentage?: number;
	discountAmount?: number;
	discountGroupName?: string;
	useRealQuantity: boolean;
}

export interface BookkeepingCustomer {
	id: number;
	targetId: number;
	bookkeepingId: number;
	createTime: Date;
	version?: Date;
	deleteTime?: Date;
	businessId?: string;
	officialName?: string;
	name?: string;
	additionalName?: string;
	streetAddress?: string;
	zip?: string;
	city?: string;
	country?: string;
	contactPerson?: string;
	invoiceAddress?: string;
	invoiceOperator?: string;
	ovt?: string;
	invoiceChannel?: InvoiceChannel;
	language?: string;
	paymentTermDays?: number;
	paymentTermId?: string;
	agreementIdentifier?: string;
}

export interface BookkeepingCustomerApiVsDbDiffExcelInternalRequest {
	targetId: number;
	lang: string;
}

export interface BookkeepingCustomerApiVsDbDiffInternalRequest {
	targetId: number;
}

export interface BookkeepingCustomerByIdInternalRequest {
	id: number;
}

export interface BookkeepingCustomerCombinedInvoices {
	id: number;
	clientId: number;
	customerId: number;
	combinedInvoices: boolean;
	combinedInvoiceType?: CombinedInvoiceType;
}

export interface BookkeepingCustomerCombinedInvoicesByCustomerAndClientInternalRequest {
	customerId: number;
	clientId: number;
}

export interface BookkeepingCustomerCombinedInvoicesByCustomerInternalRequest {
	id?: number;
	targetId: number;
}

export interface BookkeepingCustomerDiff {
	customer: BookkeepingCustomer;
	source: Source;
	key1?: string;
	key2?: string;
	key3?: string;
	key4?: string;
	dbDuplicateMatchLevel?: number;
	dbDublicateCustomer?: BookkeepingCustomer;
	apiDuplicateMatchLevel?: number;
	apiDublicateCustomer?: BookkeepingCustomer;
	differentSourceDublicateCustomer?: BookkeepingCustomer;
	differentSourceDuplicateMatchLevel?: number;
	compareResult: CompareResult;
}

export interface BookkeepingCustomerListByClientWithCombinedBillingInternalRequest {
	clientId: number;
}

export interface BookkeepingCustomerListByTargetInternalRequest {
	targetId: number;
}

export interface BookkeepingCustomerListByTargetWithCombinedBillingInternalRequest {
	targetId: number;
}

export interface BookkeepingCustomerListInternalRequest {
	clientBillingId: number;
}

export interface BookkeepingInvoice {
	invoiceDate: Date;
	invoiceMessage: string;
	customer?: BookkeepingCustomer;
	invoiceBillingInfo?: InvoiceBillingInfo;
	cbList: BookkeepingComponentInvoice[];
	invoiceType: InvoiceType;
}

export interface BookkeepingInvoicePreview extends BookkeepingInvoice {
	cbMain: ClientBilling;
	cttmId?: number;
	lastSendTime?: Date;
	total: number;
	invoiceCount: number;
	rowCount: number;
	customerName?: string;
	createTime: Date;
	status: CombinedBillingSendStatus;
	clientId: number;
}

export interface BusinessGroup extends BusinessGroupRestrictions {
	id: number;
	createActorId: number;
	createTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	name: string;
	corporationId?: number;
	clientSpecificGiftCards: boolean;
}

export interface BusinessGroupByIdInternalRequest {
	id: number;
}

export interface BusinessGroupGiftCardDebtAccount {
	businessGroupId: number;
	clientId: number;
	creditAccount: number;
	debitAccount: number;
}

export interface BusinessGroupGiftCardDebtListResponse {
	nickname: string;
	creditAccount: number;
	debitAccount: number;
}

export interface BusinessGroupInfo {
	businessGroupId: number;
	info: string;
}

export interface BusinessGroupInfoByIdInternalRequest {
	id: number;
}

export interface BusinessGroupListByCorporationIdInternalRequest {
	id: number;
}

export interface BusinessGroupListGiftCardDebtAccountByIdInternalRequest {
	id: number;
}

export interface BusinessGroupReadOnlyListGiftCardDebtAccountByClientIdInternalRequest {
	id: number;
}

export interface BusinessGroupRestrictions {
	clientSpecificAliases: boolean;
	clientSpecificCategories: boolean;
	clientSpecificOrderLists: boolean;
	clientSpecificPortions: boolean;
	clientSpecificRecipes: boolean;
	clientSpecificSalesChannels: boolean;
	clientSpecificStorageUnits: boolean;
	clientSpecificSubCategories: boolean;
	clientSpecificPricingGroups: PosPermissionType;
	clientSpecificProducts: PosPermissionType;
	clientSpecificSalesRestrictions: PosPermissionType;
}

export interface BusinessGroupRestrictionsByClientIdInternalRequest {
	clientId?: number;
}

export interface BusinessGroupUpdateGiftCardDebtAccountsRequest {
	id: number;
	giftCardDebtAccounts: BusinessGroupGiftCardDebtAccount[];
}

export interface BusinessGroupUpdateRequest {
	id?: number;
	name: string;
	info?: string;
	delete: boolean;
	clientSpecificGiftCards: boolean;
	clientSpecificAliases: boolean;
	clientSpecificCategories: boolean;
	clientSpecificOrderLists: boolean;
	clientSpecificPortions: boolean;
	clientSpecificRecipes: boolean;
	clientSpecificSalesChannels: boolean;
	clientSpecificStorageUnits: boolean;
	clientSpecificSubCategories: boolean;
	clientSpecificPricingGroups: PosPermissionType;
	clientSpecificProducts: PosPermissionType;
	clientSpecificSalesRestrictions: PosPermissionType;
	clientId: number[];
	giftCardDebtAccounts: BusinessGroupGiftCardDebtAccount[];
}

export interface BusinessGroupsWithUnits extends IdName {
	units: string;
}

export interface ChangePasswordRequest {
	m: string;
	password: string;
}

export interface ChangePasswordWithSessionRequest {
	oldPassword: string;
	password: string;
}

export interface Client {
	id: number;
	createActorId: number;
	createTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	name: string;
	nickname: string;
	zip: string;
	city: string;
	belServer?: string;
	clientType: ClientType;
	ipAddress?: string;
	onPremisesIpAddress: string;
	startDate: Date;
	transferHour: number;
	accountingTransferDelay: number;
	monthlyAccounting: boolean;
	canUpdateToCanary: boolean;
	targetBranch?: string;
	updateJava: boolean;
	updatePostgresql: boolean;
	updateNginxConf: boolean;
	updateServiceAndSocket: boolean;
	businessGroupId?: number;
	hidden: boolean;
	emergencyUpdateRepositoryId?: number;
	javaMemory: number;
	psqlMemory: number;
	throttleBackup: boolean;
}

export interface ClientAccounting {
	id: number;
	deleteActorId?: number;
	deleteTime?: Date;
	clientId: number;
	businessDate: Date;
	locationName: string;
	accountingId: number;
	name: string;
}

export interface ClientAccountingExpense {
	clientAccountingId: number;
	billId: number;
	total: number;
}

export interface ClientActiveModuleByClientIdAndTypeInternalRequest {
	clientId: number;
	type: ModuleType;
}

export interface ClientAddModuleRequest extends ClientGenericRequest {
	moduleType: ModuleType;
}

export interface ClientAndModules {
	client: Client;
	clientModule: ClientModule[];
	clientTransferTarget?: ClientTransferTarget;
}

export interface ClientBilling {
	id: number;
	completedActorId?: number;
	completedTime?: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	clientId: number;
	locationId?: string;
	businessDate: Date;
	customerName: string;
	customerBusinessId: string;
	locationName: string;
	accountingId: number;
	billId: number;
	paymentType: PosPaymentType;
	paymentTotal: number;
	createTime?: Date;
	bookkeepingId?: number;
	billingCustomerName?: string;
	billingCustomerReference?: string;
	invoiceAddress?: string;
	invoiceOperator?: string;
	billingName?: string;
	billingOfficialName?: string;
	businessId?: string;
	billingName2?: string;
	billingAddress?: string;
	billingZip?: string;
	billingCity?: string;
	billingCountry?: string;
	billingInternalInfo?: string;
}

export interface ClientByIdInternalRequest {
	id: number;
}

export interface ClientCertificate {
	id: number;
	startDate: Date;
	expiryDate: Date;
	clientId: number;
	deleteTime?: Date;
}

export interface ClientClientHealthAndRepositoriesByClientIdInternalRequest {
	id: number;
}

export interface ClientDataContainer {
	maxTime: Date;
	storageUnitList: StorageUnit[];
	productCategoryList: ProductCategory[];
	productSubCategoryList: ProductSubCategory[];
	portionSizeList: PortionSize[];
	salesChannel: SalesChannel[];
	salesRestrictionList: SalesRestriction[];
	salesRestrictionRows: SalesRestrictionTime[];
	pricingGroupList: PricingGroup[];
	pricingGroupRows: PricingGroupRow[];
	productList: Product[];
	productProductList: ProductProduct[];
	productImageList: ProductImage[];
	productPricingGroupList: ProductPricingGroup[];
	productPurchasePriceList: ProductPurchasePrice[];
	paymentTerminalClientList: PaymentTerminalClient[];
	paymentTerminalMap: { [key: number]: PaymentTerminal };
	paymentTerminalModelMap: { [key: number]: PaymentTerminalModel };
	paymentTerminalClientAreaList: PaymentTerminalClientArea[];
	printerList: Printer[];
	locationList: Location[];
	locationLogoList: LocationLogo[];
	areaList: Area[];
	stationList: Station[];
	vivaWalletPaymentInfoList: VivaWalletPaymentInfo[];
	orderListList: OrderList[];
	orderListPrinterList: OrderListPrinter[];
	orderListAreaList: OrderListArea[];
	orderListStationList: OrderListStation[];
	orderListProductCategoryList: OrderListProductCategory[];
	orderListProductSubCategoryList: OrderListProductSubCategory[];
	orderMessageList: OrderMessage[];
	orderMessageGroupList: OrderMessageGroup[];
	orderMessageGroupOrderMessageList: OrderMessageGroupOrderMessage[];
	productOrderMessageGroupList: ProductOrderMessageGroup[];
	vivaWalletFee: VivaWalletFee;
	vatCategoryList: VatCategory[];
	vatCategoryRowList: VatCategoryRow[];
	keyMap: { [key: string]: string };
}

export interface ClientEmergencyUpdateRequest {
	emergencyUpdateRepositoryId: number;
	clientIds: number[];
}

export interface ClientGenericRequest {
	id: number;
}

export interface ClientHealth {
	client: Client;
	health?: Health;
}

export interface ClientHealthAndRepositories extends HealthResponse {
	client: Client;
	updateWindowList: ClientUpdateWindow[];
	serialNumber?: string;
	currentRepository?: Repository;
	targetRepository?: Repository;
	nextMinorRepository?: Repository;
	nextPatchRepository?: Repository;
	nextUpdate?: Date;
	canUpgradeClient: boolean;
	isUpgradeSafe: boolean;
}

export interface ClientHealthAndRepositoriesWithCertificatesInternalRequest {
	id: number;
}

export interface ClientHealthMemoryByClientIdInternalRequest {
	id: number;
}

export interface ClientHttpsCertificate {
	id: number;
	startDate: Date;
	expiryDate: Date;
	ipAddressList: string[];
}

export interface ClientInfo {
	clientId: number;
	info: string;
}

export interface ClientInfoByIdInternalRequest {
	id: number;
}

export interface ClientIntegration {
	clientId: number;
	targetId: number;
	procountorDimensionId?: number;
	procountorDimensionItemId?: number;
	fennoaDimensionTypeId?: number;
	fennoaDimensionCode?: string;
	fennoaStatementSeriesCode?: string;
	penaltyPercent?: number;
	talenomDimensionKey?: string;
	talenomDimensionType?: TalenomDimensionType;
	paymentTime: number;
	fivaldiVoucherTypeId?: number;
	fivaldiPaymentTermId?: string;
	fivaldiPostingGroupId?: string;
	iban?: string;
	completionNotificationEmail?: string;
	completionNotificationLanguage?: Language;
	autoSendCompletedInvoices: boolean;
	autoSendCompletedInvoicesToCustomer: boolean;
	fennoaSalesInvoiceSeriesId?: number;
	talenomIndex?: number;
	waybillVersion?: Date;
	waybillError?: WaybillError;
}

export interface ClientIntegrationAndDependenciesRequest {
	integration: ClientIntegrationUpdateRequest;
	dependencies: ClientTransferTargetDependenciesUpdateRequest;
}

export interface ClientIntegrationByClientIdInternalRequest {
	clientId: number;
}

export interface ClientIntegrationListConfigMismatchInternalRequest {
	targetId: number;
	adminClientId?: number;
	adminBusinessGroupId?: number;
}

export interface ClientIntegrationUpdateRequest {
	clientId: number;
	targetId: number;
	procountorDimensionId?: number;
	procountorDimensionItemId?: number;
	fennoaDimensionTypeId?: number;
	fennoaDimensionCode?: string;
	fennoaStatementSeriesCode?: string;
	penaltyPercent?: number;
	talenomDimensionKey?: string;
	talenomDimensionType?: TalenomDimensionType;
	talenomIndex?: number;
	paymentTime: number;
	iban?: string;
	completionNotificationEmail?: string;
	completionNotificationLanguage?: Language;
	autoSendCompletedInvoices: boolean;
	autoSendCompletedInvoicesToCustomer: boolean;
	fennoaSalesInvoiceSeriesId?: number;
	fivaldiPaymentTermId?: string;
	fivaldiVoucherTypeId?: number;
	fivaldiPostingGroupId?: string;
}

export interface ClientListBackupsInternalRequest {
	id: number;
}

export interface ClientListByBusinessGroupIdInternalRequest {
	id: number;
}

export interface ClientListModuleInternalRequest {
	id: number;
}

export interface ClientLocationNamesInternalRequest {
	clientId: number;
}

export interface ClientMakeRequest extends ClientUpdateableFields {
	name: string;
}

export interface ClientMigration {
	id: number;
	createActorId: number;
	createTime: Date;
	clientId: number;
	migrationStartTime?: Date;
	migrationReadyTime?: Date;
	migrationFinishTime?: Date;
	finishTime?: Date;
}

export interface ClientMigrationCanMigrateResponse {
	currentlyOngoingMigration?: ClientMigration;
	hasBillStation: boolean;
	amountPremultipied: boolean;
}

export interface ClientMigrationRequest {
	clientId: number;
}

export interface ClientModule {
	id: number;
	createActorId?: number;
	createTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	clientId: number;
	moduleType: ModuleType;
}

export interface ClientModuleByClientIdAndTypeInternalRequest {
	clientId: number;
	type: ModuleType;
}

export interface ClientPosItemRunnableDataContainerDebugInternalRequest {
	id: number;
}

export interface ClientPosItemRunnableRequestDebugInternalRequest {
	id: number;
}

export interface ClientReplaceHttpsCertificateRequest {
	clientId: number;
}

export interface ClientRestoreFromFileRequest {
	id: number;
	data: string;
}

export interface ClientRestoreRequest {
	id: number;
	name: string;
}

export interface ClientSaleAccountingFetchRequest {
	clientId: number;
	minAccountingId: number;
	businessDate?: Date;
}

export interface ClientSaleBillingAndMeta {
	cb: ClientBilling;
	cttm?: ClientTransferTargetMessage;
	deleteActor?: string;
	completeActor?: string;
	mappedIntegrationErrorMessage?: IntegrationErrorMessageMapper;
	sentOrStillSending: boolean;
	pdf: string;
}

export interface ClientSaleBillingBillingAndMetaByIdInternalRequest {
	id: number;
}

export interface ClientSaleBillingCttmInternalRequest {
	id: number;
}

export interface ClientSaleBillingDeleteCttmRequest {
	id: number;
}

export interface ClientSaleBillingFetchRequest {
	clientId: number;
	businessDate: Date;
	deleteDashboardCopy: boolean;
}

export interface ClientSaleBillingLatestInternalRequest {
	clientId: number;
}

export interface ClientSaleBillingSentOrStillSendingInternalRequest {
	id: number;
}

export interface ClientSaleDeleteAccountingRequest {
	id: number;
}

export interface ClientSaveUpdateWindowRequest {
	clientId: number;
	updateWindowList: ClientSaveUpdateWindowRow[];
}

export interface ClientSaveUpdateWindowRow {
	weekday: DayOfWeek;
	startTime: string;
	endTime: string;
}

export interface ClientSettingsUpdateRequest {
	id: number;
	transferHour: number;
	accountingTransferDelay: number;
	monthlyAccounting: boolean;
}

export interface ClientState {
	stateTime: Date;
	state: HealthState;
	repositoryId?: number;
}

export interface ClientStateHistoryInternalRequest {
	id: number;
}

export interface ClientStatusResponse {
	chr: ClientHealthAndRepositories;
	httpsCertificate?: ClientHttpsCertificate;
	openvpnCertificate?: ClientCertificate;
}

export interface ClientTargetBranchUpdateRequest {
	targetBranch?: string;
	clientIds: number[];
}

export interface ClientTransferTarget {
	id: number;
	adminClientId?: number;
	adminBusinessGroupId?: number;
	deleteActorId?: number;
	deleteTime?: Date;
	name: string;
	strategy: IntegrationStrategy;
	fivaldiCompanyCuid?: string;
	username?: string;
	password?: string;
	talenomClientId?: number;
	talenomInvoiceUsername?: string;
	talenomInvoicePassword?: string;
	businessId?: string;
	active: boolean;
	oauthAccessTokenExpires?: Date;
	oauthRefreshTokenFetched?: Date;
	fennoaTempActive: boolean;
	bookkeepingCustomerLastUpdate?: Date;
	paperTransmissionTypeId: string[];
	emailTransmissionTypeId: string[];
	einvoiceTransmissionTypeId: string[];
}

export interface ClientTransferTargetAndDependenciesRequest {
	target: ClientTransferTargetUpdateRequest;
	dependencies: ClientTransferTargetDependenciesUpdateRequest;
}

export interface ClientTransferTargetByIdForAdministrationInternalRequest {
	id: number;
}

export interface ClientTransferTargetByIdInternalRequest {
	id: number;
}

export interface ClientTransferTargetDeleteRequest {
	id: number;
}

export interface ClientTransferTargetDependenciesInternalRequest {
	targetId?: number;
	clientId?: number;
}

export interface ClientTransferTargetDependenciesResponse {
	cttPaymentType: ClientTransferTargetPaymentType[];
	cttProductCategory: ClientTransferTargetProductCategory[];
}

export interface ClientTransferTargetDependenciesUpdateRequest {
	cttPaymentType: ClientTransferTargetPaymentTypeUpdateRequest[];
	cttProductCategory: ClientTransferTargetProductCategoryUpdateRequest[];
}

export interface ClientTransferTargetListClientInternalRequest {
	id: number;
}

export interface ClientTransferTargetListSelectableInternalRequest {
	clientId?: number;
}

export interface ClientTransferTargetMessage {
	id: number;
	targetId: number;
	clientId: number;
	messageType: MessageType;
	createTime: Date;
	deleteTime?: Date;
	clientAccountingId?: number;
	clientBillingId?: number;
	message?: string;
	attemptCount: number;
	attemptTime?: Date;
	status?: string;
	finishTime?: Date;
	combinedParentId?: number;
	procountorLedgerReceiptId?: number;
}

export interface ClientTransferTargetPaymentType {
	id: number;
	targetId: number;
	clientId?: number;
	paymentType: PosPaymentType;
	account: number;
}

export interface ClientTransferTargetPaymentTypeUpdateRequest {
	paymentType: PosPaymentType;
	account: number;
	clientId?: number;
}

export interface ClientTransferTargetProductCategory {
	id: number;
	targetId: number;
	clientId?: number;
	productCategory: string;
	account: number;
	productId?: string;
}

export interface ClientTransferTargetProductCategoryUpdateRequest {
	productCategory: string;
	account: number;
	productId?: string;
	clientId?: number;
	unitId?: string;
}

export interface ClientTransferTargetUpdateRequest {
	id?: number;
	adminClientId?: number;
	adminBusinessGroupId?: number;
	delete: boolean;
	name: string;
	strategy: IntegrationStrategy;
	username?: string;
	password?: string;
	talenomClientId?: number;
	talenomInvoiceUsername?: string;
	talenomInvoicePassword?: string;
	businessId?: string;
	active: boolean;
	oauthAccessToken?: string;
	oauthAccessTokenExpires?: Date;
	oauthRefreshToken?: string;
	fennoaTempActive: boolean;
	fivaldiCompanyCuid?: string;
	paperTransmissionTypeId: string;
	emailTransmissionTypeId: string;
	einvoiceTransmissionTypeId: string;
	bookkeepingCustomerLastUpdate?: Date;
}

export interface ClientTransfertTargetManualByClientId {
	clientId: number;
}

export type ClientType = "PROD" | "TEST" | "DEBUG";

export interface ClientUnit {
	client: Client;
	state?: HealthState;
	tuloposVersion?: string;
	clientModule: ClientModule[];
	nextUpdate?: Date;
	targetRepositoryString?: string;
}

export interface ClientUnitListResponse {
	clientId: number;
	name: string;
	state: HealthState;
	repositoryVersion: string;
	canRemoteControl: boolean;
}

export interface ClientUpdateRequest extends ClientUpdateableFields {
	id: number;
	hidden: boolean;
	throttleBackup: boolean;
	startDate: Date;
}

export interface ClientUpdateWindow {
	clientId: number;
	weekday: DayOfWeek;
	startTime: string;
	endTime: string;
}

export interface ClientUpdateableFields {
	nickname: string;
	zip: string;
	city: string;
	onPremisesIpAddress: string;
	canUpdateToCanary: boolean;
	updateJava: boolean;
	updatePostgresql: boolean;
	updateNginxConf: boolean;
	updateServiceAndSocket: boolean;
	info?: string;
	javaMemory: number;
	psqlMemory: number;
}

export interface ClientUpgradeRequest {
	repositoryId: number;
	currentRepositoryId?: number;
	clientId: number;
}

export interface CombinedBillingCancelOrResendRequest {
	cttmId: number;
}

export interface CombinedBillingErrorMessageInternalRequest {
	cttmId: number;
}

export interface CombinedBillingErrorMessageResponse {
	cttmId: number;
	status: string;
	mappedIntegrationErrorMessage?: IntegrationErrorMessageMapper;
}

export interface CombinedBillingListReadyRequest {
	clientId?: number;
	customerId?: number;
	startDate?: Date;
	endDate?: Date;
	status?: CombinedBillingSendStatus;
}

export interface CombinedBillingListRequest {
	clientId: number;
	customerId?: number;
	status?: CombinedBillingSendStatus;
	locationId?: string;
	startDate: Date;
	endDate: Date;
}

export interface CombinedBillingLocationListInternalRequest {
	clientId: number;
}

export type CombinedBillingSendStatus = "OK" | "SENDING" | "ERROR" | "NOT_FORMED";

export type CombinedInvoiceType = "DETAILED" | "SUMMARY";

export interface CommonItemUpdateRequestBase {
	delete: boolean;
}

export interface CompareResult {
	matchingColumns: string[];
	nonMatchingColumns: string[];
	invoiceInfoMatches?: boolean;
}

export interface Corporation {
	id: number;
	createActorId: number;
	createTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	name: string;
}

export interface CorporationByIdInternalRequest {
	id: number;
}

export interface CorporationInfo {
	corporationId: number;
	info: string;
}

export interface CorporationInfoByIdInternalRequest {
	id: number;
}

export interface CorporationUpdateRequest {
	id?: number;
	name: string;
	info?: string;
	delete: boolean;
	businessGroupId: number[];
}

export interface CorporationWithGroups extends IdName {
	groups: string;
}

export interface Country {
	cc3: string;
	cc2: string;
	fi?: string;
	sv?: string;
	en?: string;
	procountorName?: string;
}

export interface CustomerExcelUploadRequest {
	targetId: number;
	file: string;
}

export interface CustomerUpdateRequest {
	id?: number;
	bookkeepingId?: number;
	targetId: number;
	businessId?: string;
	officialName?: string;
	name?: string;
	additionalName?: string;
	streetAddress?: string;
	zip?: string;
	city?: string;
	country?: string;
	invoiceAddress?: string;
	invoiceOperator?: string;
	combinedInvoicesList: BookkeepingCustomerCombinedInvoices[];
	paymentTermDays?: number;
	language?: string;
	agreementIdentifier?: string;
	delete: boolean;
}

export interface DashboardTaskStatusResponse {
	runtimeData: TaskRuntimeData<void>;
	lastFailedThrowable?: string;
	task?: DashboardTaskType;
	isRunning: boolean;
}

export type DashboardTaskType = "NETTIKAUPPA_GIFTCARD" | "HEALTH_CHECK" | "CLIENT_FEATURE" | "DAILY_SALES" | "DASHBOARD_MAINTENANCE" | "CLIENT_BACKUP" | "CLIENT_MAINTENANCE" | "BOOKKEEPING_IMPORT" | "BOOKKEEPING_BUILDER" | "BOOKKEEPING_CUSTOMER_IMPORT" | "BOOKKEEPING_NOTIFICATION" | "BOOKKEEPING_EXPORT" | "MANIFEST_BUILDER" | "MANIFEST_EXPORT" | "INVOICING" | "V1632_MANUAL_MIGRATOR" | "POS_GIFTCARD" | "POS_ITEM" | "PG_NOTIFY" | "WAYBILL" | "WAYBILL_INVOICE";

export type DayOfWeek = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";

export interface Dimension {
	id: number;
	name: string;
	items: DimensionItem[];
}

export interface DimensionItem {
	id: number;
	codeName: string;
	status: string;
	description: string;
}

export interface DisableOtpRequest {
	id: number;
}

export interface EanLinkRequest {
	ean: string;
	name?: string;
	storageUnitId?: number;
}

export type EmailPurpose = "AccountingFailedToGenerate" | "AccountingInTransit" | "BillingFailedToGenerate" | "BillingInTransit" | "BillingUncompleted" | "ForgotPassword" | "NewUserWelcome" | "GiftCardEmailDelivery" | "GroupedGiftCardEmailDelivery" | "InitOtp";

export interface EmailTemplate {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	businessGroupId?: number;
	purpose: EmailPurpose;
	language: EmailTemplateLanguage;
	subject: string;
	text: string;
	replyTo?: string;
}

export interface EmailTemplateByIdInternalRequest {
	id: number;
	asUri: boolean;
}

export interface EmailTemplateGetImgInternalRequest {
	sha256: string;
}

export type EmailTemplateLanguage = "FI" | "EN" | "BI";

export interface EmailTemplateUpdateRequest {
	id?: number;
	businessGroupId?: number;
	giftCardProductId?: number;
	purpose: EmailPurpose;
	language: EmailTemplateLanguage;
	subject: string;
	text: string;
	replyTo?: string;
	delete: boolean;
}

export interface ExcelUpdateRequest {
	excel: string;
}

export interface FennoaDimensionCode {
	id: number;
	code: string;
	description: string;
	name_fi: string;
	is_active: number;
	active_after: boolean;
}

export interface FennoaDimensionCodeContainer {
	Dimension: FennoaDimensionCode;
	DimensionType: FennoaDimensionType;
}

export interface FennoaDimensionType {
	id: number;
	description: string;
	name_fi: string;
	is_active: number;
}

export interface FennoaDimensionTypeContainer {
	DimensionType: FennoaDimensionType;
}

export interface FennoaGetCodeDimensionsInternalRequest {
	targetId: number;
}

export interface FennoaGetTypeDimensionsInternalRequest {
	targetId: number;
}

export interface FennoaVat {
	taxPercent: number;
	account: number;
}

export interface FinalizeOTPInitRequest {
	m: string;
	otpCode?: number;
	lang: string;
}

export interface FinalizeOTPInitResponse {
	otpSecret: string;
	data: string;
	inUse: boolean;
}

export interface FivaldiAccount extends FivaldiDimension {
	account: string;
}

export interface FivaldiAccountListInternalRequest {
	targetId: number;
}

export interface FivaldiBookkeepingCustomer {
	customerId: number;
	businessId?: string;
	customerName?: string;
	customerName2?: string;
	email?: string;
	email2?: string;
	phoneNumber?: string;
	externalId?: string;
	changeTime?: Date;
}

export interface FivaldiBookkeepingCustomerListInternalRequest {
	targetId: number;
	fromDate?: Date;
}

export interface FivaldiCompany {
	companyId: number;
	cuid: string;
	customerId: string;
	name1?: string;
	changeTime: Date;
}

export interface FivaldiCompanyInvoicingDetail {
	paymentTermDTOList: FivaldiPaymentTermDTO[];
	transmissionTypeDTOList: FivaldiTransmissionTypeDTO[];
	unitDTOList: FivaldiUnitDTO[];
}

export interface FivaldiCompanyInvoicingDetailsInternalRequest {
	targetId: number;
}

export interface FivaldiCompanyListAdminInternalRequest {
	customerId: string;
}

export interface FivaldiCompanyListInternalRequest {
	targetId: number;
}

export interface FivaldiCustomer {
	customerId: string;
	name1?: string;
	name2?: string;
	businessId?: string;
}

export interface FivaldiDimension {
	dimension: string;
	description: string;
	inUseBookkeeping: boolean;
	inUseSales: boolean;
}

export interface FivaldiPaymentTermDTO {
	paymentTermId: number;
	discountDates: number;
	discountPercent: number;
	netDueDate: number;
	defaultPaymentTerm: boolean;
	description: string;
}

export interface FivaldiProduct {
	productCode: string;
	description: string;
	eanCode: string;
}

export interface FivaldiProductListInternalRequest {
	targetId: number;
}

export interface FivaldiSalesPostingGroupListInternalRequest {
	targetId: number;
}

export interface FivaldiTransmissionTypeDTO {
	transmissionId: string;
	description: string;
	defaultTransmissionTypeId: boolean;
}

export interface FivaldiUnitDTO {
	unitId: string;
	unit: string;
	description?: string;
}

export interface FivaldiVoucherType {
	id: number;
	usage: string;
	description: string;
}

export interface FivaldiVoucherTypeListInternalRequest {
	targetId: number;
}

export interface ForgotPasswordGetUsernameChangeInternalRequest {
	m: string;
}

export interface ForgotPasswordGetUsernameSetInternalRequest {
	m: string;
}

export interface ForgotPasswordRequest {
	email: string;
	lang: EmailTemplateLanguage;
}

export interface ForgotPasswordUsernameResponse {
	username: string;
}

export interface FullInvoicingSnapshot {
	snapshot: InvoicingSnapshot;
	servers: InvoicingSnapshotServer[];
	terminatedClients: InvoicingSnapshotTerminatedClient[];
	clients: InvoicingSnapshotClient[];
}

export interface GetHelpRelated {
	identifier: string;
	title: string;
}

export interface GetHelpResult {
	title: string;
	html: string;
	related: GetHelpRelated[];
}

export interface GiftCard {
	id: number;
	createTime: Date;
	modifyTime: Date;
	number: string;
	deleteActorId?: number;
	deleteTime?: Date;
	purchasedAtClientId: number;
	purchasedAtSystem: GiftCardSystem;
	purchasedAtReference: string;
	notAfter: Date;
	purchaseValue: number;
	purchaserEmail: string;
	productId: number;
	productName: string;
	productDescription?: string;
	posOrderId?: number;
	nkOrderRowId?: number;
	firstName?: string;
	lastName?: string;
	physical: boolean;
	freeText?: string;
}

export interface GiftCardAuthorizationHold {
	id: number;
	giftCardId: number;
	clientId: number;
	clientAccountingId?: number;
	createTime: Date;
	modifyTime: Date;
	system: GiftCardSystem;
	reference: string;
	businessDate: Date;
	value: number;
}

export interface GiftCardAuthorizationHoldListInternalRequest {
	id: number;
}

export interface GiftCardByIdInternalRequest {
	id: number;
}

export type GiftCardEventType = "DELIVERY_EMAIL" | "DELIVERY_DOWNLOAD" | "HTTP_ACCESS" | "PURCHASE" | "UPDATE" | "USE" | "REFUND" | "EXPIRE";

export interface GiftCardExcelUploadRequest {
	clientId: number;
	businessGroupId: number;
	giftCardProductId: number;
	file: string;
}

export type GiftCardFont = "HELVETICA" | "HELVETICA_BOLD" | "HELVETICA_OBLIQUE" | "HELVETICA_BOLD_OBLIQUE" | "TIMES_ROMAN" | "TIMES_BOLD" | "TIMES_ITALIC" | "TIMES_BOLD_ITALIC" | "COURIER" | "COURIER_BOLD" | "COURIER_OBLIQUE" | "COURIER_BOLD_OBLIQUE" | "BRETAGNE_SELF_MODERN_REGULAR" | "BRETAGNE_SELF_MODERN_ITALIC" | "AVENIR_MEDIUM" | "CLASSICO" | "MONTSERRAT" | "BRANDON_GROTESQUE";

export interface GiftCardListExcelInternalRequest {
	clientId?: number;
	businessGroupId?: number;
	system?: GiftCardSystem;
	startDate?: Date;
	endDate?: Date;
	number?: string;
	minSum?: number;
	maxSum?: number;
	productName?: string;
	email?: string;
	reference?: string;
	name?: string;
	lang: string;
}

export interface GiftCardListInternalRequest {
	clientId?: number;
	businessGroupId?: number;
	system?: GiftCardSystem;
	startDate?: Date;
	endDate?: Date;
	number?: string;
	minSum?: number;
	maxSum?: number;
	productName?: string;
	email?: string;
	reference?: string;
	name?: string;
}

export interface GiftCardListResponse extends GiftCard {
	businessGroupId: number;
	valueLeft: number;
	name?: string;
}

export interface GiftCardLog {
	id: number;
	giftCardId: number;
	clientId: number;
	actorId?: number;
	clientAccountingId?: number;
	extClientAccountingId?: number;
	eventTime: Date;
	eventType: GiftCardEventType;
	system: GiftCardSystem;
	reference: string;
	value: number;
	message?: string;
}

export interface GiftCardLogListInternalRequest {
	id: number;
}

export interface GiftCardLogResponse extends GiftCardLog {
	actorName?: string;
	businessDate?: Date;
	authorizationHoldId?: number;
}

export interface GiftCardProduct {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyTime: Date;
	modifyActorId: number;
	businessGroupId: number;
	settingsId: number;
	clientId?: number;
	emailTemplateIdFi: number;
	emailTemplateIdEn?: number;
	deleteActorId?: number;
	deleteTime?: Date;
	productName: string;
	price: number;
	openPrice: boolean;
	openFreeText: boolean;
	groupCards: boolean;
	validityDays?: number;
	notAfter?: Date;
	shortDescription?: string;
}

export interface GiftCardProductByIdInternalRequest {
	id: number;
}

export interface GiftCardProductListRequest {
	businessGroupId?: number;
	clientId?: number;
	settingsId?: number;
	minSum?: number;
	maxSum?: number;
	productName?: string;
	undeletedOnly: boolean;
}

export interface GiftCardProductUpdateRequest {
	id?: number;
	businessGroupId: number;
	settingsId: number;
	clientId?: number;
	emailTemplateIdFi: number;
	emailTemplateIdEn?: number;
	productName: string;
	price: number;
	openPrice: boolean;
	openFreeText: boolean;
	groupCards: boolean;
	validityDays?: number;
	notAfter?: Date;
	shortDescription?: string;
	delete: boolean;
}

export interface GiftCardRenderPdfInternalRequest {
	gcId: number;
}

export interface GiftCardSendEmailInternalRequest {
	id: number;
}

export interface GiftCardSettings {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyTime: Date;
	modifyActorId: number;
	deleteTime?: Date;
	deleteActorId?: number;
	businessGroupId: number;
	name: string;
	freeText?: string;
	pdf: string;
}

export interface GiftCardSettingsBox {
	giftCardSettingsId: number;
	drawRegion: GiftCardSettingsDrawRegion;
	coords: number[];
	horizontalAlign: GiftCardTextAlign;
	verticalAlign: GiftCardTextAlign;
	messageFormat: string;
	font: GiftCardFont;
	fontSize: number;
	lineSpacing: number;
	fillColor: string;
	strokeWidth: number;
	strokeColor: string;
}

export interface GiftCardSettingsBoxUpdateRequest {
	drawRegion: GiftCardSettingsDrawRegion;
	coords: number[];
	horizontalAlign: GiftCardTextAlign;
	verticalAlign: GiftCardTextAlign;
	messageFormat: string;
	font: GiftCardFont;
	fontSize: number;
	lineSpacing: number;
	fillColor: string;
	strokeWidth: number;
	strokeColor: string;
}

export interface GiftCardSettingsByGiftCardIdInternalRequest {
	id: number;
}

export interface GiftCardSettingsByIdInternalRequest {
	id: number;
}

export interface GiftCardSettingsDeleteRequest {
	id: number;
}

export type GiftCardSettingsDrawRegion = "QR_NUMBER" | "NUMBER" | "VALUE" | "NOT_AFTER_DATE" | "FREE_TEXT" | "RECIPIENT_NAME";

export interface GiftCardSettingsHasSettingsByClientIdInternalRequest {
	clientId: number;
}

export interface GiftCardSettingsPdfByIdInternalRequest {
	id: number;
}

export interface GiftCardSettingsPdfByIdResponse {
	pdf: string;
}

export interface GiftCardSettingsRenderInternalRequest {
	id: number;
	modifyTime: Date;
}

export interface GiftCardSettingsResponse {
	settings: GiftCardSettings;
	boxes: GiftCardSettingsBox[];
	pages: number;
}

export interface GiftCardSettingsSampleInternalRequest {
	id: number;
}

export interface GiftCardSettingsStub {
	id: number;
	businessGroupId: number;
	name: string;
}

export interface GiftCardSettingsUpdateRequest {
	id?: number;
	businessGroupId: number;
	name: string;
	modifyTime?: Date;
	freeText?: string;
	boxes: GiftCardSettingsBoxUpdateRequest[];
	pdf: string;
}

export type GiftCardSystem = "BROWSER" | "DASHBOARD" | "NETSTORE" | "POS";

export type GiftCardTextAlign = "START" | "CENTER" | "END";

export interface GiftCardUpdateRequest {
	id: number;
	notAfter: Date;
	purchaserEmail: string;
	productId: number;
	productName: string;
	freeText?: string;
}

export interface GiftCardUsageResponse {
	id: number;
	giftCardId: number;
	type: GiftCardEventType;
	reference: string;
	value: number;
	createTime: Date;
	clientId: number;
	businessGroupId: number;
	purchasedAtClientId: number;
	number: string;
	purchasedAtSystem: GiftCardSystem;
	productName: string;
	purchaserEmail: string;
}

export interface Health {
	id: number;
	clientId: number;
	repositoryId?: number;
	createTime: Date;
	modifyTime: Date;
	state: HealthState;
	error?: string;
	warningReasons: WarningReason[];
	features: PosInstanceFeature[];
	gateway?: string;
	branch?: string;
	abbreviatedSha?: string;
	lastBackupTime?: Date;
	lastBackupSize?: number;
	hwmon?: string;
	java?: string;
	psql?: string;
	kernel?: string;
	serverTime?: Date;
	loadavg: string[];
	nameServer: string[];
	emailTimes: Date[];
	printTimes: Date[];
	terminalTimes: Date[];
	authorizationHoldTimes: Date[];
	receiptHeroTimes: Date[];
	dashboardControlledItemTimes: Date[];
	releaseName?: string;
	releasePrettyName?: string;
	releaseVersion?: string;
	releaseVersionId?: string;
	uptime: string[];
}

export interface HealthActor {
	id: number;
	healthId: number;
	locationName?: string;
	firstName: string;
	lastName: string;
	pinCode?: string;
	permission: PosActorPermission;
}

export interface HealthCertificate {
	healthId: number;
	type: HealthCertificateType;
	certificate: string;
}

export type HealthCertificateType = "HTTPS" | "VPN";

export interface HealthDisk {
	id: number;
	healthId: number;
	name: string;
	type: string;
	readOnly: boolean;
	total?: number;
	unallocated?: number;
	usable?: number;
}

export interface HealthDrm {
	healthId: number;
	featureFoodOrder: boolean;
	featureGiftCard: boolean;
	featureIntegrationBookkeeping: boolean;
	featureIntegrationIntellipocket: boolean;
	featureIntegrationLahjakorttiEu: boolean;
	featureIntegrationNettikauppa: boolean;
	featurePaymentTerminal: boolean;
	featureProductAlias: boolean;
	featureUpdateTime: Date;
	paymentTerminalCount: number;
	firstBill?: Date;
	billCount: number;
	airpayCount?: number;
}

export interface HealthMemory {
	healthId: number;
	meminfo: string;
	memAvailable: string;
	memTotal: string;
}

export interface HealthMonitoredTask {
	healthId: number;
	type: PosTaskType;
	issues?: string;
	eventTime: Date;
}

export interface HealthNetwork {
	healthId: number;
	name: string;
	displayName: string;
	hardwareAddress?: string;
	inetAddress: string[];
	parentName?: string;
	up: boolean;
	virtual: boolean;
	loopback: boolean;
	pointToPoint: boolean;
}

export interface HealthResponse {
	health?: Health;
	disks: HealthDisk[];
	drm?: HealthDrm;
	tasks: HealthMonitoredTask[];
	httpsCertificate?: HealthCertificate;
	openvpnCertificate?: HealthCertificate;
	runtime?: HealthRuntime;
	memory?: HealthMemory;
	networks: HealthNetwork[];
	status: HealthStatus[];
	actors: HealthActor[];
}

export interface HealthRuntime {
	healthId: number;
	availableProcessors: number;
	freeMemory: number;
	maxMemory: number;
	totalMemory: number;
	currentlyAllocated: number;
	available: number;
	javaLowMemory: boolean;
}

export type HealthState = "UP" | "DOWN" | "ERROR";

export interface HealthStatus {
	healthId: number;
	objectId: string;
	type: HealthStatusType;
	statusList: string[];
	eventTime?: Date;
}

export type HealthStatusType = "EXTERNAL_NETTIKAUPPA";

export interface Help {
	id: number;
	type: HelpType;
	identifier: string;
	language: Language;
	tuloposVersion?: string;
	title: string;
	html: string;
	internalDocumentHtml: string;
}

export interface HelpByIdInternalRequest {
	id: number;
}

export interface HelpDeleteRequest {
	id: number;
}

export interface HelpGetHelpInternalRequest {
	identifier: string;
	language: Language;
}

export interface HelpListInternalRequest {
	type: HelpType;
}

export interface HelpRelatedByMainIdInternalRequest {
	id: number;
}

export type HelpType = "POS" | "DASHBOARD" | "NETSTORE";

export interface HelpUpdateRequest {
	id?: number;
	type: HelpType;
	identifier: string;
	title: string;
	language: Language;
	tuloposVersion?: string;
	html: string;
	internalDocumentHtml: string;
	related: number[];
}

export interface HeralesAdminHeralesPartyClientLocationInternalRequest {
	partyId: number;
}

export interface HeralesAdminHeralesPartyListInternalRequest {
	partyType: PartyType;
}

export interface HeralesAdminHeralesPartySupplierInternalRequest {
	partyId: number;
}

export interface HeralesAdminManifestByIdInternalRequest {
	id: string;
}

export interface HeralesAdminManifestListInternalRequest {
	id?: string;
	startManifestTimestamp?: Date;
	endManifestTimestamp?: Date;
	startDeliveryDate?: Date;
	endDeliveryDate?: Date;
	clientId?: number;
	locationName?: string;
	supplierId?: number;
	status?: ManifestError;
	showHidden: boolean;
}

export interface HeralesAdminMasterProductStorageUnitBySupplierCodeInternalRequest {
	partyId: number;
	productCode: string;
}

export interface HeralesAdminMasterProductStorageUnitEanByEanInternalRequest {
	ean: string;
}

export interface HeralesManifest {
	id: string;
	manifestTimestamp: Date;
	deliveryDate: Date;
	reference: string;
	deleteTime?: Date;
	deleteActorId?: number;
	hidden: boolean;
}

export interface HeralesManifestByIdResponse {
	manifest: HeralesManifest;
	party: HeralesParty[];
	row: HeralesManifestRow[];
	rowProduct: HeralesManifestRowProduct[];
}

export interface HeralesManifestClientSide {
	id: string;
	manifestTimestamp: Date;
	deliveryDate: Date;
	reference: string;
	clientId: number;
	locationName: string;
	supplierName: string;
	status: ManifestError;
	hidden: boolean;
}

export interface HeralesManifestDeleteRequest {
	id: string;
}

export interface HeralesManifestRow {
	id: number;
	heralesManifestId: string;
	ean?: string;
	productCode: string;
	productName: string;
	baseUnit: PosBaseUnit;
	storageUnit?: number;
	orderedStorageUnitQuantity: number;
	deliveredStorageUnitQuantity: number;
	priceExcludingVat: number;
	priceIncludingVat?: number;
	vatPercent: number;
	discount?: number;
	totalExcludingVat: number;
	totalIncludingVat?: number;
}

export interface HeralesManifestRowProduct {
	id: number;
	heralesManifestRowId: number;
	storageUnitId: number;
	eanId?: number;
}

export interface HeralesParty {
	id: number;
	heralesManifestId: string;
	partyType: PartyType;
	partyId: number;
	name: string;
	businessId: string;
	addressName: string;
	addressName2: string;
	addressStreet: string;
	addressZip: string;
	addressCity: string;
	addressCountry: string;
}

export interface HeralesPartyClientLocation {
	id: number;
	partyId: number;
	clientId: number;
	locationName: string;
}

export interface HeralesPartySupplier {
	id: number;
	partyId: number;
	name: string;
}

export interface HeralesPartyToClientLocation {
	partyId: number;
	clientId: number;
	locationName: string;
}

export interface HeralesPartyToSupplierRequest {
	partyId: number;
	name: string;
}

export interface HeralesSupplierProduct {
	partyId: number;
	productCode: string;
	storageUnitId: number;
}

export interface HideRequest {
	id: string;
}

export interface IdName {
	id: number;
	name: string;
}

export interface ImageTestRequest {
	image: string;
}

export interface Import2Result {
	accountings: number;
}

export interface IntegrationDetail {
	strategy: string;
	total: number;
	passive: number;
	active: number;
	unconfigured: number;
	passiveClients: string[];
	unconfiguredClients: string[];
}

export interface IntegrationErrorMessageMapper {
	id: number;
	deleteActorId?: number;
	deleteTime?: Date;
	regex: string;
	textFi: string;
	textEn: string;
}

export interface IntegrationErrorMessageMapperListRegexInternalRequest {
	all: boolean;
}

export interface IntegrationErrorMessageMapperRegexByIdInternalRequest {
	id: number;
}

export interface IntegrationErrorMessageMapperUpdateRequest {
	id?: number;
	regex: string;
	textFi: string;
	textEn: string;
	delete: boolean;
}

export interface IntegrationMessage {
	id: number;
	clientId: number;
	targetId?: number;
	clientBillingId?: number;
	cttmId?: number;
	createTime: Date;
	lastDayOfMonth?: Date;
	reason: IntegrationMessageReason;
	level: IntegrationMessageLevel;
	action: IntegrationMessageAction;
	minAccountingId?: number;
	businessDate?: Date;
	startBusinessDate?: Date;
	endBusinessDate?: Date;
	count?: number;
	idList: number[];
	bookkeepingId?: number;
	exception?: string;
	extraInfo?: string;
}

export type IntegrationMessageAction = "ACCOUNTING_FETCH" | "BILLING_FETCH" | "ACCOUNTING" | "PREPAYMENT" | "SALES_INVOICE" | "COMBINED_BILLING" | "ACCOUNTING_SEND" | "PREPAYMENT_SEND" | "SALES_INVOICE_SEND" | "COMBINED_BILLING_SEND";

export interface IntegrationMessageDeleteRequest {
	id: number;
}

export interface IntegrationMessageExtended extends IntegrationMessage {
	notAllData: IntegrationMessageNotAllData[];
	missingAccounts?: IntegrationMessageMissingAccounts;
}

export type IntegrationMessageLevel = "INFO" | "ERROR";

export interface IntegrationMessageListRequest {
	clientId?: number;
	strategy?: IntegrationStrategy;
	level?: IntegrationMessageLevel;
	action?: IntegrationMessageAction;
	reason?: IntegrationMessageReason;
	startDate?: Date;
	endDate?: Date;
	targetId?: number;
	errorMessage?: string;
	clientBillingId?: number;
	showAlsoHidden: boolean;
}

export interface IntegrationMessageMissingAccounts {
	integrationMessageId: number;
	vats: number[];
	paymentTypes: PosPaymentType[];
	productCategories: string[];
	giftCardNkAccounts: string[];
	productIds: string[];
}

export interface IntegrationMessageNotAllData {
	id: number;
	integrationMessageId: number;
	clientId: number;
	clientNickName: string;
	missingDates: Date[];
}

export type IntegrationMessageReason = "OK" | "FETCH_FAILED" | "FETCH_PROCESSING_FAILED" | "NO_SALES" | "NO_ACCOUNTINGS" | "NOT_ALL_DATA" | "MISSING_ACCOUNTS" | "SEND_FAILED";

export type IntegrationStrategy = "FENNOA_ACCOUNTING" | "FIVALDI_ACCOUNTING" | "NETVISOR_ACCOUNTING" | "PROCOUNTOR_ACCOUNTING" | "TALENOM_ACCOUNTING";

export interface IntegrationSummary {
	total: number;
	passive: number;
	active: number;
	unconfigured: number;
}

export interface InvoiceBillingInfo {
	businessId: string;
	name: string;
	name2: string;
	streetAddress: string;
	zip: string;
	city: string;
	country: string;
	invoiceChannel: InvoiceChannel;
	invoiceOperator: string;
	invoiceAddress: string;
	paymentTermDays: number;
}

export interface InvoiceBillsRequest {
	clientId: number;
	clientBillingId: number[];
	invoiceDate?: Date;
}

export interface InvoiceBillsResponse {
	count: number;
}

export type InvoiceChannel = "EINVOICE" | "EMAIL" | "PAPER";

export type InvoiceType = "SALES_INVOICE" | "COMBINED_BILLING" | "PREPAYMENT";

export interface InvoicingData extends InvoicingProjectionData {
	fullSnapshot: FullInvoicingSnapshot;
	rows: InvoicingDataRow[];
	integrationDetails: IntegrationDetail[];
	integrationSummary: IntegrationSummary;
}

export interface InvoicingDataRow {
	name: string;
	belServer?: string;
	modulesString: string;
	bookkeepingStrategy?: IntegrationStrategy;
	branch?: string;
	terminals: number;
	billCount: number;
	firstBill?: Date;
	airpay1m: number;
	active: boolean;
	isNew: boolean;
	hasHealth: boolean;
}

export interface InvoicingProjectionData {
	activeThreshold: number;
	activeClients: number;
	passiveClients: number;
	allClients: number;
	activeTerminals: number;
	passiveTerminals: number;
	allTerminals: number;
	serversAtRs: number;
	activeModules: { [key: string]: number };
	passiveModules: { [key: string]: number };
	allModules: { [key: string]: number };
	categories: string[];
	moduleSort: string[];
}

export interface InvoicingSnapshot {
	id: number;
	reportDate: Date;
	cutoffDate: Date;
	createTime: Date;
}

export interface InvoicingSnapshotClient {
	id: number;
	invoicingSnapshotId: number;
	clientId: number;
	clientCreateActorId?: number;
	clientCreateTime: Date;
	name: string;
	clientType: string;
	belServer?: string;
	repositoryId?: number;
	healthCreateTime?: Date;
	healthModifyTime?: Date;
	branch?: string;
	paymentTerminalCount?: number;
	billCount?: number;
	firstBill?: Date;
	airpayCount?: number;
	modules?: ModuleType[];
	targetId?: number;
	targetStrategy?: IntegrationStrategy;
	targetActive?: boolean;
}

export interface InvoicingSnapshotServer {
	id: number;
	invoicingSnapshotId: number;
	serverLocation: ServerLocation;
	serverType: ServerType;
	count: number;
}

export interface InvoicingSnapshotTerminatedClient {
	id: number;
	invoicingSnapshotId: number;
	clientId: number;
	name: string;
	deleteTime: Date;
}

export interface JsonUpdateRequest {
	language: Language;
	json: string;
}

export type Language = "FI" | "SV" | "EN";

export interface ListLogByKeyRequest {
	key: string;
}

export interface LocalizedPaymentType {
	id: PosPaymentType;
	name: string;
}

export interface Location extends AbstractPosItemClientSpecific {
	customerName: string;
	customerBusinessId: string;
	name: string;
	streetAddress: string;
	zip: string;
	city: string;
	phone: string;
	email: string;
	backofficeFontSize?: number;
	paymentType: PosPaymentType[];
	auditHour: number;
	areaLimited: boolean;
	areaAccounting: boolean;
	giftCardSalesProductId?: string;
	extraChargeProductId?: string;
	underChargeProductId?: string;
	message?: string;
	doNotSendReports: boolean;
	selfServicePinCode?: string;
	sellPhysicalGiftCards: boolean;
	deleteOpenBillsInAutomatedAccounting: boolean;
	qrCode?: string;
	qrCodeScale?: number;
}

export interface LocationByIdInternalRequest {
	id: string;
}

export interface LocationListForBusinessGroupInternalRequest {
	businessGroupId: number;
}

export interface LocationListInternalRequest {
	id?: number;
}

export interface LocationLogo {
	locationId: string;
	imageData: string;
	imageMimeType: string;
}

export interface LocationLogoDeleteRequest {
	id: string;
}

export interface LocationLogoInternalRequest {
	locationId: string;
}

export interface LocationUpdateRequest extends PosItemUpdateRequestBase {
	customerName: string;
	customerBusinessId: string;
	name: string;
	streetAddress: string;
	zip: string;
	city: string;
	phone: string;
	email: string;
	backofficeFontSize?: number;
	paymentType: PosPaymentType[];
	auditHour: number;
	areaLimited: boolean;
	areaAccounting: boolean;
	giftCardSalesProductId?: string;
	extraChargeProductId?: string;
	underChargeProductId?: string;
	logo?: string;
	message?: string;
	doNotSendReports: boolean;
	selfServicePinCode?: string;
	sellPhysicalGiftCards: boolean;
	deleteOpenBillsInAutomatedAccounting: boolean;
	qrCode?: string;
	qrCodeScale?: number;
}

export interface LoginByUsernameRequest {
	username: string;
	password: string;
	otpCode?: number;
	lang: EmailTemplateLanguage;
}

export type ManifestError = "NO_TRANSFER" | "TRANSFERED" | "NO_PRODUCT_MATCH" | "NO_RECIPIENT_MATCH" | "NO_SUPPLIER_MATCH" | "DELETED" | "ERROR";

export interface MasterProduct {
	id: number;
	deleteTime?: Date;
	name: string;
	baseUnit: PosBaseUnit;
	originCountry: string;
	productCategoryId: number;
	tags: string[];
	sendToPos: boolean;
}

export interface MasterProductByIdInternalRequest {
	id: number;
}

export interface MasterProductByIdResult {
	product: MasterProduct;
	storageUnits: MasterProductByIdStorageUnitResult[];
}

export interface MasterProductByIdStorageUnitResult {
	storageUnit: MasterProductStorageUnit;
	supplierProducts: HeralesSupplierProduct[];
	eans: MasterProductStorageUnitEan[];
}

export interface MasterProductCategory {
	id: number;
	deleteTime?: Date;
	name: string;
}

export interface MasterProductDeleteRequest {
	id: number;
}

export interface MasterProductMatch {
	product: MasterProduct;
	storageUnit: MasterProductStorageUnit;
	ean: MasterProductStorageUnitEan;
}

export interface MasterProductStorageUnit {
	id: number;
	masterProductId: number;
	deleteTime?: Date;
	name: string;
	storageUnit: number;
}

export interface MasterProductStorageUnitByIdInternalRequest {
	id: number;
}

export interface MasterProductStorageUnitEan {
	id: number;
	storageUnitId: number;
	deleteTime?: Date;
	ean: string;
	name: string;
}

export interface MasterProductStorageUnitEanByIdInternalRequest {
	id: number;
}

export type MessageType = "ACCOUNTING" | "INVOICE";

export type ModuleType = "FoodOrder" | "GiftCard" | "IntegrationBookkeeping" | "IntegrationHerales" | "IntegrationIntellipocket" | "IntegrationLahjakorttiEu" | "IntegrationNettikauppa" | "IntegrationReceiptHero" | "PaymentTerminal" | "ProductAlias" | "RemoteControl";

export interface NetvisorGetProductsInternalRequest {
	targetId: number;
}

export interface NetvisorProductItem {
	baseInformation: ProductBaseInformation;
	bookKeepingDetails: ProductBookKeepingDetails;
}

export interface News {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	newsType: NewsType;
	published: boolean;
	topicFi: string;
	shortBodyFi: string;
	longBodyFi: string;
	topicEn: string;
	shortBodyEn: string;
	longBodyEn: string;
}

export interface NewsAdminListInternalRequest {
	newsType: NewsType;
}

export interface NewsByIdInternalRequest {
	id: number;
	asUri: boolean;
}

export interface NewsDTO extends News {
	createActorName: string;
	modifyActorName: string;
}

export interface NewsGetImgInternalRequest {
	sha256: string;
}

export interface NewsListInternalRequest {
	newsType: NewsType;
}

export type NewsType = "NEWS" | "ALERT";

export interface NewsUpdateRequest {
	id?: number;
	topicFi: string;
	shortBodyFi: string;
	longBodyFi: string;
	topicEn: string;
	shortBodyEn: string;
	longBodyEn: string;
	delete: boolean;
	newsType: NewsType;
	published: boolean;
}

export interface OTPStartInternalRequest {
	lang: EmailTemplateLanguage;
}

export interface OrderList extends AbstractPosItem {
	name: string;
	copies: number;
	bell: boolean;
	seats: boolean;
	sortProductsByName: boolean;
	orderListTemplate: OrderListTemplate;
	message?: string;
	orderListType: OrderListType;
}

export interface OrderListArea {
	orderListId: string;
	areaId: string;
}

export interface OrderListByIdInternalRequest {
	id: string;
}

export interface OrderListByIdResponse {
	orderList: OrderList;
	areaId: string[];
	stationId: string[];
	printerId: string[];
	productCategoryId: string[];
	productSubCategoryId: string[];
}

export interface OrderListByIdWithDependenciesInternalRequest {
	id: string;
}

export interface OrderListListInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface OrderListListOnSpecificLevelInternalRequest {
	id: number;
	type: PrivilegeType;
}

export interface OrderListListWithDependenciesInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface OrderListPrinter {
	orderListId: string;
	printerId: string;
}

export interface OrderListProductCategory {
	orderListId: string;
	productCategoryId: string;
}

export interface OrderListProductSubCategory {
	orderListId: string;
	productSubCategoryId: string;
}

export interface OrderListStation {
	orderListId: string;
	stationId: string;
}

export type OrderListTemplate = "SERVICE_LONG" | "CUSTOMER_COLLECT";

export type OrderListType = "COURSES" | "SEATS";

export interface OrderListUpdateRequest extends PosItemUpdateRequestBase {
	name: string;
	printerId: string[];
	copies: number;
	bell: boolean;
	seats: boolean;
	sortProductsByName: boolean;
	orderListTemplate: OrderListTemplate;
	message?: string;
	orderListType: OrderListType;
	productCategoryId: string[];
	productSubCategoryId: string[];
	areaId: string[];
	stationId: string[];
}

export interface OrderListWithDependencies extends OrderList {
	printers: string;
	productCategories: string;
	productSubCategories: string;
	areas: string;
}

export interface OrderMessage extends AbstractPosItem {
	itemSpecific: boolean;
	availableInMessages: boolean;
	orderListId?: string;
	message: string;
	open: boolean;
}

export interface OrderMessageAvailableInMessagesUpdate {
	id: string;
	availableInMessages: boolean;
}

export interface OrderMessageByIdInternalRequest {
	id: string;
}

export interface OrderMessageGroup extends AbstractPosItem {
	name: string;
}

export interface OrderMessageGroupByIdInternalRequest {
	id: string;
}

export interface OrderMessageGroupExtended extends OrderMessageGroup {
	orderMessages: OrderMessage[];
}

export interface OrderMessageGroupListInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface OrderMessageGroupListOnSpecificLevelInternalRequest {
	id: number;
	type: PrivilegeType;
}

export interface OrderMessageGroupListWithPermissionInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface OrderMessageGroupOrderMessage {
	id: string;
	orderMessageId: string;
	orderMessageGroupId: string;
}

export interface OrderMessageGroupUpdateRequest extends PosItemUpdateRequestBase {
	name: string;
	orderMessages: OrderMessage[];
}

export interface OrderMessageGroupWithPermission {
	orderMessageGroupExtended: OrderMessageGroupExtended;
	canEdit: boolean;
}

export interface OrderMessageListInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface OrderMessageListOnSpecificLevelInternalRequest {
	id: number;
	type: PrivilegeType;
}

export interface OrderMessageListWithPermissionsInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface OrderMessageUpdateRequest extends PosItemUpdateRequestBase {
	itemSpecific: boolean;
	availableInMessages: boolean;
	orderListId?: string;
	message: string;
}

export interface OrderMessageWithPermission {
	orderMessage: OrderMessage;
	canEdit: boolean;
}

export interface OtherActorUpdateRequest {
	id?: number;
	firstName: string;
	lastName: string;
	username: string;
	canBookkeepingIntegration: boolean;
	canIntegrationSettings: boolean;
	canGiftCard: boolean;
	canRemoteControl: boolean;
	canCreateUsers: boolean;
	canManifestIntegration: boolean;
	canPosItemAdmin: boolean;
	clientId: number[];
	businessGroupId: number[];
	corporationId: number[];
	delete: boolean;
}

export type PartyType = "SUPPLIER" | "BUYER" | "RECIPIENT";

export interface PaymentTerminal extends AbstractCommonItem {
	id: number;
	serialNumber: string;
	integrationPartner: PosTerminalIntegrationPartner;
	modelId?: number;
	isSpare: boolean;
	siteId?: number;
	statusId?: number;
	owner?: PaymentTerminalOwner;
}

export interface PaymentTerminalByIdInternalRequest {
	id: number;
}

export interface PaymentTerminalClient extends AbstractPosItemClientSpecific {
	paymentTerminalId: number;
	locationId: string;
	vivaWalletTerminalId?: string;
	name: string;
	integrationState: PosTerminalIntegrationState;
	extra: boolean;
	requireCardProcessingType: boolean;
	isVivaTerminalActivated: boolean;
	vivaWalletTerminalForceReset: boolean;
	kioskMode: boolean;
}

export interface PaymentTerminalClientArea {
	id: number;
	paymentTerminalClientId: string;
	areaId: string;
	stationId?: string;
}

export interface PaymentTerminalClientByIdInternalRequest {
	id: string;
}

export interface PaymentTerminalClientListByAreaIdInternalRequest {
	areaId: string;
}

export interface PaymentTerminalClientListByLocationIdInternalRequest {
	locationId: string;
}

export interface PaymentTerminalClientListInternalRequest {
	id?: number;
}

export interface PaymentTerminalClientUpdateRequest extends PosItemUpdateRequestBase {
	paymentTerminalId?: number;
	locationId: string;
	areaIds: string[];
	stationIds: string[];
	name: string;
	integrationState: PosTerminalIntegrationState;
	extra: boolean;
	requireCardProcessingType: boolean;
	isVivaTerminalActivated: boolean;
	vivaWalletTerminalForceReset: boolean;
	kioskMode: boolean;
}

export interface PaymentTerminalClientWithAreaIds {
	paymentTerminalClient: PaymentTerminalClient;
	areaIds: string[];
	stationIds: string[];
}

export interface PaymentTerminalClientWithAreasByIdInternalRequest {
	id: string;
}

export interface PaymentTerminalListInternalRequest {
	all: boolean;
}

export interface PaymentTerminalListManufacturersInternalRequest {
	all: boolean;
}

export interface PaymentTerminalListModelsInternalRequest {
	all: boolean;
}

export interface PaymentTerminalListSitesInternalRequest {
	all: boolean;
}

export interface PaymentTerminalListStatusesInternalRequest {
	all: boolean;
}

export interface PaymentTerminalListWithClientDataInternalRequest {
	all: boolean;
}

export interface PaymentTerminalManufacturer extends AbstractCommonItem {
	id: number;
	name: string;
}

export interface PaymentTerminalManufacturerByIdInternalRequest {
	id: number;
}

export interface PaymentTerminalModel extends AbstractCommonItem {
	id: number;
	name: string;
	manufacturerId: number;
	allowExternalDevice: boolean;
}

export interface PaymentTerminalModelByIdInternalRequest {
	id: number;
}

export type PaymentTerminalOwner = "TULOPOS" | "CUSTOMER";

export interface PaymentTerminalSite extends AbstractCommonItem {
	id: number;
	name: string;
}

export interface PaymentTerminalSiteByIdInternalRequest {
	id: number;
}

export interface PaymentTerminalStatus extends AbstractCommonItem {
	id: number;
	name: string;
}

export interface PaymentTerminalStatusByIdInternalRequest {
	id: number;
}

export interface PaymentTerminalUpdateManufacturerRequest extends CommonItemUpdateRequestBase {
	id?: number;
	name: string;
}

export interface PaymentTerminalUpdateModelRequest extends CommonItemUpdateRequestBase {
	id?: number;
	name: string;
	manufacturerId: number;
	allowExternalDevice: boolean;
}

export interface PaymentTerminalUpdateRequest extends CommonItemUpdateRequestBase {
	id?: number;
	serialNumber: string;
	integrationPartner: PosTerminalIntegrationPartner;
	modelId?: number;
	isSpare: boolean;
	siteId?: number;
	statusId?: number;
	owner?: PaymentTerminalOwner;
}

export interface PaymentTerminalUpdateSiteOrStatusRequest extends CommonItemUpdateRequestBase {
	id?: number;
	name: string;
}

export interface PaymentTerminalWithClientData extends PaymentTerminal {
	clientName?: string;
	integrationState?: PosTerminalIntegrationState;
	lastResultTime?: Date;
	lastClientName?: string;
	terminalVersion?: string;
}

export interface PortionSize extends AbstractPosItem {
	name: string;
	amount?: number;
	isStorageSize: boolean;
}

export interface PortionSizeByIdInternalRequest {
	id: string;
}

export interface PortionSizeListForBusinessGroupInternalRequest {
	businessGroupId: number;
}

export interface PortionSizeListInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface PortionSizeListOnSpecificLevelInternalRequest {
	id: number;
	type: PrivilegeType;
}

export interface PortionSizeUpdateRequest extends PosItemUpdateRequestBase {
	name: string;
	amount?: number;
	isStorageSize: boolean;
}

export type PosActorPermission = "ADMIN" | "HEAD" | "WAITER" | "REPORT" | "INTEGRATION";

export interface PosArea extends PosItemUpdateBase {
	locationId: string;
	billPrinterId?: string;
	name: string;
	requestPersonCountForNewTable: boolean;
	signOutAfterSave: boolean;
	closeBillsManually: boolean;
	signOutAfterIdleMinutes?: number;
	hidePersonChoice: boolean;
	eanInput: boolean;
	doNotPrintReceipts: boolean;
	lastReceiptInFrontPage: boolean;
	lastReceiptAskForEmail: boolean;
	freeSaleAllowed: boolean;
	tableReservationDefaults: number[];
	defaultView: PosAreaDefaultView;
	viewChangeDisallowed: boolean;
	hideTerminalStatusList: boolean;
	disallowPosUseDuringTransaction: boolean;
	terminalStatusListOrientation: PosTerminalStatusListOrientation;
}

export type PosAreaDefaultView = "POS" | "FLOOR_PLAN_POS" | "FOOD_ORDER" | "FLOOR_PLAN_FOOD_ORDER";

export type PosBaseUnit = "PCS" | "L" | "KG";

export interface PosClientMigrationCanMigrateInternalRequest {
	clientId: number;
}

export type PosInstanceFeature = "AMOUNT_PREMULTIPLIED" | "BILL_HAS_STATION" | "CATEGORY_MAPPING_AVAILABLE" | "GIFT_CARD_V1624" | "DASHBOARD_CONTROLLED_ITEM_PAYMENT_TERMINAL" | "DASHBOARD_CONTROLLED_ITEM_PRODUCT" | "SYSTEMD_SOCKET_READY" | "SYSTEMD_SOCKET_IN_USE" | "VAT_CONVERTED";

export interface PosItemCopyMasterRequestBase<T> {
	id: number;
	type: PrivilegeType;
	list: T[];
}

export interface PosItemUpdateBase {
	id: string;
	supersededById?: string;
	createTime: Date;
	modifyTime: Date;
	deleteTime?: Date;
	clientSpecific: boolean;
	businessGroupSpecific: boolean;
	corporationSpecific: boolean;
}

export interface PosItemUpdateRequestBase {
	id?: string;
	delete: boolean;
	privilegeId?: number;
	type: PrivilegeType;
}

export interface PosLocation extends PosItemUpdateBase {
	customerName: string;
	customerBusinessId: string;
	name: string;
	streetAddress: string;
	zip: string;
	city: string;
	phone: string;
	email: string;
	backofficeFontSize?: number;
	paymentType: PosPaymentType[];
	auditHour: number;
	areaLimited: boolean;
	areaAccounting: boolean;
	giftCardSalesProductId?: string;
	extraChargeProductId?: string;
	underChargeProductId?: string;
	sellPhysicalGiftCards: boolean;
	deleteOpenBillsInAutomatedAccounting: boolean;
	message?: string;
	doNotSendReports: boolean;
	selfServicePinCode?: string;
	qrCode?: string;
	qrCodeScale?: number;
}

export interface PosLocationLogo {
	locationId: string;
	imageData: string;
	imageMimeType: string;
}

export interface PosOrderList extends PosItemUpdateBase {
	name: string;
	copies: number;
	bell: boolean;
	seats: boolean;
	sortProductsByName: boolean;
	orderListTemplate: OrderListTemplate;
	message?: string;
	orderListType: OrderListType;
}

export interface PosOrderListArea {
	orderListId: string;
	areaId: string;
}

export interface PosOrderListPrinter {
	orderListId: string;
	printerId: string;
}

export interface PosOrderListProductCategory {
	orderListId: string;
	productCategoryId: string;
}

export interface PosOrderListProductSubCategory {
	orderListId: string;
	productSubCategoryId: string;
}

export interface PosOrderListStation {
	orderListId: string;
	stationId: string;
}

export interface PosOrderMessage extends PosItemUpdateBase {
	itemSpecific: boolean;
	availableInMessages: boolean;
	orderListId?: string;
	message: string;
	open: boolean;
}

export interface PosOrderMessageGroup extends PosItemUpdateBase {
	name: string;
}

export interface PosOrderMessageGroupOrderMessage {
	id: string;
	orderMessageId: string;
	orderMessageGroupId: string;
}

export type PosPaymentType = "cash" | "rounding" | "payment_terminal" | "amex" | "bankcard" | "billing" | "combined_billing" | "credit" | "debit" | "diners" | "eazy_break" | "lunch" | "lunchpaymentcard" | "smartum" | "extra" | "dinnerbooking" | "easyorder" | "edenred_app" | "edenred_voucher" | "expense" | "foodora" | "giftcard_eu" | "giftcard_nk" | "givito" | "intellipocket_bonus" | "internal" | "kelpoplounas" | "linecut" | "lunchie" | "marketing" | "mobilepay_app" | "online_store" | "paytrail" | "prepayment" | "resq" | "retainer" | "roombilling" | "smartum_app" | "stripe" | "tulopos_webstore" | "voucher" | "weorder" | "wolt" | "electron" | "epassi" | "giftcard" | "maestro" | "mastercard" | "mobilepay" | "offline_card" | "visa" | "user1" | "user2" | "user3" | "user4" | "user5";

export type PosPermissionType = "RESTRICT" | "SPECIFY" | "ALLOW";

export interface PosPortionSize extends PosItemUpdateBase {
	name: string;
	amount?: number;
	isStorageSize: boolean;
}

export interface PosPosItemUpdateRequest {
	vatCategoryList: PosVatCategory[];
	vatCategoryRowList: PosVatCategoryRow[];
	storageUnitList: PosStorageUnit[];
	productCategoryList: PosProductCategory[];
	productSubCategoryList: PosProductSubCategory[];
	portionSizeList: PosPortionSize[];
	salesChannel: PosSalesChannel[];
	salesRestrictionList: PosSalesRestriction[];
	salesRestrictionRows: PosSalesRestrictionTime[];
	pricingGroupList: PosPricingGroup[];
	pricingGroupRows: PosPricingGroupRow[];
	productList: PosProduct[];
	productImageList: PosProductImage[];
	productProductList: PosProductProduct[];
	productPricingGroupList: PosProductPricingGroup[];
	productPurchasePriceList: PosProductPurchasePrice[];
	locationList: PosLocation[];
	locationLogoList: PosLocationLogo[];
	areaList: PosArea[];
	stationList: PosStation[];
	terminalList: PosTerminal[];
	terminalAreaList: PosTerminalArea[];
	printerList: PosPrinter[];
	orderListList: PosOrderList[];
	orderListPrinterList: PosOrderListPrinter[];
	orderListAreaList: PosOrderListArea[];
	orderListStationList: PosOrderListStation[];
	orderListProductCategoryList: PosOrderListProductCategory[];
	orderListProductSubCategoryList: PosOrderListProductSubCategory[];
	orderMessageList: PosOrderMessage[];
	orderMessageGroupList: PosOrderMessageGroup[];
	orderMessageGroupOrderMessageList: PosOrderMessageGroupOrderMessage[];
	productOrderMessageGroupList: PosProductOrderMessageGroup[];
	vivaWalletPaymentInfoList: PosVivaWalletPaymentInfo[];
	vivaWalletFee: PosVivaWalletFee;
}

export interface PosPricingGroup extends PosItemUpdateBase {
	name: string;
	productCategoryId?: string;
	productSubCategoryId?: string;
	isDefault: boolean;
}

export interface PosPricingGroupRow {
	id: string;
	pricingGroupId: string;
	portionSizeId: string;
	salesChannelId?: string;
	salesRestrictionId?: string;
	locationId?: string;
	areaId?: string;
	clientSpecific: boolean;
	isHidden: boolean;
}

export interface PosPrinter extends PosItemUpdateBase {
	printerType: PosPrinterType;
	name: string;
	width: number;
	smallFont: number;
	bigFont: number;
	ipAddress: string;
	mac?: string;
	port?: number;
}

export type PosPrinterType = "LK_D30" | "LK_TE323" | "SR85" | "SP700" | "TSP143" | "MC_PRINT2" | "ESC_POS" | "ESC_POS_SD" | "TEST" | "CLOUDPRNT";

export interface PosProduct extends PosItemUpdateBase {
	productType: ProductType;
	aliasType?: AliasType;
	name: string;
	abbreviation: string;
	orderListAbbreviation?: string;
	productCategoryId: string;
	productSubCategoryId?: string;
	useSalesChannel: boolean;
	salesChannelId?: string;
	salesRestrictionId?: string;
	baseUnit: PosBaseUnit;
	storageUnitId?: string;
	description?: string;
	purchasePriceSu?: number;
	locationId?: string;
	warehousedOnly: boolean;
	openPrice: boolean;
	useSlavePrice: boolean;
	useSlavePortion: boolean;
	openName: boolean;
	useChosenProductOnBill: boolean;
	useChosenProductOnPos: boolean;
	useChosenProductOnOrderList: boolean;
	doNotAllowComponentsToMerge: boolean;
	fixedPrice?: number;
	ean: string[];
}

export interface PosProductCategory extends PosItemUpdateBase {
	name: string;
	vatCategoryId: number;
	defaultBaseUnit: PosBaseUnit;
	defaultStorageUnitId?: string;
	valviraCategory?: PosValviraReportCategory;
	nonOperativeSales: boolean;
}

export interface PosProductImage {
	productId: string;
	modifyTime: Date;
	imageData: string;
	imageMimeType: string;
}

export interface PosProductOrderMessageGroup extends PosItemUpdateBase {
	productId: string;
	orderMessageGroupId: string;
}

export interface PosProductPricingGroup {
	id: string;
	productId: string;
	portionSizeId: string;
	price: number;
	salesChannelId?: string;
	salesRestrictionId?: string;
	locationId?: string;
	areaId?: string;
	clientSpecific: boolean;
	isHidden: boolean;
	deleteTime?: Date;
}

export interface PosProductProduct {
	id: string;
	masterId: string;
	productCategoryId?: string;
	productSubCategoryId?: string;
	portionSizeId?: string;
	productPricingGroupId?: string;
	productId?: string;
	amount?: number;
	useSlavePrice: boolean;
	total?: number;
}

export interface PosProductPurchasePrice {
	id: string;
	productId: string;
	price: number;
	createTime: Date;
	accountingDate: Date;
}

export interface PosProductSubCategory extends PosItemUpdateBase {
	name: string;
	productCategoryId: string;
	defaultBaseUnit?: PosBaseUnit;
	defaultStorageUnitId?: string;
}

export interface PosSalesChannel extends PosItemUpdateBase {
	name: string;
	abbreviation: string;
	valviraOutSale: boolean;
}

export interface PosSalesRestriction extends PosItemUpdateBase {
	name: string;
	abbreviation: string;
	minimumPermission: PosActorPermission;
	startDate?: Date;
	endDate?: Date;
}

export interface PosSalesRestrictionTime {
	id: string;
	salesRestrictionId: string;
	dayOfWeek: DayOfWeek;
	hourStart: number;
	hourEnd: number;
	clientSpecific: boolean;
}

export interface PosStation extends PosItemUpdateBase {
	areaId: string;
	name: string;
	billPrinterId?: string;
}

export interface PosStorageUnit extends PosItemUpdateBase {
	name: string;
	value: number;
	baseUnit: PosBaseUnit;
}

export type PosTaskType = "ACCOUNTING" | "CLEANER" | "DASHBOARD_CONTROLLED_ITEM_QUEUE" | "EMAIL_QUEUE" | "GIFT_CARD_AUTHORIZATION_HOLD_QUEUE" | "GIFT_CARD_EMAIL_QUEUE" | "GIFT_CARD_PAYMENT_QUEUE" | "GIFT_CARD_TRANSACTION_QUEUE" | "INVENTORY" | "NETTIKAUPPA" | "SHOPIFY" | "PRINTER_CONNECTION" | "PSQL_LISTENER" | "RECEIPT_HERO_QUEUE" | "REPORT" | "STATUS" | "TERMINAL_CONNECTION" | "TERMINAL_STATUS" | "TERMINAL_STATUS_SERVER_KEEPALIVE" | "VIVA_WALLET" | "VIVA_WALLET_DASHBOARD" | "DASHBOARDAPI_GIFT_CARD_UPDATE" | "DASHBOARDAPI_POS_ITEM_UPDATE";

export interface PosTerminal extends PosItemUpdateBase {
	locationId: string;
	integrationPartner: PosTerminalIntegrationPartner;
	integrationState: PosTerminalIntegrationState;
	serialNumber: string;
	vivaWalletTerminalId?: string;
	name: string;
	extra: boolean;
	requireCardProcessingType: boolean;
	allowExternalDevice: boolean;
	kioskMode: boolean;
	isVivaTerminalActivated: boolean;
	vivaWalletTerminalForceReset: boolean;
}

export interface PosTerminalArea {
	terminalId: string;
	areaId: string;
	stationId?: string;
}

export type PosTerminalIntegrationPartner = "VERIFONE" | "VIVA_WALLET";

export type PosTerminalIntegrationState = "ECR" | "CLOUD" | "TABLES";

export type PosTerminalStatusListOrientation = "HORIZONTAL" | "VERTICAL";

export type PosValviraReportCategory = "BEER" | "OTHER";

export interface PosVatCategory {
	id: number;
	name: string;
	deleteTime?: Date;
}

export interface PosVatCategoryRow {
	vatCategoryId: number;
	vatPercent: number;
	startDate: Date;
}

export interface PosVivaWalletFee {
	feePercentage: number;
	feeCents: number;
}

export interface PosVivaWalletPaymentInfo extends PosItemUpdateBase {
	locationId?: string;
	merchantId: string;
	posApiClientId: string;
	posApiClientSecret: string;
	sourceCode: string;
	pinCode: string;
	feeCents: number;
	feePercentage: number;
}

export interface PosVivaWalletWebhookResponseByMerchantTrnsAndParentTransactionIdInternalRequest {
	merchantTrns: string;
	parentTransactionId?: string;
}

export interface PosVivaWalletWebhookResponseByMerchantTrnsInternalRequest {
	merchantTrns: string;
}

export interface PrepaymentListRequest {
	clientId?: number;
	status?: TransferStatus;
	startDate?: Date;
	endDate?: Date;
	minSum?: number;
	maxSum?: number;
	deleted: boolean;
	lang: string;
	reference?: string;
	billedCustomerName?: string;
}

export interface PrepaymentManualSendRequest {
	id: number;
}

export interface PrepaymentManualSendResponse {
	count: number;
}

export interface PrepaymentResponse extends ClientBilling {
	cttmId?: number;
	unitName: string;
	status?: TransferStatus;
	deleted: boolean;
}

export interface PricingGroup extends AbstractPosItem {
	name: string;
	productCategoryId?: string;
	productSubCategoryId?: string;
	isDefault: boolean;
}

export interface PricingGroupByIdInternalRequest {
	id: string;
}

export interface PricingGroupListInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface PricingGroupListOnSpecificLevelInternalRequest {
	id: number;
	type: PrivilegeType;
}

export interface PricingGroupListRowsInternalRequest {
	id: string;
}

export interface PricingGroupListWithRowsInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface PricingGroupResponse {
	pricingGroup: PricingGroup;
	rows: PricingGroupRow[];
}

export interface PricingGroupRow {
	id: string;
	pricingGroupId: string;
	portionSizeId: string;
	salesChannelId?: string;
	salesRestrictionId?: string;
	locationId?: string;
	areaId?: string;
	clientId?: number;
	isHidden: boolean;
}

export interface PricingGroupRowUpdateRequest {
	id?: string;
	portionSizeId: string;
	salesChannelId?: string;
	salesRestrictionId?: string;
	locationId?: string;
	areaId?: string;
	clientId?: number;
	isHidden: boolean;
}

export interface PricingGroupRowsResponse {
	rows: PricingGroupRow[];
	specifyRows: PricingGroupSpecifyRows[];
}

export interface PricingGroupSpecifyRequest {
	elementId: string;
	clientId: number;
	pricingGroupRows: PricingGroupRowUpdateRequest[];
}

export interface PricingGroupSpecifyRows extends IdName {
	rows: PricingGroupRow[];
}

export interface PricingGroupUpdateRequest extends PosItemUpdateRequestBase {
	name: string;
	productCategoryId?: string;
	productSubCategoryId?: string;
	isDefault: boolean;
	rows: PricingGroupRowUpdateRequest[];
}

export interface Printer extends AbstractPosItemClientSpecific {
	printerType: PosPrinterType;
	name: string;
	width: number;
	smallFont: number;
	bigFont: number;
	ipAddress: string;
	mac?: string;
	port?: number;
}

export interface PrinterByIdInternalRequest {
	id: string;
}

export interface PrinterListInternalRequest {
	id?: number;
}

export interface PrinterUpdateRequest extends PosItemUpdateRequestBase {
	printerType: PosPrinterType;
	name: string;
	width: number;
	smallFont: number;
	bigFont: number;
	ipAddress: string;
	mac?: string;
	port?: number;
}

export interface PrivilegeCanEditInternalRequest {
	type: PrivilegeType;
	id?: number;
}

export interface PrivilegeItem extends IdName {
	additionalName?: string;
	businessGroupId?: number;
	corporationId?: number;
	type: PrivilegeType;
}

export interface PrivilegeListClientsByBusinessGroupIdInternalRequest {
	businessGroupId: number;
}

export type PrivilegeType = "CLIENT" | "BUSINESS_GROUP" | "CORPORATION";

export interface ProcountorGetDimensionsInternalRequest {
	targetId: number;
}

export interface ProcountorGetProductsInternalRequest {
	targetId: number;
}

export interface ProcountorLogin2InternalRequest {
	state: string;
	error: string;
	code: string;
}

export interface ProcountorLoginInternalRequest {
	targetId: number;
	url: string;
}

export interface ProcountorProduct {
	id: number;
	type: string;
	code: string;
	name: string;
	defaultAccount: number;
}

export interface Product extends AbstractPosItem {
	type: ProductType;
	aliasType?: AliasType;
	locationId?: string;
	name: string;
	abbreviation: string;
	orderListAbbreviation?: string;
	productCategoryId: string;
	productSubCategoryId?: string;
	useSalesChannel: boolean;
	salesChannelId?: string;
	salesRestrictionId?: string;
	baseUnit: PosBaseUnit;
	storageUnitId?: string;
	description?: string;
	warehousedOnly: boolean;
	openPrice: boolean;
	useSlavePrice: boolean;
	useSlavePortion: boolean;
	openName: boolean;
	useChosenProductOnBill: boolean;
	useChosenProductOnPos: boolean;
	useChosenProductOnOrderList: boolean;
	doNotAllowComponentsToMerge: boolean;
	fixedPrice?: number;
	ean: string[];
}

export interface ProductBaseInformation {
	netvisorKey: number;
	productCode: string;
	name: string;
}

export interface ProductBookKeepingDetails {
	defaultVatPercent: number;
	defaultDomesticAccountNumber: number;
	defaultEuAccountNumber: number;
	defaultOutsideEUAccountnumber: number;
}

export interface ProductByIdExtendedInternalRequest {
	id: string;
}

export interface ProductByIdInternalRequest {
	id: string;
}

export interface ProductByIdResponse extends ProductResponse {
	purchasePriceSu?: number;
	pricingGroupRowList: PricingGroupRow[];
}

export interface ProductCategory extends AbstractPosItem {
	name: string;
	vatCategoryId: number;
	defaultBaseUnit: PosBaseUnit;
	defaultStorageUnitId?: string;
	valviraCategory?: PosValviraReportCategory;
	nonOperativeSales: boolean;
}

export interface ProductCategoryByIdInternalRequest {
	id: string;
}

export interface ProductCategoryListInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface ProductCategoryListOnSpecificLevelInternalRequest {
	id: number;
	type: PrivilegeType;
}

export interface ProductCategoryUpdateRequest extends PosItemUpdateRequestBase {
	name: string;
	vatCategoryId: number;
	defaultBaseUnit: PosBaseUnit;
	defaultStorageUnitId?: string;
	valviraCategory?: PosValviraReportCategory;
	nonOperativeSales: boolean;
}

export interface ProductImage {
	productId: string;
	modifyTime: Date;
	imageData: string;
	imageMimeType: string;
}

export interface ProductImageByIdInternalRequest {
	id: string;
}

export interface ProductListInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface ProductListOnSpecificLevelInternalRequest {
	id: number;
	type: PrivilegeType;
}

export interface ProductListPricesInternalRequest {
	id: string;
}

export interface ProductListWithPricingGroupRowsInternalRequest {
	id?: number;
	type: PrivilegeType;
	productType?: ProductType;
}

export interface ProductOrderMessageGroup extends AbstractPosItem {
	productId: string;
	orderMessageGroupId: string;
}

export interface ProductOrderMessageGroupByIdInternalRequest {
	id: string;
}

export interface ProductOrderMessageGroupListInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface ProductOrderMessageGroupListOnSpecificLevelInternalRequest {
	id: number;
	type: PrivilegeType;
}

export interface ProductOrderMessageGroupListWithPermissionsInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface ProductOrderMessageGroupUpdateRequest extends PosItemUpdateRequestBase {
	productId: string;
	orderMessageGroupId: string;
}

export interface ProductOrderMessageGroupWithPermission {
	productOrderMessageGroup: ProductOrderMessageGroup;
	canEdit: boolean;
}

export interface ProductPricingGroup {
	id: string;
	deleteTime?: Date;
	clientId?: number;
	productId: string;
	portionSizeId: string;
	price: number;
	salesChannelId?: string;
	salesRestrictionId?: string;
	locationId?: string;
	areaId?: string;
	isHidden: boolean;
}

export interface ProductPricingGroupResponse {
	rows: ProductPricingGroup[];
	specifyRows: ProductPricingGroupSpecifyRow[];
}

export interface ProductPricingGroupSpecifyRequest {
	elementId: string;
	clientId: number;
	productPricingGroupList: ProductUpdateRequestPG[];
}

export interface ProductPricingGroupSpecifyRow extends IdName {
	rows: ProductPricingGroup[];
}

export interface ProductProduct {
	id: string;
	masterId: string;
	productCategoryId?: string;
	productSubCategoryId?: string;
	productId?: string;
	portionSizeId?: string;
	productPricingGroupId?: string;
	amount?: number;
	useSlavePrice: boolean;
	total?: number;
	ordering: number;
}

export interface ProductPurchasePrice {
	id: string;
	productId: string;
	createTime: Date;
	accountingDate: Date;
	price: number;
}

export interface ProductResponse {
	product: Product;
	productPricingGroupList: ProductPricingGroup[];
	productProductList: ProductProduct[];
}

export interface ProductResponseWithPricingGroupRows extends ProductResponse {
	pricingGroupRowList: PricingGroupRow[];
}

export interface ProductSubCategory extends AbstractPosItem {
	name: string;
	productCategoryId: string;
	defaultBaseUnit?: PosBaseUnit;
	defaultStorageUnitId?: string;
}

export interface ProductSubCategoryByIdInternalRequest {
	id: string;
}

export interface ProductSubCategoryListInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface ProductSubCategoryListOnSpecificLevelInternalRequest {
	id: number;
	type: PrivilegeType;
}

export interface ProductSubCategoryUpdateRequest extends PosItemUpdateRequestBase {
	name: string;
	productCategoryId: string;
	defaultBaseUnit?: PosBaseUnit;
	defaultStorageUnitId?: string;
}

export type ProductType = "ALIAS" | "RECIPE" | "REGULAR";

export interface ProductUpdateRequest extends PosItemUpdateRequestBase {
	productType: ProductType;
	aliasType?: AliasType;
	name: string;
	abbreviation: string;
	orderListAbbreviation?: string;
	productCategoryId: string;
	productSubCategoryId?: string;
	useSalesChannel: boolean;
	salesChannelId?: string;
	salesRestrictionId?: string;
	baseUnit: PosBaseUnit;
	storageUnitId?: string;
	description?: string;
	purchasePriceSu?: number;
	locationId?: string;
	warehousedOnly: boolean;
	openPrice: boolean;
	useSlavePrice: boolean;
	useSlavePortion: boolean;
	openName: boolean;
	useChosenProductOnBill: boolean;
	useChosenProductOnPos: boolean;
	useChosenProductOnOrderList: boolean;
	doNotAllowComponentsToMerge: boolean;
	fixedPrice?: number;
	ean: string[];
	productPricingGroupList: ProductUpdateRequestPG[];
	productProductList: ProductUpdateRequestPP[];
	image?: string;
}

export interface ProductUpdateRequestPG {
	id?: string;
	portionSizeId?: string;
	price?: number;
	salesChannelId?: string;
	salesRestrictionId?: string;
	locationId?: string;
	areaId?: string;
	clientId?: number;
	isHidden: boolean;
}

export interface ProductUpdateRequestPP {
	productCategoryId?: string;
	productSubCategoryId?: string;
	portionSizeId?: string;
	productPricingGroupId?: string;
	productId?: string;
	amount?: number;
	useSlavePrice: boolean;
	total?: number;
}

export interface PublicTranslateByKeyInternalRequest {
	key: string;
}

export interface PublicTranslateListInternalRequest {
	all: boolean;
}

export interface ReleaseLog {
	id: number;
	createTime: Date;
	deleteTime?: Date;
	deleteActorId?: number;
	published: boolean;
	branch: string;
	abbreviatedSha: string;
	technicalDescription: string;
	descriptionFi: string;
	descriptionEn: string;
	type: ReleaseLogType;
}

export interface ReleaseLogByIdInternalRequest {
	id: number;
	asUri: boolean;
}

export interface ReleaseLogGetImgInternalRequest {
	sha256: string;
}

export interface ReleaseLogListForCustomerInternalRequest {
	limit5: boolean;
	type: string;
}

export interface ReleaseLogListInternalRequest {
	type: string;
}

export interface ReleaseLogPublicResponse {
	id: number;
	createTime: Date;
	descriptionFi: string;
	descriptionEn: string;
}

export type ReleaseLogType = "DASHBOARD" | "NETSTORE";

export interface ReleaseLogUpdateRequest {
	id: number;
	technicalDescription: string;
	descriptionFi: string;
	descriptionEn: string;
	delete: boolean;
	published: boolean;
}

export type ReleaseType = "NON_STABLE" | "STABLE" | "CANARY";

export interface RemoveOneActorBusinessGroupRequest {
	businessGroupId: number;
	actorId: number;
}

export interface RemoveOneActorClientRequest {
	clientId: number;
	actorId: number;
}

export interface RemoveOneActorCorporationRequest {
	corporationId: number;
	actorId: number;
}

export interface ReportAuditMemoExcelInternalRequest {
	clientId: number;
	locationName?: string;
	year: number;
	month: number;
	lang: string;
}

export interface ReportAuditMemoInternalRequest {
	clientId: number;
	locationName?: string;
	year: number;
	month: number;
}

export interface ReportGiftCardDebtInternalRequest {
	clientId?: number;
	businessGroupId?: number;
	system?: GiftCardSystem;
	cutoffDate?: Date;
	expiresFrom?: Date;
	expiresTo?: Date;
	productName?: string;
	email?: string;
}

export interface ReportGiftCardUsageInternalRequest {
	type?: GiftCardEventType;
	usedAtClientId?: number;
	purchasedAtClientId?: number;
	businessGroupId?: number;
	system?: GiftCardSystem;
	startDate?: Date;
	endDate?: Date;
	productName?: string;
	email?: string;
}

export interface ReportUnitDataByIdExcelInternalRequest {
	id: number;
	lang: string;
}

export interface ReportUnitDataByIdInternalRequest {
	id: number;
}

export interface ReportUnitSummaryExcelInternalRequest {
	lang: string;
}

export interface Repository {
	id: number;
	createTime: Date;
	deleteTime?: Date;
	deleteActorId?: number;
	archiveTime?: Date;
	releaseType: ReleaseType;
	branch: string;
	epoch?: number;
	major?: number;
	minor?: number;
	patch?: number;
	md5sum: string;
	abbreviatedSha: string;
	name: string;
	publicDescriptionFi: string;
	publicDescriptionEn: string;
}

export interface RepositoryByIdInternalRequest {
	id: number;
	asUri: boolean;
}

export interface RepositoryGetImgInternalRequest {
	sha256: string;
}

export interface RepositoryListForCustomerInternalRequest {
	limit5: boolean;
}

export interface RepositoryListInternalRequest {
	archived?: boolean;
	onlyStable: boolean;
}

export interface RepositoryPublicResponse {
	id: number;
	createTime: Date;
	branch: string;
	abbreviatedSha: string;
	publicDescriptionFi: string;
	publicDescriptionEn: string;
}

export interface RepositoryUpdateRequest {
	id: number;
	name: string;
	publicDescriptionFi: string;
	publicDescriptionEn: string;
	delete: boolean;
	releaseType: ReleaseType;
	archive: boolean;
}

export interface RepositoryWithDataPresent extends Repository {
	dataPresent: boolean;
}

export interface SalesChannel extends AbstractPosItem {
	name: string;
	abbreviation: string;
	valviraOutSale: boolean;
}

export interface SalesChannelByIdInternalRequest {
	id: string;
}

export interface SalesChannelListForBusinessGroupInternalRequest {
	businessGroupId: number;
}

export interface SalesChannelListInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface SalesChannelListOnSpecificLevelInternalRequest {
	id: number;
	type: PrivilegeType;
}

export interface SalesChannelUpdateRequest extends PosItemUpdateRequestBase {
	name: string;
	abbreviation: string;
	valviraOutSale: boolean;
}

export interface SalesInvoiceListNotCompleteRequest {
	clientId?: number;
	deleted: boolean;
	lang: string;
}

export interface SalesInvoiceListNotCompleteResponse extends ClientBilling {
	unitName: string;
	status: string;
}

export interface SalesInvoiceListRequest {
	clientId?: number;
	status?: TransferStatus;
	startDate?: Date;
	endDate?: Date;
	minSum?: number;
	maxSum?: number;
	deleted: boolean;
	lang: string;
	billedCustomerName?: string;
}

export interface SalesInvoiceManualSendRequest {
	id: number;
}

export interface SalesInvoiceResponse extends ClientBilling {
	unitName: string;
	status?: TransferStatus;
	deleted: boolean;
}

export interface SalesInvoicetManualSendResponse {
	count: number;
}

export interface SalesPostingGroupDTO {
	postingGroupId: string;
	description: string;
	cashSales: boolean;
	defaultPostingGroupForSales: boolean;
	defaultPostingId: string;
	accountsReceivableAccount: string;
	defaultSk1?: string;
	defaultSk2?: string;
	defaultSk3?: string;
	defaultSk4?: string;
}

export interface SalesRestriction extends AbstractPosItem {
	name: string;
	abbreviation: string;
	minimumPermission: PosActorPermission;
	startDate?: Date;
	endDate?: Date;
}

export interface SalesRestrictionByIdInternalRequest {
	id: string;
}

export interface SalesRestrictionListForBusinessGroupInternalRequest {
	businessGroupId: number;
}

export interface SalesRestrictionListInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface SalesRestrictionListOnSpecificLevelInternalRequest {
	id: number;
	type: PrivilegeType;
}

export interface SalesRestrictionListTimesInternalRequest {
	id: string;
}

export interface SalesRestrictionSpecifyRequest {
	elementId: string;
	clientId: number;
	salesRestrictionTimes: SalesRestrictionTimeUpdateRequest[];
}

export interface SalesRestrictionTime extends SalesRestrictionTimeData {
	id: string;
	salesRestrictionId: string;
	clientId?: number;
}

export interface SalesRestrictionTimeData {
	dayOfWeek: DayOfWeek;
	hourStart: number;
	hourEnd: number;
}

export interface SalesRestrictionTimeResponse {
	rows: SalesRestrictionTime[];
	specifyRows: SalesRestrictionTimeSpecifyRows[];
}

export interface SalesRestrictionTimeSpecifyRows extends IdName {
	rows: SalesRestrictionTime[];
}

export interface SalesRestrictionTimeUpdateRequest extends SalesRestrictionTimeData {
	clientId?: number;
}

export interface SalesRestrictionUpdateRequest extends PosItemUpdateRequestBase {
	name: string;
	abbreviation: string;
	minimumPermission: PosActorPermission;
	startDate?: Date;
	endDate?: Date;
	salesRestrictionTimes: SalesRestrictionTimeUpdateRequest[];
}

export interface SchedulerToggleTaskRequest {
	task: DashboardTaskType;
}

export interface SelfNameUpdateReqeust {
	firstName: string;
	lastName: string;
}

export interface Server {
	id: string;
	serverType: ServerType;
	serverLocation: ServerLocation;
	serialNumber: string;
	secureBoot: boolean;
	acquired: Date;
	retired?: Date;
	retiringReason: string;
	notes: string;
	healthId?: number;
}

export interface ServerByIdInternalRequest {
	id: string;
}

export interface ServerListExcelInternalRequest {
	id?: string;
	serialNumber?: string;
	serverLocation?: ServerLocation;
	clientName?: string;
	serverType?: ServerType;
	showRetired: boolean;
	startAcquiredDate?: Date;
	endAcquiredDate?: Date;
	lang: string;
}

export interface ServerListInternalRequest {
	id?: string;
	serialNumber?: string;
	serverLocation?: ServerLocation;
	clientName?: string;
	serverType?: ServerType;
	showRetired: boolean;
	startAcquiredDate?: Date;
	endAcquiredDate?: Date;
}

export interface ServerListResponse {
	id: string;
	serialNumber: string;
	serverLocation: ServerLocation;
	clientName?: string;
	serverType: ServerType;
	acquired: Date;
	retired?: Date;
	lastSeen?: Date;
}

export type ServerLocation = "BEL" | "BEL_MUU" | "IN_TRANSPORTATION" | "RS";

export interface ServerLog {
	id: number;
	serverId: string;
	clientId?: number;
	actorId?: number;
	eventTime: Date;
	description: string;
}

export interface ServerLogByIdInternalRequest {
	id: string;
}

export type ServerType = "BEL" | "BELP" | "RS" | "OTHER";

export interface ServerUpdateRequest {
	id: string;
	serverLocation: ServerLocation;
	serverType: ServerType;
	serialNumber: string;
	secureBoot: boolean;
	acquired: Date;
	retired?: Date;
	retiringReason: string;
	notes: string;
}

export interface Session {
	createTime: Date;
	deleteTime?: Date;
	actorId: number;
	firstName: string;
	lastName: string;
	username: string;
	canAdmin: boolean;
	canBelAdmin: boolean;
	canCreateUsers: boolean;
	canBookkeepingIntegration: boolean;
	canGiftCard: boolean;
	canIntegrationSettings: boolean;
	canManifestIntegration: boolean;
	canPosItemAdmin: boolean;
	canRemoteControl: boolean;
	canSuperuser: boolean;
	hasOTP: boolean;
}

export interface SessionStatusResponse {
	abbreviatedSha: string;
	session?: Session;
	integrationMessageErrors: boolean;
}

export type Source = "API" | "DB";

export interface Station extends AbstractPosItemClientSpecific {
	areaId: string;
	name: string;
	billPrinterId?: string;
}

export interface StationByIdInternalRequest {
	id: string;
}

export interface StationByIdWithPaymentTerminalsInternalRequest {
	id: string;
}

export interface StationListInternalRequest {
	id?: number;
}

export interface StationUpdateRequest extends PosItemUpdateRequestBase {
	areaId: string;
	name: string;
	billPrinterId?: string;
}

export interface StationWithPaymentTerminals {
	station: Station;
	paymentTerminalClientIds: UuidName2[];
}

export interface StorageUnit extends AbstractPosItem {
	name: string;
	value: number;
	baseUnit: PosBaseUnit;
}

export interface StorageUnitByIdInternalRequest {
	id: string;
}

export interface StorageUnitListForBusinessGroupInternalRequest {
	businessGroupId: number;
}

export interface StorageUnitListInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface StorageUnitListOnSpecificLevelInternalRequest {
	id: number;
	type: PrivilegeType;
}

export interface StorageUnitUpdateRequest extends PosItemUpdateRequestBase {
	name: string;
	value: number;
	baseUnit: PosBaseUnit;
}

export interface SupplierProductLinkRequest {
	partyId: number;
	productCode: string;
	storageUnitId?: number;
}

export interface TalenomAccount {
	number: string;
	name: string;
	vatRate: number;
}

export interface TalenomDimension {
	key: string;
	type: TalenomDimensionType;
	value: string;
}

export type TalenomDimensionType = "CostCenter" | "CostType" | "Project" | "ProjectType";

export interface TalenomGetAccountsInternalRequest {
	targetId: number;
}

export interface TalenomGetDimensionsInternalRequest {
	targetId: number;
}

export interface TaskRuntimeData<V> {
	name: string;
	firstRun: Date;
	executions: number;
	executionsInterrupted: number;
	nextRun?: Date;
	runningStart?: Date;
	runningEnd?: Date;
	latestSuccessTime?: Date;
	latestSuccessResult?: V;
	latestFailedTime?: Date;
	validationStatus: string[];
	latestRunExceptional: boolean;
	stillRunning: boolean;
	stuck: boolean;
}

export interface ToggleCanUpdateNextPatchRequest {
	id: number;
}

export type TransferStatus = "OK" | "ERROR" | "MISSING";

export interface TransferredAccountingListRequest {
	clientId?: number;
	status?: TransferStatus;
	startDate?: Date;
	endDate?: Date;
	deleted: boolean;
}

export interface TransferredAccountingListResponse {
	businessDate: Date;
	locationName: string;
	accountingId: number;
	strategy: IntegrationStrategy;
	cttmId: number;
	status: string;
	shortStatus: string;
	message: string;
	finishTime: Date;
	attemptTime: Date;
	attemptCount: number;
	deleteTime: Date;
}

export interface Translate {
	key: string;
	deleteTime?: Date;
	fiValue?: string;
	svValue?: string;
	enValue?: string;
	fiDefault?: string;
	svDefault?: string;
	enDefault?: string;
	comment?: string;
}

export interface TranslateConfigMismatchResponse {
	deleted: Translate[];
	mismatch: Translate[];
	emptyStrings: Translate[];
}

export interface TranslateDeleteRequest {
	delete: Translate[];
}

export interface TranslateJsonDownloadInternalRequest {
	language: Language;
}

export interface TranslateLog {
	id: number;
	key: string;
	modifyActorId: number;
	modifyTime: Date;
	action: TranslateLogAction;
	message?: string;
}

export type TranslateLogAction = "NEW" | "DELETE" | "UNDELETE" | "EDIT";

export interface TranslateLogWithModifier extends TranslateLog {
	modifiedBy: string;
}

export interface TranslateUpdateRequest {
	key: string;
	fiDefault?: string;
	enDefault?: string;
	fiValue?: string;
	enValue?: string;
	comment?: string;
	delete: boolean;
	isNew: boolean;
}

export interface UnitByIdInternalRequest {
	clientId: number;
}

export interface UuidName {
	id: string;
	name: string;
}

export interface UuidName2 {
	id: string;
	name: string;
}

export interface VatCategory {
	id: number;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	name: string;
}

export interface VatCategoryByIdInternalRequest {
	id: number;
}

export interface VatCategoryByIdResponse {
	vatCategory: VatCategory;
	rows: VatCategoryRow[];
}

export interface VatCategoryRow {
	vatCategoryId: number;
	vatPercent: number;
	startDate: Date;
}

export interface VatCategoryUpdateRequest {
	id?: number;
	name: string;
	rows: VatCategoryUpdateRow[];
}

export interface VatCategoryUpdateRow {
	vatPercent: number;
	startDate: Date;
}

export interface VatCategoryWithRows {
	vatCategory: VatCategory;
	vatCategoryRows: VatCategoryRow[];
}

export interface VivaWalletEventData {
	sales: boolean;
	refund: boolean;
	reversal: boolean;
	Moto: boolean;
	Email: string;
	Phone: string;
	BankId: string;
	Systemic: boolean;
	Switching: boolean;
	ParentId: string;
	Amount: number;
	ChannelId: string;
	TerminalId: number;
	MerchantId: string;
	OrderCode: string;
	ProductId: string;
	StatusId: VivaWalletStatusId;
	FullName: string;
	ResellerId: string;
	InsDate: string;
	TotalFee: number;
	CardToken: string;
	CardNumber: string;
	TipAmount: number;
	SourceCode: string;
	SourceName: string;
	Latitude: string;
	Longitude: string;
	CompanyName: string;
	TransactionId: string;
	CompanyTitle: string;
	PanEntryMode: string;
	ReferenceNumber: string;
	ResponseCode: string;
	CurrencyCode: string;
	OrderCulture: string;
	MerchantTrns: string;
	CustomerTrns: string;
	IsManualRefund: boolean;
	TargetPersonId: string;
	TargetWalletId: string;
	LoyaltyTriggered: boolean;
	TransactionTypeId: number;
	AuthorizationId: string;
	TotalInstallments: number;
	CardCountryCode: string;
	CardIssuingBank: string;
	RedeemedAmount: number;
	ClearanceDate: Date;
	CurrentInstallment: number;
	Tags: string[];
	BillId: string;
	ResellerSourceCode: string;
	ResellerSourceName: string;
	ResellerCompanyName: string;
	ResellerSourceAddress: string;
	CardExpirationDate: string;
	RetrievalReferenceNumber: string;
	AssignedMerchantUsers: string[];
	AssignedResellerUsers: string[];
	CardTypeId: number;
	ResponseEventId: number;
	ElectronicCommerceIndicator: string;
	DigitalWalletId: number;
	CardUniqueReference: string;
	DualMessage: boolean;
}

export interface VivaWalletFee extends AbstractPosItem {
	feePercentage: number;
	feeCents: number;
}

export interface VivaWalletFeeByIdInternalRequest {
	id: string;
}

export interface VivaWalletFeeListInternalRequest {
	id?: number;
	type: PrivilegeType;
}

export interface VivaWalletFeeListOnSpecificLevelInternalRequest {
	id: number;
	type: PrivilegeType;
}

export interface VivaWalletFeeUpdateRequest extends PosItemUpdateRequestBase {
	feePercentage: number;
	feeCents: number;
}

export interface VivaWalletIsvCredentials {
	id: number;
	clientId: string;
	clientSecret: string;
}

export interface VivaWalletIsvCredentialsUpdateRequest {
	clientId: string;
	clientSecret: string;
}

export interface VivaWalletPaymentInfo extends AbstractPosItemClientSpecific {
	locationId?: string;
	merchantId: string;
	posApiClientId: string;
	posApiClientSecret: string;
	sourceCode: string;
	pinCode: string;
}

export interface VivaWalletPaymentInfoByIdInternalRequest {
	id: string;
}

export interface VivaWalletPaymentInfoListInternalRequest {
	id?: number;
}

export interface VivaWalletPaymentInfoUpdateRequest extends PosItemUpdateRequestBase {
	locationId?: string;
	merchantId: string;
	posApiClientId: string;
	posApiClientSecret: string;
	sourceCode: string;
	pinCode: string;
}

export type VivaWalletStatusId = "F" | "A" | "C" | "E" | "R" | "X" | "M" | "MA" | "MI" | "ML" | "MS" | "MW";

export interface VivaWalletWebhookResponse {
	Url: string;
	EventData: VivaWalletEventData;
	Created: string;
	CorrelationId: string;
	EventTypeId: number;
	Delay: number;
	MessageId: string;
	RecipientId: string;
	MessageTypeId: number;
}

export interface VpnMigrateRequest {
	clientId: number;
}

export type WarningReason = "BACKUP_TOO_SMALL" | "BACKUP_TOO_OLD" | "CLOCK_DRIFT" | "HEALTH_TOO_OLD" | "TERMINALQUEUE_HAS_ENTRIES" | "EMAILQUEUE_HAS_ENTRIES" | "AUTHORIZATIONHOLDQUEUE_HAS_ENTRIES" | "RECEIPTHERO_HAS_ENTRIES" | "DASHBOARDCONTROLLEDITEMQUEUE_HAS_ENTRIES" | "EXTERNAL_NETTIKAUPPA_COMPLETED_SLOW" | "STATE_IS_ERROR" | "STATE_IS_DOWN" | "JAVA_LOW_MEMORY" | "UPDATE_WINDOW_MISSING" | "LOAD_AVG_HIGH";

export interface WaybillCanLoginInternalRequest {
	clientId: number;
}

export type WaybillError = "CONNECT_TIMEOUT" | "RESPONSE_NOT_OK" | "RUN_INTERRUPT" | "RUN_CANCEL";

export interface WaybillLoginInternalRequest {
	clientId: number;
}

export interface WaybillLoginResponse {
	url: string;
}

// Discovered object keys that should be read as a Date type
const dates: { [key: string]: true } = {
	"ClearanceDate": true,
	"accountingDate": true,
	"acquired": true,
	"archiveTime": true,
	"attemptTime": true,
	"authorizationHoldTimes": true,
	"backupTime": true,
	"bookkeepingCustomerLastUpdate": true,
	"businessDate": true,
	"changeTime": true,
	"clientCreateTime": true,
	"completedTime": true,
	"createTime": true,
	"cutoffDate": true,
	"dashboardControlledItemTimes": true,
	"deleteTime": true,
	"deliveryDate": true,
	"emailTimes": true,
	"endAcquiredDate": true,
	"endBusinessDate": true,
	"endDate": true,
	"endDeliveryDate": true,
	"endManifestTimestamp": true,
	"eventTime": true,
	"expiresFrom": true,
	"expiresTo": true,
	"expiryDate": true,
	"featureUpdateTime": true,
	"finishTime": true,
	"firstBill": true,
	"firstRun": true,
	"fromDate": true,
	"healthCreateTime": true,
	"healthModifyTime": true,
	"invoiceDate": true,
	"lastBackupTime": true,
	"lastDayOfMonth": true,
	"lastResultTime": true,
	"lastSeen": true,
	"lastSendTime": true,
	"latestFailedTime": true,
	"latestSuccessTime": true,
	"manifestTimestamp": true,
	"maxTime": true,
	"migrationFinishTime": true,
	"migrationReadyTime": true,
	"migrationStartTime": true,
	"missingAccountingsDates": true,
	"missingDates": true,
	"modifyTime": true,
	"nextRun": true,
	"nextUpdate": true,
	"notAfter": true,
	"oauthAccessTokenExpires": true,
	"oauthRefreshTokenFetched": true,
	"printTimes": true,
	"receiptHeroTimes": true,
	"reportDate": true,
	"retired": true,
	"runningEnd": true,
	"runningStart": true,
	"serverTime": true,
	"startAcquiredDate": true,
	"startBusinessDate": true,
	"startDate": true,
	"startDeliveryDate": true,
	"startManifestTimestamp": true,
	"stateTime": true,
	"terminalTimes": true,
	"version": true,
	"waybillVersion": true,
};

@autoinject
export class MyHttpApi {
	// Methods from fi.bel.rj.api.clientsale.AccountingAPI
	accountingDelete(request: ClientSaleDeleteAccountingRequest) {
		return this.doPut<void>('Accounting/delete', request);
	}
	accountingFetch(request: ClientSaleAccountingFetchRequest) {
		return this.doPut<Import2Result>('Accounting/fetch', request);
	}
	accountingLatest(request: AccountingLatestInternalRequest) {
		return this.doGet<ClientAccounting | undefined>('Accounting/latest', request);
	}
	accountingLatestUrl(request: AccountingLatestInternalRequest) {
		return this.getUrl('Accounting/latest', request);
	}
	accountingManualSend(request: AccountingManualSendRequest) {
		return this.doPut<AccountingManualSendResponse>('Accounting/manualSend', request);
	}
	accountingTransferredAccountingList(request: TransferredAccountingListRequest) {
		return this.doPut<TransferredAccountingListResponse[]>('Accounting/transferredAccountingList', request);
	}

	// Methods from fi.bel.rj.api.ActorAPI
	actorAdminById(request: ActorAdminByIdInternalRequest) {
		return this.doGet<Actor>('Actor/adminById', request);
	}
	actorAdminByIdUrl(request: ActorAdminByIdInternalRequest) {
		return this.getUrl('Actor/adminById', request);
	}
	actorAdminList() {
		return this.doGet<ActorListResponse[]>('Actor/adminList', {});
	}
	actorAdminListUrl() {
		return this.getUrl('Actor/adminList', {});
	}
	actorAdminListBusinessGroup(request: ActorAdminListBusinessGroupInternalRequest) {
		return this.doGet<IdName[]>('Actor/adminListBusinessGroup', request);
	}
	actorAdminListBusinessGroupUrl(request: ActorAdminListBusinessGroupInternalRequest) {
		return this.getUrl('Actor/adminListBusinessGroup', request);
	}
	actorAdminListClient(request: ActorAdminListClientInternalRequest) {
		return this.doGet<IdName[]>('Actor/adminListClient', request);
	}
	actorAdminListClientUrl(request: ActorAdminListClientInternalRequest) {
		return this.getUrl('Actor/adminListClient', request);
	}
	actorAdminListCorporation(request: ActorAdminListCorporationInternalRequest) {
		return this.doGet<IdName[]>('Actor/adminListCorporation', request);
	}
	actorAdminListCorporationUrl(request: ActorAdminListCorporationInternalRequest) {
		return this.getUrl('Actor/adminListCorporation', request);
	}
	actorAdminUpdate(request: ActorAdminUpdateRequest) {
		return this.doPut<Actor>('Actor/adminUpdate', request);
	}
	actorChangePasswordWithSession(request: ChangePasswordWithSessionRequest) {
		return this.doPut<void>('Actor/changePasswordWithSession', request);
	}
	actorCreateUsersList() {
		return this.doGet<ActorListResponse[]>('Actor/createUsersList', {});
	}
	actorCreateUsersListUrl() {
		return this.getUrl('Actor/createUsersList', {});
	}
	actorCreateUsersUpdate(request: OtherActorUpdateRequest) {
		return this.doPut<Actor>('Actor/createUsersUpdate', request);
	}
	actorDisableOtp(request: DisableOtpRequest) {
		return this.doPut<Actor>('Actor/disableOtp', request);
	}
	actorListBusinessGroup(request: ActorListBusinessGroupInternalRequest) {
		return this.doGet<BusinessGroupsWithUnits[]>('Actor/listBusinessGroup', request);
	}
	actorListBusinessGroupUrl(request: ActorListBusinessGroupInternalRequest) {
		return this.getUrl('Actor/listBusinessGroup', request);
	}
	actorListClient() {
		return this.doGet<IdName[]>('Actor/listClient', {});
	}
	actorListClientUrl() {
		return this.getUrl('Actor/listClient', {});
	}
	actorListWithAccessByClientId(request: ActorListWithAccessByClientIdInternalRequest) {
		return this.doGet<ActorWithAccessResponse[]>('Actor/listWithAccessByClientId', request);
	}
	actorListWithAccessByClientIdUrl(request: ActorListWithAccessByClientIdInternalRequest) {
		return this.getUrl('Actor/listWithAccessByClientId', request);
	}
	actorOtherById(request: ActorOtherByIdInternalRequest) {
		return this.doGet<ActorWithPermissionsResponse>('Actor/otherById', request);
	}
	actorOtherByIdUrl(request: ActorOtherByIdInternalRequest) {
		return this.getUrl('Actor/otherById', request);
	}
	actorRemoveOneBusinessGroup(request: RemoveOneActorBusinessGroupRequest) {
		return this.doPut<void>('Actor/removeOneBusinessGroup', request);
	}
	actorRemoveOneClient(request: RemoveOneActorClientRequest) {
		return this.doPut<void>('Actor/removeOneClient', request);
	}
	actorRemoveOneCorporation(request: RemoveOneActorCorporationRequest) {
		return this.doPut<void>('Actor/removeOneCorporation', request);
	}
	actorSelf() {
		return this.doGet<ActorWithPermissionsResponse>('Actor/self', {});
	}
	actorSelfUrl() {
		return this.getUrl('Actor/self', {});
	}
	actorUpdateSelf(request: SelfNameUpdateReqeust) {
		return this.doPut<void>('Actor/updateSelf', request);
	}

	// Methods from fi.bel.rj.api.ADSessionAPI
	aDSessionById(request: ADSessionByIdInternalRequest) {
		return this.doGet<Session | undefined>('ADSession/byId', request);
	}
	aDSessionByIdUrl(request: ADSessionByIdInternalRequest) {
		return this.getUrl('ADSession/byId', request);
	}
	aDSessionLogin() {
		return this.doGet<unknown>('ADSession/login', {});
	}
	aDSessionLoginUrl() {
		return this.getUrl('ADSession/login', {});
	}
	aDSessionLogin2(request: ADSessionLogin2InternalRequest) {
		return this.doGet<unknown>('ADSession/login2', request);
	}
	aDSessionLogin2Url(request: ADSessionLogin2InternalRequest) {
		return this.getUrl('ADSession/login2', request);
	}
	aDSessionOtp(request: ADSessionOtpRequest) {
		return this.doPut<Session>('ADSession/otp', request);
	}

	// Methods from fi.bel.rj.api.BookkeepingCustomerAPI
	bookkeepingCustomerApiVsDbDiff(request: BookkeepingCustomerApiVsDbDiffInternalRequest) {
		return this.doGet<BookkeepingCustomerDiff[]>('BookkeepingCustomer/apiVsDbDiff', request);
	}
	bookkeepingCustomerApiVsDbDiffUrl(request: BookkeepingCustomerApiVsDbDiffInternalRequest) {
		return this.getUrl('BookkeepingCustomer/apiVsDbDiff', request);
	}
	bookkeepingCustomerApiVsDbDiffExcel(request: BookkeepingCustomerApiVsDbDiffExcelInternalRequest) {
		return this.doGet<unknown>('BookkeepingCustomer/apiVsDbDiffExcel', request);
	}
	bookkeepingCustomerApiVsDbDiffExcelUrl(request: BookkeepingCustomerApiVsDbDiffExcelInternalRequest) {
		return this.getUrl('BookkeepingCustomer/apiVsDbDiffExcel', request);
	}
	bookkeepingCustomerById(request: BookkeepingCustomerByIdInternalRequest) {
		return this.doGet<BookkeepingCustomer>('BookkeepingCustomer/byId', request);
	}
	bookkeepingCustomerByIdUrl(request: BookkeepingCustomerByIdInternalRequest) {
		return this.getUrl('BookkeepingCustomer/byId', request);
	}
	bookkeepingCustomerCombinedInvoicesByCustomer(request: BookkeepingCustomerCombinedInvoicesByCustomerInternalRequest) {
		return this.doGet<BookkeepingCustomerCombinedInvoices[]>('BookkeepingCustomer/combinedInvoicesByCustomer', request);
	}
	bookkeepingCustomerCombinedInvoicesByCustomerUrl(request: BookkeepingCustomerCombinedInvoicesByCustomerInternalRequest) {
		return this.getUrl('BookkeepingCustomer/combinedInvoicesByCustomer', request);
	}
	bookkeepingCustomerCombinedInvoicesByCustomerAndClient(request: BookkeepingCustomerCombinedInvoicesByCustomerAndClientInternalRequest) {
		return this.doGet<BookkeepingCustomerCombinedInvoices | undefined>('BookkeepingCustomer/combinedInvoicesByCustomerAndClient', request);
	}
	bookkeepingCustomerCombinedInvoicesByCustomerAndClientUrl(request: BookkeepingCustomerCombinedInvoicesByCustomerAndClientInternalRequest) {
		return this.getUrl('BookkeepingCustomer/combinedInvoicesByCustomerAndClient', request);
	}
	bookkeepingCustomerExcelUpload(request: CustomerExcelUploadRequest) {
		return this.doPut<void>('BookkeepingCustomer/excelUpload', request);
	}
	bookkeepingCustomerImportStatus() {
		return this.doGet<TaskRuntimeData<void> | undefined>('BookkeepingCustomer/importStatus', {});
	}
	bookkeepingCustomerImportStatusUrl() {
		return this.getUrl('BookkeepingCustomer/importStatus', {});
	}
	bookkeepingCustomerList(request: BookkeepingCustomerListInternalRequest) {
		return this.doGet<BookkeepingCustomer[]>('BookkeepingCustomer/list', request);
	}
	bookkeepingCustomerListUrl(request: BookkeepingCustomerListInternalRequest) {
		return this.getUrl('BookkeepingCustomer/list', request);
	}
	bookkeepingCustomerListAllWithCombinedBilling() {
		return this.doGet<BookkeepingCustomer[]>('BookkeepingCustomer/listAllWithCombinedBilling', {});
	}
	bookkeepingCustomerListAllWithCombinedBillingUrl() {
		return this.getUrl('BookkeepingCustomer/listAllWithCombinedBilling', {});
	}
	bookkeepingCustomerListByClientWithCombinedBilling(request: BookkeepingCustomerListByClientWithCombinedBillingInternalRequest) {
		return this.doGet<BookkeepingCustomer[]>('BookkeepingCustomer/listByClientWithCombinedBilling', request);
	}
	bookkeepingCustomerListByClientWithCombinedBillingUrl(request: BookkeepingCustomerListByClientWithCombinedBillingInternalRequest) {
		return this.getUrl('BookkeepingCustomer/listByClientWithCombinedBilling', request);
	}
	bookkeepingCustomerListByTarget(request: BookkeepingCustomerListByTargetInternalRequest) {
		return this.doGet<BookkeepingCustomer[]>('BookkeepingCustomer/listByTarget', request);
	}
	bookkeepingCustomerListByTargetUrl(request: BookkeepingCustomerListByTargetInternalRequest) {
		return this.getUrl('BookkeepingCustomer/listByTarget', request);
	}
	bookkeepingCustomerListByTargetWithCombinedBilling(request: BookkeepingCustomerListByTargetWithCombinedBillingInternalRequest) {
		return this.doGet<BookkeepingCustomerCombinedInvoices[]>('BookkeepingCustomer/listByTargetWithCombinedBilling', request);
	}
	bookkeepingCustomerListByTargetWithCombinedBillingUrl(request: BookkeepingCustomerListByTargetWithCombinedBillingInternalRequest) {
		return this.getUrl('BookkeepingCustomer/listByTargetWithCombinedBilling', request);
	}
	bookkeepingCustomerUpdate(request: CustomerUpdateRequest) {
		return this.doPut<BookkeepingCustomer>('BookkeepingCustomer/update', request);
	}

	// Methods from fi.bel.rj.api.BusinessGroupAPI
	businessGroupById(request: BusinessGroupByIdInternalRequest) {
		return this.doGet<BusinessGroup>('BusinessGroup/byId', request);
	}
	businessGroupByIdUrl(request: BusinessGroupByIdInternalRequest) {
		return this.getUrl('BusinessGroup/byId', request);
	}
	businessGroupInfoById(request: BusinessGroupInfoByIdInternalRequest) {
		return this.doGet<BusinessGroupInfo | undefined>('BusinessGroup/infoById', request);
	}
	businessGroupInfoByIdUrl(request: BusinessGroupInfoByIdInternalRequest) {
		return this.getUrl('BusinessGroup/infoById', request);
	}
	businessGroupList() {
		return this.doGet<BusinessGroup[]>('BusinessGroup/list', {});
	}
	businessGroupListUrl() {
		return this.getUrl('BusinessGroup/list', {});
	}
	businessGroupListByCorporationId(request: BusinessGroupListByCorporationIdInternalRequest) {
		return this.doGet<IdName[]>('BusinessGroup/listByCorporationId', request);
	}
	businessGroupListByCorporationIdUrl(request: BusinessGroupListByCorporationIdInternalRequest) {
		return this.getUrl('BusinessGroup/listByCorporationId', request);
	}
	businessGroupListGiftCardDebtAccountById(request: BusinessGroupListGiftCardDebtAccountByIdInternalRequest) {
		return this.doGet<BusinessGroupGiftCardDebtAccount[]>('BusinessGroup/listGiftCardDebtAccountById', request);
	}
	businessGroupListGiftCardDebtAccountByIdUrl(request: BusinessGroupListGiftCardDebtAccountByIdInternalRequest) {
		return this.getUrl('BusinessGroup/listGiftCardDebtAccountById', request);
	}
	businessGroupReadOnlyListGiftCardDebtAccountByClientId(request: BusinessGroupReadOnlyListGiftCardDebtAccountByClientIdInternalRequest) {
		return this.doGet<BusinessGroupGiftCardDebtListResponse[]>('BusinessGroup/readOnlyListGiftCardDebtAccountByClientId', request);
	}
	businessGroupReadOnlyListGiftCardDebtAccountByClientIdUrl(request: BusinessGroupReadOnlyListGiftCardDebtAccountByClientIdInternalRequest) {
		return this.getUrl('BusinessGroup/readOnlyListGiftCardDebtAccountByClientId', request);
	}
	businessGroupRestrictionsByClientId(request: BusinessGroupRestrictionsByClientIdInternalRequest) {
		return this.doGet<BusinessGroupRestrictions>('BusinessGroup/restrictionsByClientId', request);
	}
	businessGroupRestrictionsByClientIdUrl(request: BusinessGroupRestrictionsByClientIdInternalRequest) {
		return this.getUrl('BusinessGroup/restrictionsByClientId', request);
	}
	businessGroupUpdate(request: BusinessGroupUpdateRequest) {
		return this.doPut<BusinessGroup>('BusinessGroup/update', request);
	}
	businessGroupUpdateGiftCardDebtAccounts(request: BusinessGroupUpdateGiftCardDebtAccountsRequest) {
		return this.doPut<void>('BusinessGroup/updateGiftCardDebtAccounts', request);
	}

	// Methods from fi.bel.rj.api.ClientAPI
	clientActiveModuleByClientIdAndType(request: ClientActiveModuleByClientIdAndTypeInternalRequest) {
		return this.doGet<ClientModule | undefined>('Client/activeModuleByClientIdAndType', request);
	}
	clientActiveModuleByClientIdAndTypeUrl(request: ClientActiveModuleByClientIdAndTypeInternalRequest) {
		return this.getUrl('Client/activeModuleByClientIdAndType', request);
	}
	clientAddModule(request: ClientAddModuleRequest) {
		return this.doPut<ClientModule>('Client/addModule', request);
	}
	clientBackup(request: ClientGenericRequest) {
		return this.doPut<boolean>('Client/backup', request);
	}
	clientById(request: ClientByIdInternalRequest) {
		return this.doGet<Client>('Client/byId', request);
	}
	clientByIdUrl(request: ClientByIdInternalRequest) {
		return this.getUrl('Client/byId', request);
	}
	clientClientEmergencyUpdate(request: ClientEmergencyUpdateRequest) {
		return this.doPut<void>('Client/clientEmergencyUpdate', request);
	}
	clientClientHealth() {
		return this.doGet<ClientHealth[]>('Client/clientHealth', {});
	}
	clientClientHealthUrl() {
		return this.getUrl('Client/clientHealth', {});
	}
	clientClientHealthAndRepositories() {
		return this.doGet<ClientHealthAndRepositories[]>('Client/clientHealthAndRepositories', {});
	}
	clientClientHealthAndRepositoriesUrl() {
		return this.getUrl('Client/clientHealthAndRepositories', {});
	}
	clientClientHealthAndRepositoriesByClientId(request: ClientClientHealthAndRepositoriesByClientIdInternalRequest) {
		return this.doGet<ClientHealthAndRepositories>('Client/clientHealthAndRepositoriesByClientId', request);
	}
	clientClientHealthAndRepositoriesByClientIdUrl(request: ClientClientHealthAndRepositoriesByClientIdInternalRequest) {
		return this.getUrl('Client/clientHealthAndRepositoriesByClientId', request);
	}
	clientDelete(request: ClientGenericRequest) {
		return this.doPut<Client>('Client/delete', request);
	}
	clientDeleteModule(request: ClientGenericRequest) {
		return this.doPut<ClientModule>('Client/deleteModule', request);
	}
	clientHealthAndRepositoriesWithCertificates(request: ClientHealthAndRepositoriesWithCertificatesInternalRequest) {
		return this.doGet<ClientStatusResponse>('Client/healthAndRepositoriesWithCertificates', request);
	}
	clientHealthAndRepositoriesWithCertificatesUrl(request: ClientHealthAndRepositoriesWithCertificatesInternalRequest) {
		return this.getUrl('Client/healthAndRepositoriesWithCertificates', request);
	}
	clientHealthMemoryByClientId(request: ClientHealthMemoryByClientIdInternalRequest) {
		return this.doGet<HealthMemory | undefined>('Client/healthMemoryByClientId', request);
	}
	clientHealthMemoryByClientIdUrl(request: ClientHealthMemoryByClientIdInternalRequest) {
		return this.getUrl('Client/healthMemoryByClientId', request);
	}
	clientInfoById(request: ClientInfoByIdInternalRequest) {
		return this.doGet<ClientInfo | undefined>('Client/infoById', request);
	}
	clientInfoByIdUrl(request: ClientInfoByIdInternalRequest) {
		return this.getUrl('Client/infoById', request);
	}
	clientList() {
		return this.doGet<Client[]>('Client/list', {});
	}
	clientListUrl() {
		return this.getUrl('Client/list', {});
	}
	clientListAllWithIntegration() {
		return this.doGet<Client[]>('Client/listAllWithIntegration', {});
	}
	clientListAllWithIntegrationUrl() {
		return this.getUrl('Client/listAllWithIntegration', {});
	}
	clientListBackups(request: ClientListBackupsInternalRequest) {
		return this.doGet<BackupMeta[]>('Client/listBackups', request);
	}
	clientListBackupsUrl(request: ClientListBackupsInternalRequest) {
		return this.getUrl('Client/listBackups', request);
	}
	clientListByBusinessGroupId(request: ClientListByBusinessGroupIdInternalRequest) {
		return this.doGet<IdName[]>('Client/listByBusinessGroupId', request);
	}
	clientListByBusinessGroupIdUrl(request: ClientListByBusinessGroupIdInternalRequest) {
		return this.getUrl('Client/listByBusinessGroupId', request);
	}
	clientListDeleted() {
		return this.doGet<Client[]>('Client/listDeleted', {});
	}
	clientListDeletedUrl() {
		return this.getUrl('Client/listDeleted', {});
	}
	clientListModule(request: ClientListModuleInternalRequest) {
		return this.doGet<ClientModule[]>('Client/listModule', request);
	}
	clientListModuleUrl(request: ClientListModuleInternalRequest) {
		return this.getUrl('Client/listModule', request);
	}
	clientListOwnModules() {
		return this.doGet<ClientAndModules[]>('Client/listOwnModules', {});
	}
	clientListOwnModulesUrl() {
		return this.getUrl('Client/listOwnModules', {});
	}
	clientLocationNames(request: ClientLocationNamesInternalRequest) {
		return this.doGet<string[]>('Client/locationNames', request);
	}
	clientLocationNamesUrl(request: ClientLocationNamesInternalRequest) {
		return this.getUrl('Client/locationNames', request);
	}
	clientMake(request: ClientMakeRequest) {
		return this.doPut<Client>('Client/make', request);
	}
	clientModuleByClientIdAndType(request: ClientModuleByClientIdAndTypeInternalRequest) {
		return this.doGet<ClientModule | undefined>('Client/moduleByClientIdAndType', request);
	}
	clientModuleByClientIdAndTypeUrl(request: ClientModuleByClientIdAndTypeInternalRequest) {
		return this.getUrl('Client/moduleByClientIdAndType', request);
	}
	clientPosItemRunnableDataContainerDebug(request: ClientPosItemRunnableDataContainerDebugInternalRequest) {
		return this.doGet<ClientDataContainer>('Client/posItemRunnableDataContainerDebug', request);
	}
	clientPosItemRunnableDataContainerDebugUrl(request: ClientPosItemRunnableDataContainerDebugInternalRequest) {
		return this.getUrl('Client/posItemRunnableDataContainerDebug', request);
	}
	clientPosItemRunnableRequestDebug(request: ClientPosItemRunnableRequestDebugInternalRequest) {
		return this.doGet<PosPosItemUpdateRequest>('Client/posItemRunnableRequestDebug', request);
	}
	clientPosItemRunnableRequestDebugUrl(request: ClientPosItemRunnableRequestDebugInternalRequest) {
		return this.getUrl('Client/posItemRunnableRequestDebug', request);
	}
	clientRefreshVpn(request: VpnMigrateRequest) {
		return this.doPut<void>('Client/refreshVpn', request);
	}
	clientReplaceHttpsCertificate(request: ClientReplaceHttpsCertificateRequest) {
		return this.doPut<void>('Client/replaceHttpsCertificate', request);
	}
	clientRestore(request: ClientRestoreRequest) {
		return this.doPut<boolean>('Client/restore', request);
	}
	clientRestoreFromFile(request: ClientRestoreFromFileRequest) {
		return this.doPut<boolean>('Client/restoreFromFile', request);
	}
	clientSaveUpdateWindow(request: ClientSaveUpdateWindowRequest) {
		return this.doPut<void>('Client/saveUpdateWindow', request);
	}
	clientSettingsUpdate(request: ClientSettingsUpdateRequest) {
		return this.doPut<Client>('Client/settingsUpdate', request);
	}
	clientStateHistory(request: ClientStateHistoryInternalRequest) {
		return this.doGet<ClientState[]>('Client/stateHistory', request);
	}
	clientStateHistoryUrl(request: ClientStateHistoryInternalRequest) {
		return this.getUrl('Client/stateHistory', request);
	}
	clientTargetBranch(request: ClientTargetBranchUpdateRequest) {
		return this.doPut<void>('Client/targetBranch', request);
	}
	clientToggleCanUpdateNextPatch(request: ToggleCanUpdateNextPatchRequest) {
		return this.doPut<Client>('Client/toggleCanUpdateNextPatch', request);
	}
	clientUndelete(request: ClientGenericRequest) {
		return this.doPut<boolean>('Client/undelete', request);
	}
	clientUpdate(request: ClientUpdateRequest) {
		return this.doPut<Client>('Client/update', request);
	}
	clientUpdateJava(request: ClientGenericRequest) {
		return this.doPut<boolean>('Client/updateJava', request);
	}
	clientUpdateNginxConf(request: ClientGenericRequest) {
		return this.doPut<void>('Client/updateNginxConf', request);
	}
	clientUpdatePostgresql(request: ClientGenericRequest) {
		return this.doPut<boolean>('Client/updatePostgresql', request);
	}
	clientUpdateServiceAndSocket(request: ClientGenericRequest) {
		return this.doPut<void>('Client/updateServiceAndSocket', request);
	}
	clientUpdateToSocket(request: ClientGenericRequest) {
		return this.doPut<void>('Client/updateToSocket', request);
	}
	clientUpgrade(request: ClientUpgradeRequest) {
		return this.doPut<boolean>('Client/upgrade', request);
	}

	// Methods from fi.bel.rj.api.clientsale.ClientIntegrationAPI
	clientIntegrationByClientId(request: ClientIntegrationByClientIdInternalRequest) {
		return this.doGet<ClientIntegration | undefined>('ClientIntegration/byClientId', request);
	}
	clientIntegrationByClientIdUrl(request: ClientIntegrationByClientIdInternalRequest) {
		return this.getUrl('ClientIntegration/byClientId', request);
	}
	clientIntegrationDelete(request: ClientIntegration) {
		return this.doPut<void>('ClientIntegration/delete', request);
	}
	clientIntegrationList() {
		return this.doGet<ClientIntegration[]>('ClientIntegration/list', {});
	}
	clientIntegrationListUrl() {
		return this.getUrl('ClientIntegration/list', {});
	}
	clientIntegrationListConfigMismatch(request: ClientIntegrationListConfigMismatchInternalRequest) {
		return this.doGet<ClientIntegration[]>('ClientIntegration/listConfigMismatch', request);
	}
	clientIntegrationListConfigMismatchUrl(request: ClientIntegrationListConfigMismatchInternalRequest) {
		return this.getUrl('ClientIntegration/listConfigMismatch', request);
	}
	clientIntegrationUpdate(request: ClientIntegrationAndDependenciesRequest) {
		return this.doPut<void>('ClientIntegration/update', request);
	}

	// Methods from fi.bel.rj.api.clientsale.ClientSaleBillingAPI
	clientSaleBillingBillingAndMetaById(request: ClientSaleBillingBillingAndMetaByIdInternalRequest) {
		return this.doGet<ClientSaleBillingAndMeta>('ClientSaleBilling/billingAndMetaById', request);
	}
	clientSaleBillingBillingAndMetaByIdUrl(request: ClientSaleBillingBillingAndMetaByIdInternalRequest) {
		return this.getUrl('ClientSaleBilling/billingAndMetaById', request);
	}
	clientSaleBillingCttm(request: ClientSaleBillingCttmInternalRequest) {
		return this.doGet<ClientTransferTargetMessage>('ClientSaleBilling/cttm', request);
	}
	clientSaleBillingCttmUrl(request: ClientSaleBillingCttmInternalRequest) {
		return this.getUrl('ClientSaleBilling/cttm', request);
	}
	clientSaleBillingDeleteCttm(request: ClientSaleBillingDeleteCttmRequest) {
		return this.doPut<void>('ClientSaleBilling/deleteCttm', request);
	}
	clientSaleBillingFetch(request: ClientSaleBillingFetchRequest) {
		return this.doPut<Import2Result>('ClientSaleBilling/fetch', request);
	}
	clientSaleBillingLatest(request: ClientSaleBillingLatestInternalRequest) {
		return this.doGet<ClientBilling | undefined>('ClientSaleBilling/latest', request);
	}
	clientSaleBillingLatestUrl(request: ClientSaleBillingLatestInternalRequest) {
		return this.getUrl('ClientSaleBilling/latest', request);
	}
	clientSaleBillingSentOrStillSending(request: ClientSaleBillingSentOrStillSendingInternalRequest) {
		return this.doGet<boolean>('ClientSaleBilling/sentOrStillSending', request);
	}
	clientSaleBillingSentOrStillSendingUrl(request: ClientSaleBillingSentOrStillSendingInternalRequest) {
		return this.getUrl('ClientSaleBilling/sentOrStillSending', request);
	}
	clientSaleBillingToggleHide(request: BillingUpdateRequest) {
		return this.doPut<void>('ClientSaleBilling/toggleHide', request);
	}
	clientSaleBillingUpdateBusinessDate(request: BillingUpdateBusinessDateRequest) {
		return this.doPut<void>('ClientSaleBilling/updateBusinessDate', request);
	}

	// Methods from fi.bel.rj.api.clientsale.ClientTransferTargetAPI
	clientTransferTargetById(request: ClientTransferTargetByIdInternalRequest) {
		return this.doGet<ClientTransferTarget>('ClientTransferTarget/byId', request);
	}
	clientTransferTargetByIdUrl(request: ClientTransferTargetByIdInternalRequest) {
		return this.getUrl('ClientTransferTarget/byId', request);
	}
	clientTransferTargetByIdForAdministration(request: ClientTransferTargetByIdForAdministrationInternalRequest) {
		return this.doGet<ClientTransferTarget>('ClientTransferTarget/byIdForAdministration', request);
	}
	clientTransferTargetByIdForAdministrationUrl(request: ClientTransferTargetByIdForAdministrationInternalRequest) {
		return this.getUrl('ClientTransferTarget/byIdForAdministration', request);
	}
	clientTransferTargetDelete(request: ClientTransferTargetDeleteRequest) {
		return this.doPut<void>('ClientTransferTarget/delete', request);
	}
	clientTransferTargetDependencies(request: ClientTransferTargetDependenciesInternalRequest) {
		return this.doGet<ClientTransferTargetDependenciesResponse>('ClientTransferTarget/dependencies', request);
	}
	clientTransferTargetDependenciesUrl(request: ClientTransferTargetDependenciesInternalRequest) {
		return this.getUrl('ClientTransferTarget/dependencies', request);
	}
	clientTransferTargetFullPaymentTypeList() {
		return this.doGet<LocalizedPaymentType[]>('ClientTransferTarget/fullPaymentTypeList', {});
	}
	clientTransferTargetFullPaymentTypeListUrl() {
		return this.getUrl('ClientTransferTarget/fullPaymentTypeList', {});
	}
	clientTransferTargetGetFennoaVat() {
		return this.doGet<FennoaVat[]>('ClientTransferTarget/getFennoaVat', {});
	}
	clientTransferTargetGetFennoaVatUrl() {
		return this.getUrl('ClientTransferTarget/getFennoaVat', {});
	}
	clientTransferTargetListAdministrable() {
		return this.doGet<ClientTransferTarget[]>('ClientTransferTarget/listAdministrable', {});
	}
	clientTransferTargetListAdministrableUrl() {
		return this.getUrl('ClientTransferTarget/listAdministrable', {});
	}
	clientTransferTargetListClient(request: ClientTransferTargetListClientInternalRequest) {
		return this.doGet<IdName[]>('ClientTransferTarget/listClient', request);
	}
	clientTransferTargetListClientUrl(request: ClientTransferTargetListClientInternalRequest) {
		return this.getUrl('ClientTransferTarget/listClient', request);
	}
	clientTransferTargetListSelectable(request: ClientTransferTargetListSelectableInternalRequest) {
		return this.doGet<ClientTransferTarget[]>('ClientTransferTarget/listSelectable', request);
	}
	clientTransferTargetListSelectableUrl(request: ClientTransferTargetListSelectableInternalRequest) {
		return this.getUrl('ClientTransferTarget/listSelectable', request);
	}
	clientTransferTargetManualByClientId(request: ClientTransfertTargetManualByClientId) {
		return this.doPut<ClientTransferTarget | undefined>('ClientTransferTarget/manualByClientId', request);
	}
	clientTransferTargetOwnList() {
		return this.doGet<ClientTransferTarget[]>('ClientTransferTarget/ownList', {});
	}
	clientTransferTargetOwnListUrl() {
		return this.getUrl('ClientTransferTarget/ownList', {});
	}
	clientTransferTargetUpdate(request: ClientTransferTargetAndDependenciesRequest) {
		return this.doPut<void>('ClientTransferTarget/update', request);
	}

	// Methods from fi.bel.rj.api.clientsale.CombinedBillingAPI
	combinedBillingCancel(request: CombinedBillingCancelOrResendRequest) {
		return this.doPut<void>('CombinedBilling/cancel', request);
	}
	combinedBillingErrorMessage(request: CombinedBillingErrorMessageInternalRequest) {
		return this.doGet<CombinedBillingErrorMessageResponse>('CombinedBilling/errorMessage', request);
	}
	combinedBillingErrorMessageUrl(request: CombinedBillingErrorMessageInternalRequest) {
		return this.getUrl('CombinedBilling/errorMessage', request);
	}
	combinedBillingInvoiceBills(request: InvoiceBillsRequest) {
		return this.doPut<InvoiceBillsResponse>('CombinedBilling/invoiceBills', request);
	}
	combinedBillingListCombinable(request: CombinedBillingListRequest) {
		return this.doPut<ClientBilling[]>('CombinedBilling/listCombinable', request);
	}
	combinedBillingListReady(request: CombinedBillingListReadyRequest) {
		return this.doPut<BookkeepingInvoicePreview[]>('CombinedBilling/listReady', request);
	}
	combinedBillingLocationList(request: CombinedBillingLocationListInternalRequest) {
		return this.doGet<Location[]>('CombinedBilling/locationList', request);
	}
	combinedBillingLocationListUrl(request: CombinedBillingLocationListInternalRequest) {
		return this.getUrl('CombinedBilling/locationList', request);
	}
	combinedBillingPreview(request: InvoiceBillsRequest) {
		return this.doPut<BookkeepingInvoicePreview[]>('CombinedBilling/preview', request);
	}
	combinedBillingResend(request: CombinedBillingCancelOrResendRequest) {
		return this.doPut<void>('CombinedBilling/resend', request);
	}
	combinedBillingSave(request: BillingUpdateRequest) {
		return this.doPut<void>('CombinedBilling/save', request);
	}
	combinedBillingTransferToSalesInvoice(request: BillingUpdateRequest) {
		return this.doPut<void>('CombinedBilling/transferToSalesInvoice', request);
	}

	// Methods from fi.bel.rj.api.CorporationAPI
	corporationById(request: CorporationByIdInternalRequest) {
		return this.doGet<Corporation>('Corporation/byId', request);
	}
	corporationByIdUrl(request: CorporationByIdInternalRequest) {
		return this.getUrl('Corporation/byId', request);
	}
	corporationInfoById(request: CorporationInfoByIdInternalRequest) {
		return this.doGet<CorporationInfo | undefined>('Corporation/infoById', request);
	}
	corporationInfoByIdUrl(request: CorporationInfoByIdInternalRequest) {
		return this.getUrl('Corporation/infoById', request);
	}
	corporationList() {
		return this.doGet<Corporation[]>('Corporation/list', {});
	}
	corporationListUrl() {
		return this.getUrl('Corporation/list', {});
	}
	corporationUpdate(request: CorporationUpdateRequest) {
		return this.doPut<Corporation>('Corporation/update', request);
	}

	// Methods from fi.bel.rj.api.CountryAPI
	countryList() {
		return this.doGet<Country[]>('Country/list', {});
	}
	countryListUrl() {
		return this.getUrl('Country/list', {});
	}

	// Methods from fi.bel.rj.api.EmailTemplateAPI
	emailTemplateById(request: EmailTemplateByIdInternalRequest) {
		return this.doGet<EmailTemplate>('EmailTemplate/byId', request);
	}
	emailTemplateByIdUrl(request: EmailTemplateByIdInternalRequest) {
		return this.getUrl('EmailTemplate/byId', request);
	}
	emailTemplateGetImg(request: EmailTemplateGetImgInternalRequest) {
		return this.doGet<unknown>('EmailTemplate/getImg', request);
	}
	emailTemplateGetImgUrl(request: EmailTemplateGetImgInternalRequest) {
		return this.getUrl('EmailTemplate/getImg', request);
	}
	emailTemplateList() {
		return this.doGet<EmailTemplate[]>('EmailTemplate/list', {});
	}
	emailTemplateListUrl() {
		return this.getUrl('EmailTemplate/list', {});
	}
	emailTemplateListGiftCard() {
		return this.doGet<EmailTemplate[]>('EmailTemplate/listGiftCard', {});
	}
	emailTemplateListGiftCardUrl() {
		return this.getUrl('EmailTemplate/listGiftCard', {});
	}
	emailTemplateUpdate(request: EmailTemplateUpdateRequest) {
		return this.doPut<EmailTemplate>('EmailTemplate/update', request);
	}

	// Methods from fi.bel.rj.api.integration.bookkeeping.FennoaAPI
	fennoaGetCodeDimensions(request: FennoaGetCodeDimensionsInternalRequest) {
		return this.doGet<FennoaDimensionCodeContainer[]>('Fennoa/getCodeDimensions', request);
	}
	fennoaGetCodeDimensionsUrl(request: FennoaGetCodeDimensionsInternalRequest) {
		return this.getUrl('Fennoa/getCodeDimensions', request);
	}
	fennoaGetTypeDimensions(request: FennoaGetTypeDimensionsInternalRequest) {
		return this.doGet<FennoaDimensionTypeContainer[]>('Fennoa/getTypeDimensions', request);
	}
	fennoaGetTypeDimensionsUrl(request: FennoaGetTypeDimensionsInternalRequest) {
		return this.getUrl('Fennoa/getTypeDimensions', request);
	}

	// Methods from fi.bel.rj.api.integration.bookkeeping.FivaldiAPI
	fivaldiAccountList(request: FivaldiAccountListInternalRequest) {
		return this.doGet<FivaldiAccount[]>('Fivaldi/accountList', request);
	}
	fivaldiAccountListUrl(request: FivaldiAccountListInternalRequest) {
		return this.getUrl('Fivaldi/accountList', request);
	}
	fivaldiBookkeepingCustomerList(request: FivaldiBookkeepingCustomerListInternalRequest) {
		return this.doGet<FivaldiBookkeepingCustomer[]>('Fivaldi/bookkeepingCustomerList', request);
	}
	fivaldiBookkeepingCustomerListUrl(request: FivaldiBookkeepingCustomerListInternalRequest) {
		return this.getUrl('Fivaldi/bookkeepingCustomerList', request);
	}
	fivaldiCompanyInvoicingDetails(request: FivaldiCompanyInvoicingDetailsInternalRequest) {
		return this.doGet<FivaldiCompanyInvoicingDetail>('Fivaldi/companyInvoicingDetails', request);
	}
	fivaldiCompanyInvoicingDetailsUrl(request: FivaldiCompanyInvoicingDetailsInternalRequest) {
		return this.getUrl('Fivaldi/companyInvoicingDetails', request);
	}
	fivaldiCompanyList(request: FivaldiCompanyListInternalRequest) {
		return this.doGet<FivaldiCompany[]>('Fivaldi/companyList', request);
	}
	fivaldiCompanyListUrl(request: FivaldiCompanyListInternalRequest) {
		return this.getUrl('Fivaldi/companyList', request);
	}
	fivaldiCompanyListAdmin(request: FivaldiCompanyListAdminInternalRequest) {
		return this.doGet<FivaldiCompany[]>('Fivaldi/companyListAdmin', request);
	}
	fivaldiCompanyListAdminUrl(request: FivaldiCompanyListAdminInternalRequest) {
		return this.getUrl('Fivaldi/companyListAdmin', request);
	}
	fivaldiCustomerList() {
		return this.doGet<FivaldiCustomer[]>('Fivaldi/customerList', {});
	}
	fivaldiCustomerListUrl() {
		return this.getUrl('Fivaldi/customerList', {});
	}
	fivaldiProductList(request: FivaldiProductListInternalRequest) {
		return this.doGet<FivaldiProduct[]>('Fivaldi/productList', request);
	}
	fivaldiProductListUrl(request: FivaldiProductListInternalRequest) {
		return this.getUrl('Fivaldi/productList', request);
	}
	fivaldiSalesPostingGroupList(request: FivaldiSalesPostingGroupListInternalRequest) {
		return this.doGet<SalesPostingGroupDTO[]>('Fivaldi/salesPostingGroupList', request);
	}
	fivaldiSalesPostingGroupListUrl(request: FivaldiSalesPostingGroupListInternalRequest) {
		return this.getUrl('Fivaldi/salesPostingGroupList', request);
	}
	fivaldiVoucherTypeList(request: FivaldiVoucherTypeListInternalRequest) {
		return this.doGet<FivaldiVoucherType[]>('Fivaldi/voucherTypeList', request);
	}
	fivaldiVoucherTypeListUrl(request: FivaldiVoucherTypeListInternalRequest) {
		return this.getUrl('Fivaldi/voucherTypeList', request);
	}

	// Methods from fi.bel.rj.api.ForgotPasswordAPI
	forgotPasswordChangePassword(request: ChangePasswordRequest) {
		return this.doPut<void>('ForgotPassword/changePassword', request);
	}
	forgotPasswordForgotPassword(request: ForgotPasswordRequest) {
		return this.doPut<boolean>('ForgotPassword/forgotPassword', request);
	}
	forgotPasswordGetUsernameChange(request: ForgotPasswordGetUsernameChangeInternalRequest) {
		return this.doGet<ForgotPasswordUsernameResponse>('ForgotPassword/getUsernameChange', request);
	}
	forgotPasswordGetUsernameChangeUrl(request: ForgotPasswordGetUsernameChangeInternalRequest) {
		return this.getUrl('ForgotPassword/getUsernameChange', request);
	}
	forgotPasswordGetUsernameSet(request: ForgotPasswordGetUsernameSetInternalRequest) {
		return this.doGet<ForgotPasswordUsernameResponse>('ForgotPassword/getUsernameSet', request);
	}
	forgotPasswordGetUsernameSetUrl(request: ForgotPasswordGetUsernameSetInternalRequest) {
		return this.getUrl('ForgotPassword/getUsernameSet', request);
	}
	forgotPasswordSetPassword(request: ChangePasswordRequest) {
		return this.doPut<void>('ForgotPassword/setPassword', request);
	}

	// Methods from fi.bel.rj.api.giftcard.GiftCardAPI
	giftCardById(request: GiftCardByIdInternalRequest) {
		return this.doGet<GiftCard>('GiftCard/byId', request);
	}
	giftCardByIdUrl(request: GiftCardByIdInternalRequest) {
		return this.getUrl('GiftCard/byId', request);
	}
	giftCardExcelUpload(request: GiftCardExcelUploadRequest) {
		return this.doPut<void>('GiftCard/excelUpload', request);
	}
	giftCardList(request: GiftCardListInternalRequest) {
		return this.doGet<GiftCardListResponse[]>('GiftCard/list', request);
	}
	giftCardListUrl(request: GiftCardListInternalRequest) {
		return this.getUrl('GiftCard/list', request);
	}
	giftCardListExcel(request: GiftCardListExcelInternalRequest) {
		return this.doGet<unknown>('GiftCard/listExcel', request);
	}
	giftCardListExcelUrl(request: GiftCardListExcelInternalRequest) {
		return this.getUrl('GiftCard/listExcel', request);
	}
	giftCardRenderPdf(request: GiftCardRenderPdfInternalRequest) {
		return this.doGet<unknown>('GiftCard/renderPdf', request);
	}
	giftCardRenderPdfUrl(request: GiftCardRenderPdfInternalRequest) {
		return this.getUrl('GiftCard/renderPdf', request);
	}
	giftCardSendEmail(request: GiftCardSendEmailInternalRequest) {
		return this.doGet<void>('GiftCard/sendEmail', request);
	}
	giftCardSendEmailUrl(request: GiftCardSendEmailInternalRequest) {
		return this.getUrl('GiftCard/sendEmail', request);
	}
	giftCardUpdate(request: GiftCardUpdateRequest) {
		return this.doPut<GiftCard>('GiftCard/update', request);
	}

	// Methods from fi.bel.rj.api.giftcard.GiftCardAuthorizationHoldAPI
	giftCardAuthorizationHoldList(request: GiftCardAuthorizationHoldListInternalRequest) {
		return this.doGet<GiftCardAuthorizationHold[]>('GiftCardAuthorizationHold/list', request);
	}
	giftCardAuthorizationHoldListUrl(request: GiftCardAuthorizationHoldListInternalRequest) {
		return this.getUrl('GiftCardAuthorizationHold/list', request);
	}

	// Methods from fi.bel.rj.api.giftcard.GiftCardLogAPI
	giftCardLogList(request: GiftCardLogListInternalRequest) {
		return this.doGet<GiftCardLogResponse[]>('GiftCardLog/list', request);
	}
	giftCardLogListUrl(request: GiftCardLogListInternalRequest) {
		return this.getUrl('GiftCardLog/list', request);
	}

	// Methods from fi.bel.rj.api.giftcard.GiftCardProductAPI
	giftCardProductById(request: GiftCardProductByIdInternalRequest) {
		return this.doGet<GiftCardProduct>('GiftCardProduct/byId', request);
	}
	giftCardProductByIdUrl(request: GiftCardProductByIdInternalRequest) {
		return this.getUrl('GiftCardProduct/byId', request);
	}
	giftCardProductList(request: GiftCardProductListRequest) {
		return this.doPut<GiftCardProduct[]>('GiftCardProduct/list', request);
	}
	giftCardProductUpdate(request: GiftCardProductUpdateRequest) {
		return this.doPut<GiftCardProduct>('GiftCardProduct/update', request);
	}

	// Methods from fi.bel.rj.api.giftcard.GiftCardSettingsAPI
	giftCardSettingsByGiftCardId(request: GiftCardSettingsByGiftCardIdInternalRequest) {
		return this.doGet<GiftCardSettingsStub>('GiftCardSettings/byGiftCardId', request);
	}
	giftCardSettingsByGiftCardIdUrl(request: GiftCardSettingsByGiftCardIdInternalRequest) {
		return this.getUrl('GiftCardSettings/byGiftCardId', request);
	}
	giftCardSettingsById(request: GiftCardSettingsByIdInternalRequest) {
		return this.doGet<GiftCardSettingsResponse>('GiftCardSettings/byId', request);
	}
	giftCardSettingsByIdUrl(request: GiftCardSettingsByIdInternalRequest) {
		return this.getUrl('GiftCardSettings/byId', request);
	}
	giftCardSettingsDelete(request: GiftCardSettingsDeleteRequest) {
		return this.doPut<void>('GiftCardSettings/delete', request);
	}
	giftCardSettingsHasSettingsByClientId(request: GiftCardSettingsHasSettingsByClientIdInternalRequest) {
		return this.doGet<boolean>('GiftCardSettings/hasSettingsByClientId', request);
	}
	giftCardSettingsHasSettingsByClientIdUrl(request: GiftCardSettingsHasSettingsByClientIdInternalRequest) {
		return this.getUrl('GiftCardSettings/hasSettingsByClientId', request);
	}
	giftCardSettingsList() {
		return this.doGet<GiftCardSettingsStub[]>('GiftCardSettings/list', {});
	}
	giftCardSettingsListUrl() {
		return this.getUrl('GiftCardSettings/list', {});
	}
	giftCardSettingsPdfById(request: GiftCardSettingsPdfByIdInternalRequest) {
		return this.doGet<GiftCardSettingsPdfByIdResponse>('GiftCardSettings/pdfById', request);
	}
	giftCardSettingsPdfByIdUrl(request: GiftCardSettingsPdfByIdInternalRequest) {
		return this.getUrl('GiftCardSettings/pdfById', request);
	}
	giftCardSettingsRender(request: GiftCardSettingsRenderInternalRequest) {
		return this.doGet<unknown>('GiftCardSettings/render', request);
	}
	giftCardSettingsRenderUrl(request: GiftCardSettingsRenderInternalRequest) {
		return this.getUrl('GiftCardSettings/render', request);
	}
	giftCardSettingsSample(request: GiftCardSettingsSampleInternalRequest) {
		return this.doGet<unknown>('GiftCardSettings/sample', request);
	}
	giftCardSettingsSampleUrl(request: GiftCardSettingsSampleInternalRequest) {
		return this.getUrl('GiftCardSettings/sample', request);
	}
	giftCardSettingsUpdate(request: GiftCardSettingsUpdateRequest) {
		return this.doPut<GiftCardSettingsResponse>('GiftCardSettings/update', request);
	}

	// Methods from fi.bel.rj.api.HelpAPI
	helpById(request: HelpByIdInternalRequest) {
		return this.doGet<Help>('Help/byId', request);
	}
	helpByIdUrl(request: HelpByIdInternalRequest) {
		return this.getUrl('Help/byId', request);
	}
	helpDelete(request: HelpDeleteRequest) {
		return this.doPut<void>('Help/delete', request);
	}
	helpGetHelp(request: HelpGetHelpInternalRequest) {
		return this.doGet<GetHelpResult | undefined>('Help/getHelp', request);
	}
	helpGetHelpUrl(request: HelpGetHelpInternalRequest) {
		return this.getUrl('Help/getHelp', request);
	}
	helpList(request: HelpListInternalRequest) {
		return this.doGet<Help[]>('Help/list', request);
	}
	helpListUrl(request: HelpListInternalRequest) {
		return this.getUrl('Help/list', request);
	}
	helpRelatedByMainId(request: HelpRelatedByMainIdInternalRequest) {
		return this.doGet<number[]>('Help/relatedByMainId', request);
	}
	helpRelatedByMainIdUrl(request: HelpRelatedByMainIdInternalRequest) {
		return this.getUrl('Help/relatedByMainId', request);
	}
	helpUpdate(request: HelpUpdateRequest) {
		return this.doPut<void>('Help/update', request);
	}

	// Methods from fi.bel.rj.api.HeralesAdminAPI
	heralesAdminEanLink(request: EanLinkRequest) {
		return this.doPut<void>('HeralesAdmin/eanLink', request);
	}
	heralesAdminHeralesPartyClientLocation(request: HeralesAdminHeralesPartyClientLocationInternalRequest) {
		return this.doGet<HeralesPartyClientLocation | undefined>('HeralesAdmin/heralesPartyClientLocation', request);
	}
	heralesAdminHeralesPartyClientLocationUrl(request: HeralesAdminHeralesPartyClientLocationInternalRequest) {
		return this.getUrl('HeralesAdmin/heralesPartyClientLocation', request);
	}
	heralesAdminHeralesPartyList(request: HeralesAdminHeralesPartyListInternalRequest) {
		return this.doGet<HeralesParty[]>('HeralesAdmin/heralesPartyList', request);
	}
	heralesAdminHeralesPartyListUrl(request: HeralesAdminHeralesPartyListInternalRequest) {
		return this.getUrl('HeralesAdmin/heralesPartyList', request);
	}
	heralesAdminHeralesPartySupplier(request: HeralesAdminHeralesPartySupplierInternalRequest) {
		return this.doGet<HeralesPartySupplier | undefined>('HeralesAdmin/heralesPartySupplier', request);
	}
	heralesAdminHeralesPartySupplierUrl(request: HeralesAdminHeralesPartySupplierInternalRequest) {
		return this.getUrl('HeralesAdmin/heralesPartySupplier', request);
	}
	heralesAdminHeralesPartyToClientLocation(request: HeralesPartyToClientLocation) {
		return this.doPut<void>('HeralesAdmin/heralesPartyToClientLocation', request);
	}
	heralesAdminHeralesPartyToSupplier(request: HeralesPartyToSupplierRequest) {
		return this.doPut<void>('HeralesAdmin/heralesPartyToSupplier', request);
	}
	heralesAdminManifestById(request: HeralesAdminManifestByIdInternalRequest) {
		return this.doGet<HeralesManifestByIdResponse>('HeralesAdmin/manifestById', request);
	}
	heralesAdminManifestByIdUrl(request: HeralesAdminManifestByIdInternalRequest) {
		return this.getUrl('HeralesAdmin/manifestById', request);
	}
	heralesAdminManifestDeleteById(request: HeralesManifestDeleteRequest) {
		return this.doPut<void>('HeralesAdmin/manifestDeleteById', request);
	}
	heralesAdminManifestList(request: HeralesAdminManifestListInternalRequest) {
		return this.doGet<HeralesManifestClientSide[]>('HeralesAdmin/manifestList', request);
	}
	heralesAdminManifestListUrl(request: HeralesAdminManifestListInternalRequest) {
		return this.getUrl('HeralesAdmin/manifestList', request);
	}
	heralesAdminManifestUndeleteById(request: HeralesManifestDeleteRequest) {
		return this.doPut<void>('HeralesAdmin/manifestUndeleteById', request);
	}
	heralesAdminMasterProductStorageUnitBySupplierCode(request: HeralesAdminMasterProductStorageUnitBySupplierCodeInternalRequest) {
		return this.doGet<MasterProductStorageUnit | undefined>('HeralesAdmin/masterProductStorageUnitBySupplierCode', request);
	}
	heralesAdminMasterProductStorageUnitBySupplierCodeUrl(request: HeralesAdminMasterProductStorageUnitBySupplierCodeInternalRequest) {
		return this.getUrl('HeralesAdmin/masterProductStorageUnitBySupplierCode', request);
	}
	heralesAdminMasterProductStorageUnitEanByEan(request: HeralesAdminMasterProductStorageUnitEanByEanInternalRequest) {
		return this.doGet<MasterProductStorageUnitEan | undefined>('HeralesAdmin/masterProductStorageUnitEanByEan', request);
	}
	heralesAdminMasterProductStorageUnitEanByEanUrl(request: HeralesAdminMasterProductStorageUnitEanByEanInternalRequest) {
		return this.getUrl('HeralesAdmin/masterProductStorageUnitEanByEan', request);
	}
	heralesAdminSupplierProductLink(request: SupplierProductLinkRequest) {
		return this.doPut<void>('HeralesAdmin/supplierProductLink', request);
	}
	heralesAdminToggleHide(request: HideRequest) {
		return this.doPut<void>('HeralesAdmin/toggleHide', request);
	}

	// Methods from fi.bel.rj.api.ImageAPI
	imageTest(request: ImageTestRequest) {
		return this.doPut<boolean>('Image/test', request);
	}

	// Methods from fi.bel.rj.api.IntegrationErrorMessageMapperAPI
	integrationErrorMessageMapperListRegex(request: IntegrationErrorMessageMapperListRegexInternalRequest) {
		return this.doGet<IntegrationErrorMessageMapper[]>('IntegrationErrorMessageMapper/listRegex', request);
	}
	integrationErrorMessageMapperListRegexUrl(request: IntegrationErrorMessageMapperListRegexInternalRequest) {
		return this.getUrl('IntegrationErrorMessageMapper/listRegex', request);
	}
	integrationErrorMessageMapperRegexById(request: IntegrationErrorMessageMapperRegexByIdInternalRequest) {
		return this.doGet<IntegrationErrorMessageMapper>('IntegrationErrorMessageMapper/regexById', request);
	}
	integrationErrorMessageMapperRegexByIdUrl(request: IntegrationErrorMessageMapperRegexByIdInternalRequest) {
		return this.getUrl('IntegrationErrorMessageMapper/regexById', request);
	}
	integrationErrorMessageMapperRegexUpdate(request: IntegrationErrorMessageMapperUpdateRequest) {
		return this.doPut<void>('IntegrationErrorMessageMapper/regexUpdate', request);
	}

	// Methods from fi.bel.rj.api.IntegrationMessageAPI
	integrationMessageDelete(request: IntegrationMessageDeleteRequest) {
		return this.doPut<void>('IntegrationMessage/delete', request);
	}
	integrationMessageList(request: IntegrationMessageListRequest) {
		return this.doPut<IntegrationMessageExtended[]>('IntegrationMessage/list', request);
	}

	// Methods from fi.bel.rj.api.MasterProductAPI
	masterProductById(request: MasterProductByIdInternalRequest) {
		return this.doGet<MasterProductByIdResult>('MasterProduct/byId', request);
	}
	masterProductByIdUrl(request: MasterProductByIdInternalRequest) {
		return this.getUrl('MasterProduct/byId', request);
	}
	masterProductCategoryDelete(request: MasterProductDeleteRequest) {
		return this.doPut<void>('MasterProduct/categoryDelete', request);
	}
	masterProductCategoryList() {
		return this.doGet<MasterProductCategory[]>('MasterProduct/categoryList', {});
	}
	masterProductCategoryListUrl() {
		return this.getUrl('MasterProduct/categoryList', {});
	}
	masterProductCategoryUpdate(request: MasterProductCategory) {
		return this.doPut<void>('MasterProduct/categoryUpdate', request);
	}
	masterProductList() {
		return this.doGet<MasterProduct[]>('MasterProduct/list', {});
	}
	masterProductListUrl() {
		return this.getUrl('MasterProduct/list', {});
	}
	masterProductStorageUnitById(request: MasterProductStorageUnitByIdInternalRequest) {
		return this.doGet<MasterProductMatch>('MasterProduct/storageUnitById', request);
	}
	masterProductStorageUnitByIdUrl(request: MasterProductStorageUnitByIdInternalRequest) {
		return this.getUrl('MasterProduct/storageUnitById', request);
	}
	masterProductStorageUnitEanById(request: MasterProductStorageUnitEanByIdInternalRequest) {
		return this.doGet<MasterProductMatch>('MasterProduct/storageUnitEanById', request);
	}
	masterProductStorageUnitEanByIdUrl(request: MasterProductStorageUnitEanByIdInternalRequest) {
		return this.getUrl('MasterProduct/storageUnitEanById', request);
	}
	masterProductUpdate(request: MasterProductByIdResult) {
		return this.doPut<void>('MasterProduct/update', request);
	}

	// Methods from fi.bel.rj.api.integration.bookkeeping.NetvisorAPI
	netvisorGetProducts(request: NetvisorGetProductsInternalRequest) {
		return this.doGet<NetvisorProductItem[]>('Netvisor/getProducts', request);
	}
	netvisorGetProductsUrl(request: NetvisorGetProductsInternalRequest) {
		return this.getUrl('Netvisor/getProducts', request);
	}

	// Methods from fi.bel.rj.api.NewsAPI
	newsAdminList(request: NewsAdminListInternalRequest) {
		return this.doGet<NewsDTO[]>('News/adminList', request);
	}
	newsAdminListUrl(request: NewsAdminListInternalRequest) {
		return this.getUrl('News/adminList', request);
	}
	newsById(request: NewsByIdInternalRequest) {
		return this.doGet<NewsDTO>('News/byId', request);
	}
	newsByIdUrl(request: NewsByIdInternalRequest) {
		return this.getUrl('News/byId', request);
	}
	newsGetImg(request: NewsGetImgInternalRequest) {
		return this.doGet<unknown>('News/getImg', request);
	}
	newsGetImgUrl(request: NewsGetImgInternalRequest) {
		return this.getUrl('News/getImg', request);
	}
	newsLatestAlert() {
		return this.doGet<NewsDTO | undefined>('News/latestAlert', {});
	}
	newsLatestAlertUrl() {
		return this.getUrl('News/latestAlert', {});
	}
	newsList(request: NewsListInternalRequest) {
		return this.doGet<NewsDTO[]>('News/list', request);
	}
	newsListUrl(request: NewsListInternalRequest) {
		return this.getUrl('News/list', request);
	}
	newsUpdate(request: NewsUpdateRequest) {
		return this.doPut<void>('News/update', request);
	}

	// Methods from fi.bel.rj.api.OTPAPI
	oTPRemoveOTP() {
		return this.doGet<void>('OTP/removeOTP', {});
	}
	oTPRemoveOTPUrl() {
		return this.getUrl('OTP/removeOTP', {});
	}
	oTPStart(request: OTPStartInternalRequest) {
		return this.doGet<void>('OTP/start', request);
	}
	oTPStartUrl(request: OTPStartInternalRequest) {
		return this.getUrl('OTP/start', request);
	}
	oTPVerify(request: FinalizeOTPInitRequest) {
		return this.doPut<FinalizeOTPInitResponse>('OTP/verify', request);
	}

	// Methods from fi.bel.rj.api.clientsale.PrepaymentAPI
	prepaymentManualSend(request: PrepaymentManualSendRequest) {
		return this.doPut<PrepaymentManualSendResponse>('Prepayment/manualSend', request);
	}
	prepaymentPrepaymentList(request: PrepaymentListRequest) {
		return this.doPut<PrepaymentResponse[]>('Prepayment/prepaymentList', request);
	}

	// Methods from fi.bel.rj.api.PrivilegeAPI
	privilegeCanEdit(request: PrivilegeCanEditInternalRequest) {
		return this.doGet<boolean>('Privilege/canEdit', request);
	}
	privilegeCanEditUrl(request: PrivilegeCanEditInternalRequest) {
		return this.getUrl('Privilege/canEdit', request);
	}
	privilegeListAll() {
		return this.doGet<PrivilegeItem[]>('Privilege/listAll', {});
	}
	privilegeListAllUrl() {
		return this.getUrl('Privilege/listAll', {});
	}
	privilegeListBusinessGroupViaClients() {
		return this.doGet<BusinessGroup[]>('Privilege/listBusinessGroupViaClients', {});
	}
	privilegeListBusinessGroupViaClientsUrl() {
		return this.getUrl('Privilege/listBusinessGroupViaClients', {});
	}
	privilegeListClient() {
		return this.doGet<PrivilegeItem[]>('Privilege/listClient', {});
	}
	privilegeListClientUrl() {
		return this.getUrl('Privilege/listClient', {});
	}
	privilegeListClientsByBusinessGroupId(request: PrivilegeListClientsByBusinessGroupIdInternalRequest) {
		return this.doGet<IdName[]>('Privilege/listClientsByBusinessGroupId', request);
	}
	privilegeListClientsByBusinessGroupIdUrl(request: PrivilegeListClientsByBusinessGroupIdInternalRequest) {
		return this.getUrl('Privilege/listClientsByBusinessGroupId', request);
	}
	privilegeListCorporationViaClients() {
		return this.doGet<Corporation[]>('Privilege/listCorporationViaClients', {});
	}
	privilegeListCorporationViaClientsUrl() {
		return this.getUrl('Privilege/listCorporationViaClients', {});
	}

	// Methods from fi.bel.rj.api.integration.bookkeeping.ProcountorAPI
	procountorGetDimensions(request: ProcountorGetDimensionsInternalRequest) {
		return this.doGet<Dimension[]>('Procountor/getDimensions', request);
	}
	procountorGetDimensionsUrl(request: ProcountorGetDimensionsInternalRequest) {
		return this.getUrl('Procountor/getDimensions', request);
	}
	procountorGetProducts(request: ProcountorGetProductsInternalRequest) {
		return this.doGet<ProcountorProduct[]>('Procountor/getProducts', request);
	}
	procountorGetProductsUrl(request: ProcountorGetProductsInternalRequest) {
		return this.getUrl('Procountor/getProducts', request);
	}
	procountorLogin(request: ProcountorLoginInternalRequest) {
		return this.doGet<unknown>('Procountor/login', request);
	}
	procountorLoginUrl(request: ProcountorLoginInternalRequest) {
		return this.getUrl('Procountor/login', request);
	}
	procountorLogin2(request: ProcountorLogin2InternalRequest) {
		return this.doGet<unknown>('Procountor/login2', request);
	}
	procountorLogin2Url(request: ProcountorLogin2InternalRequest) {
		return this.getUrl('Procountor/login2', request);
	}

	// Methods from fi.bel.rj.api.PublicTranslateAPI
	publicTranslateByKey(request: PublicTranslateByKeyInternalRequest) {
		return this.doGet<Translate>('PublicTranslate/byKey', request);
	}
	publicTranslateByKeyUrl(request: PublicTranslateByKeyInternalRequest) {
		return this.getUrl('PublicTranslate/byKey', request);
	}
	publicTranslateList(request: PublicTranslateListInternalRequest) {
		return this.doGet<Translate[]>('PublicTranslate/list', request);
	}
	publicTranslateListUrl(request: PublicTranslateListInternalRequest) {
		return this.getUrl('PublicTranslate/list', request);
	}

	// Methods from fi.bel.rj.api.ReleaseLogAPI
	releaseLogById(request: ReleaseLogByIdInternalRequest) {
		return this.doGet<ReleaseLog>('ReleaseLog/byId', request);
	}
	releaseLogByIdUrl(request: ReleaseLogByIdInternalRequest) {
		return this.getUrl('ReleaseLog/byId', request);
	}
	releaseLogGetImg(request: ReleaseLogGetImgInternalRequest) {
		return this.doGet<unknown>('ReleaseLog/getImg', request);
	}
	releaseLogGetImgUrl(request: ReleaseLogGetImgInternalRequest) {
		return this.getUrl('ReleaseLog/getImg', request);
	}
	releaseLogList(request: ReleaseLogListInternalRequest) {
		return this.doGet<ReleaseLog[]>('ReleaseLog/list', request);
	}
	releaseLogListUrl(request: ReleaseLogListInternalRequest) {
		return this.getUrl('ReleaseLog/list', request);
	}
	releaseLogListForCustomer(request: ReleaseLogListForCustomerInternalRequest) {
		return this.doGet<ReleaseLogPublicResponse[]>('ReleaseLog/listForCustomer', request);
	}
	releaseLogListForCustomerUrl(request: ReleaseLogListForCustomerInternalRequest) {
		return this.getUrl('ReleaseLog/listForCustomer', request);
	}
	releaseLogUpdate(request: ReleaseLogUpdateRequest) {
		return this.doPut<void>('ReleaseLog/update', request);
	}

	// Methods from fi.bel.rj.api.ReportAPI
	reportAuditMemo(request: ReportAuditMemoInternalRequest) {
		return this.doGet<BookkeepingAuditMemoResponse>('Report/auditMemo', request);
	}
	reportAuditMemoUrl(request: ReportAuditMemoInternalRequest) {
		return this.getUrl('Report/auditMemo', request);
	}
	reportAuditMemoExcel(request: ReportAuditMemoExcelInternalRequest) {
		return this.doGet<unknown>('Report/auditMemoExcel', request);
	}
	reportAuditMemoExcelUrl(request: ReportAuditMemoExcelInternalRequest) {
		return this.getUrl('Report/auditMemoExcel', request);
	}
	reportGiftCardDebt(request: ReportGiftCardDebtInternalRequest) {
		return this.doGet<GiftCardListResponse[]>('Report/giftCardDebt', request);
	}
	reportGiftCardDebtUrl(request: ReportGiftCardDebtInternalRequest) {
		return this.getUrl('Report/giftCardDebt', request);
	}
	reportGiftCardUsage(request: ReportGiftCardUsageInternalRequest) {
		return this.doGet<GiftCardUsageResponse[]>('Report/giftCardUsage', request);
	}
	reportGiftCardUsageUrl(request: ReportGiftCardUsageInternalRequest) {
		return this.getUrl('Report/giftCardUsage', request);
	}
	reportListUnit() {
		return this.doGet<InvoicingSnapshot[]>('Report/listUnit', {});
	}
	reportListUnitUrl() {
		return this.getUrl('Report/listUnit', {});
	}
	reportUnitDataById(request: ReportUnitDataByIdInternalRequest) {
		return this.doGet<InvoicingData>('Report/unitDataById', request);
	}
	reportUnitDataByIdUrl(request: ReportUnitDataByIdInternalRequest) {
		return this.getUrl('Report/unitDataById', request);
	}
	reportUnitDataByIdExcel(request: ReportUnitDataByIdExcelInternalRequest) {
		return this.doGet<unknown>('Report/unitDataByIdExcel', request);
	}
	reportUnitDataByIdExcelUrl(request: ReportUnitDataByIdExcelInternalRequest) {
		return this.getUrl('Report/unitDataByIdExcel', request);
	}
	reportUnitSummaryExcel(request: ReportUnitSummaryExcelInternalRequest) {
		return this.doGet<unknown>('Report/unitSummaryExcel', request);
	}
	reportUnitSummaryExcelUrl(request: ReportUnitSummaryExcelInternalRequest) {
		return this.getUrl('Report/unitSummaryExcel', request);
	}

	// Methods from fi.bel.rj.api.RepositoryAPI
	repositoryArchive(request: ArchiveRequest) {
		return this.doPut<void>('Repository/archive', request);
	}
	repositoryById(request: RepositoryByIdInternalRequest) {
		return this.doGet<RepositoryWithDataPresent>('Repository/byId', request);
	}
	repositoryByIdUrl(request: RepositoryByIdInternalRequest) {
		return this.getUrl('Repository/byId', request);
	}
	repositoryGetImg(request: RepositoryGetImgInternalRequest) {
		return this.doGet<unknown>('Repository/getImg', request);
	}
	repositoryGetImgUrl(request: RepositoryGetImgInternalRequest) {
		return this.getUrl('Repository/getImg', request);
	}
	repositoryList(request: RepositoryListInternalRequest) {
		return this.doGet<Repository[]>('Repository/list', request);
	}
	repositoryListUrl(request: RepositoryListInternalRequest) {
		return this.getUrl('Repository/list', request);
	}
	repositoryListBranches() {
		return this.doGet<string[]>('Repository/listBranches', {});
	}
	repositoryListBranchesUrl() {
		return this.getUrl('Repository/listBranches', {});
	}
	repositoryListForCustomer(request: RepositoryListForCustomerInternalRequest) {
		return this.doGet<RepositoryPublicResponse[]>('Repository/listForCustomer', request);
	}
	repositoryListForCustomerUrl(request: RepositoryListForCustomerInternalRequest) {
		return this.getUrl('Repository/listForCustomer', request);
	}
	repositoryUpdate(request: RepositoryUpdateRequest) {
		return this.doPut<void>('Repository/update', request);
	}

	// Methods from fi.bel.rj.api.clientsale.SalesInvoiceAPI
	salesInvoiceComplete(request: BillingUpdateRequest) {
		return this.doPut<void>('SalesInvoice/complete', request);
	}
	salesInvoiceList(request: SalesInvoiceListRequest) {
		return this.doPut<SalesInvoiceResponse[]>('SalesInvoice/list', request);
	}
	salesInvoiceListNotComplete(request: SalesInvoiceListNotCompleteRequest) {
		return this.doPut<SalesInvoiceListNotCompleteResponse[]>('SalesInvoice/listNotComplete', request);
	}
	salesInvoiceManualSend(request: SalesInvoiceManualSendRequest) {
		return this.doPut<SalesInvoicetManualSendResponse>('SalesInvoice/manualSend', request);
	}
	salesInvoiceTransferToCombinedBilling(request: BillingUpdateRequest) {
		return this.doPut<void>('SalesInvoice/transferToCombinedBilling', request);
	}
	salesInvoiceUpdateBillInfo(request: BillingUpdateRequest) {
		return this.doPut<void>('SalesInvoice/updateBillInfo', request);
	}

	// Methods from fi.bel.rj.api.SchedulerAPI
	schedulerStatus() {
		return this.doGet<DashboardTaskStatusResponse[]>('Scheduler/status', {});
	}
	schedulerStatusUrl() {
		return this.getUrl('Scheduler/status', {});
	}
	schedulerToggleTask(request: SchedulerToggleTaskRequest) {
		return this.doPut<void>('Scheduler/toggleTask', request);
	}

	// Methods from fi.bel.rj.api.ServerAPI
	serverById(request: ServerByIdInternalRequest) {
		return this.doGet<Server>('Server/byId', request);
	}
	serverByIdUrl(request: ServerByIdInternalRequest) {
		return this.getUrl('Server/byId', request);
	}
	serverList(request: ServerListInternalRequest) {
		return this.doGet<ServerListResponse[]>('Server/list', request);
	}
	serverListUrl(request: ServerListInternalRequest) {
		return this.getUrl('Server/list', request);
	}
	serverListExcel(request: ServerListExcelInternalRequest) {
		return this.doGet<unknown>('Server/listExcel', request);
	}
	serverListExcelUrl(request: ServerListExcelInternalRequest) {
		return this.getUrl('Server/listExcel', request);
	}
	serverLogById(request: ServerLogByIdInternalRequest) {
		return this.doGet<ServerLog[]>('Server/logById', request);
	}
	serverLogByIdUrl(request: ServerLogByIdInternalRequest) {
		return this.getUrl('Server/logById', request);
	}
	serverUpdate(request: ServerUpdateRequest) {
		return this.doPut<Server>('Server/update', request);
	}

	// Methods from fi.bel.rj.api.SessionAPI
	sessionLoginByUsername(request: LoginByUsernameRequest) {
		return this.doPut<Session>('Session/loginByUsername', request);
	}
	sessionLogout() {
		return this.doPut<Session>('Session/logout', {});
	}
	sessionStatus() {
		return this.doGet<SessionStatusResponse>('Session/status', {});
	}
	sessionStatusUrl() {
		return this.getUrl('Session/status', {});
	}

	// Methods from fi.bel.rj.api.integration.bookkeeping.TalenomAPI
	talenomGetAccounts(request: TalenomGetAccountsInternalRequest) {
		return this.doGet<TalenomAccount[]>('Talenom/getAccounts', request);
	}
	talenomGetAccountsUrl(request: TalenomGetAccountsInternalRequest) {
		return this.getUrl('Talenom/getAccounts', request);
	}
	talenomGetDimensions(request: TalenomGetDimensionsInternalRequest) {
		return this.doGet<TalenomDimension[]>('Talenom/getDimensions', request);
	}
	talenomGetDimensionsUrl(request: TalenomGetDimensionsInternalRequest) {
		return this.getUrl('Talenom/getDimensions', request);
	}

	// Methods from fi.bel.rj.api.TranslateAPI
	translateConfigMismatch() {
		return this.doGet<TranslateConfigMismatchResponse>('Translate/configMismatch', {});
	}
	translateConfigMismatchUrl() {
		return this.getUrl('Translate/configMismatch', {});
	}
	translateDeleteList(request: TranslateDeleteRequest) {
		return this.doPut<void>('Translate/deleteList', request);
	}
	translateExcelDownload() {
		return this.doGet<unknown>('Translate/excelDownload', {});
	}
	translateExcelDownloadUrl() {
		return this.getUrl('Translate/excelDownload', {});
	}
	translateExcelUpdate(request: ExcelUpdateRequest) {
		return this.doPut<Translate[]>('Translate/excelUpdate', request);
	}
	translateJsonDownload(request: TranslateJsonDownloadInternalRequest) {
		return this.doGet<unknown>('Translate/jsonDownload', request);
	}
	translateJsonDownloadUrl(request: TranslateJsonDownloadInternalRequest) {
		return this.getUrl('Translate/jsonDownload', request);
	}
	translateJsonUpdate(request: JsonUpdateRequest) {
		return this.doPut<Translate[]>('Translate/jsonUpdate', request);
	}
	translateListLogByKey(request: ListLogByKeyRequest) {
		return this.doPut<TranslateLogWithModifier[]>('Translate/listLogByKey', request);
	}
	translateUpdate(request: TranslateUpdateRequest) {
		return this.doPut<Translate>('Translate/update', request);
	}

	// Methods from fi.bel.rj.api.UnitAPI
	unitById(request: UnitByIdInternalRequest) {
		return this.doGet<ClientUnit>('Unit/byId', request);
	}
	unitByIdUrl(request: UnitByIdInternalRequest) {
		return this.getUrl('Unit/byId', request);
	}
	unitList() {
		return this.doGet<ClientUnitListResponse[]>('Unit/list', {});
	}
	unitListUrl() {
		return this.getUrl('Unit/list', {});
	}

	// Methods from fi.bel.rj.api.VivaWalletIsvCredentialsAPI
	vivaWalletIsvCredentialsGet() {
		return this.doGet<VivaWalletIsvCredentials | undefined>('VivaWalletIsvCredentials/get', {});
	}
	vivaWalletIsvCredentialsGetUrl() {
		return this.getUrl('VivaWalletIsvCredentials/get', {});
	}
	vivaWalletIsvCredentialsUpdate(request: VivaWalletIsvCredentialsUpdateRequest) {
		return this.doPut<VivaWalletIsvCredentials>('VivaWalletIsvCredentials/update', request);
	}

	// Methods from fi.bel.rj.api.integration.pos.PosVivaWalletAPI
	posVivaWalletWebhookResponseByMerchantTrns(request: PosVivaWalletWebhookResponseByMerchantTrnsInternalRequest) {
		return this.doGet<VivaWalletWebhookResponse | undefined>('PosVivaWallet/webhookResponseByMerchantTrns', request);
	}
	posVivaWalletWebhookResponseByMerchantTrnsUrl(request: PosVivaWalletWebhookResponseByMerchantTrnsInternalRequest) {
		return this.getUrl('PosVivaWallet/webhookResponseByMerchantTrns', request);
	}
	posVivaWalletWebhookResponseByMerchantTrnsAndParentTransactionId(request: PosVivaWalletWebhookResponseByMerchantTrnsAndParentTransactionIdInternalRequest) {
		return this.doGet<VivaWalletWebhookResponse | undefined>('PosVivaWallet/webhookResponseByMerchantTrnsAndParentTransactionId', request);
	}
	posVivaWalletWebhookResponseByMerchantTrnsAndParentTransactionIdUrl(request: PosVivaWalletWebhookResponseByMerchantTrnsAndParentTransactionIdInternalRequest) {
		return this.getUrl('PosVivaWallet/webhookResponseByMerchantTrnsAndParentTransactionId', request);
	}

	// Methods from fi.bel.rj.api.positem.PortionSizeAPI
	portionSizeById(request: PortionSizeByIdInternalRequest) {
		return this.doGet<PortionSize>('PortionSize/byId', request);
	}
	portionSizeByIdUrl(request: PortionSizeByIdInternalRequest) {
		return this.getUrl('PortionSize/byId', request);
	}
	portionSizeCopyMasterItems(request: PosItemCopyMasterRequestBase<PortionSize>) {
		return this.doPut<void>('PortionSize/copyMasterItems', request);
	}
	portionSizeList(request: PortionSizeListInternalRequest) {
		return this.doGet<PortionSize[]>('PortionSize/list', request);
	}
	portionSizeListUrl(request: PortionSizeListInternalRequest) {
		return this.getUrl('PortionSize/list', request);
	}
	portionSizeListForBusinessGroup(request: PortionSizeListForBusinessGroupInternalRequest) {
		return this.doGet<{ [key: number]: PortionSize[] }>('PortionSize/listForBusinessGroup', request);
	}
	portionSizeListForBusinessGroupUrl(request: PortionSizeListForBusinessGroupInternalRequest) {
		return this.getUrl('PortionSize/listForBusinessGroup', request);
	}
	portionSizeListMaster() {
		return this.doGet<PortionSize[]>('PortionSize/listMaster', {});
	}
	portionSizeListMasterUrl() {
		return this.getUrl('PortionSize/listMaster', {});
	}
	portionSizeListOnSpecificLevel(request: PortionSizeListOnSpecificLevelInternalRequest) {
		return this.doGet<PortionSize[]>('PortionSize/listOnSpecificLevel', request);
	}
	portionSizeListOnSpecificLevelUrl(request: PortionSizeListOnSpecificLevelInternalRequest) {
		return this.getUrl('PortionSize/listOnSpecificLevel', request);
	}
	portionSizeUpdate(request: PortionSizeUpdateRequest) {
		return this.doPut<PortionSize>('PortionSize/update', request);
	}

	// Methods from fi.bel.rj.api.positem.SalesChannelAPI
	salesChannelById(request: SalesChannelByIdInternalRequest) {
		return this.doGet<SalesChannel>('SalesChannel/byId', request);
	}
	salesChannelByIdUrl(request: SalesChannelByIdInternalRequest) {
		return this.getUrl('SalesChannel/byId', request);
	}
	salesChannelCopyMasterItems(request: PosItemCopyMasterRequestBase<SalesChannel>) {
		return this.doPut<void>('SalesChannel/copyMasterItems', request);
	}
	salesChannelList(request: SalesChannelListInternalRequest) {
		return this.doGet<SalesChannel[]>('SalesChannel/list', request);
	}
	salesChannelListUrl(request: SalesChannelListInternalRequest) {
		return this.getUrl('SalesChannel/list', request);
	}
	salesChannelListForBusinessGroup(request: SalesChannelListForBusinessGroupInternalRequest) {
		return this.doGet<{ [key: number]: SalesChannel[] }>('SalesChannel/listForBusinessGroup', request);
	}
	salesChannelListForBusinessGroupUrl(request: SalesChannelListForBusinessGroupInternalRequest) {
		return this.getUrl('SalesChannel/listForBusinessGroup', request);
	}
	salesChannelListMaster() {
		return this.doGet<SalesChannel[]>('SalesChannel/listMaster', {});
	}
	salesChannelListMasterUrl() {
		return this.getUrl('SalesChannel/listMaster', {});
	}
	salesChannelListOnSpecificLevel(request: SalesChannelListOnSpecificLevelInternalRequest) {
		return this.doGet<SalesChannel[]>('SalesChannel/listOnSpecificLevel', request);
	}
	salesChannelListOnSpecificLevelUrl(request: SalesChannelListOnSpecificLevelInternalRequest) {
		return this.getUrl('SalesChannel/listOnSpecificLevel', request);
	}
	salesChannelUpdate(request: SalesChannelUpdateRequest) {
		return this.doPut<SalesChannel>('SalesChannel/update', request);
	}

	// Methods from fi.bel.rj.api.positem.SalesRestrictionAPI
	salesRestrictionById(request: SalesRestrictionByIdInternalRequest) {
		return this.doGet<SalesRestriction>('SalesRestriction/byId', request);
	}
	salesRestrictionByIdUrl(request: SalesRestrictionByIdInternalRequest) {
		return this.getUrl('SalesRestriction/byId', request);
	}
	salesRestrictionCopyMasterItems(request: PosItemCopyMasterRequestBase<SalesRestriction>) {
		return this.doPut<void>('SalesRestriction/copyMasterItems', request);
	}
	salesRestrictionList(request: SalesRestrictionListInternalRequest) {
		return this.doGet<SalesRestriction[]>('SalesRestriction/list', request);
	}
	salesRestrictionListUrl(request: SalesRestrictionListInternalRequest) {
		return this.getUrl('SalesRestriction/list', request);
	}
	salesRestrictionListForBusinessGroup(request: SalesRestrictionListForBusinessGroupInternalRequest) {
		return this.doGet<{ [key: number]: SalesRestriction[] }>('SalesRestriction/listForBusinessGroup', request);
	}
	salesRestrictionListForBusinessGroupUrl(request: SalesRestrictionListForBusinessGroupInternalRequest) {
		return this.getUrl('SalesRestriction/listForBusinessGroup', request);
	}
	salesRestrictionListMaster() {
		return this.doGet<SalesRestriction[]>('SalesRestriction/listMaster', {});
	}
	salesRestrictionListMasterUrl() {
		return this.getUrl('SalesRestriction/listMaster', {});
	}
	salesRestrictionListOnSpecificLevel(request: SalesRestrictionListOnSpecificLevelInternalRequest) {
		return this.doGet<SalesRestriction[]>('SalesRestriction/listOnSpecificLevel', request);
	}
	salesRestrictionListOnSpecificLevelUrl(request: SalesRestrictionListOnSpecificLevelInternalRequest) {
		return this.getUrl('SalesRestriction/listOnSpecificLevel', request);
	}
	salesRestrictionListTimes(request: SalesRestrictionListTimesInternalRequest) {
		return this.doGet<SalesRestrictionTimeResponse>('SalesRestriction/listTimes', request);
	}
	salesRestrictionListTimesUrl(request: SalesRestrictionListTimesInternalRequest) {
		return this.getUrl('SalesRestriction/listTimes', request);
	}
	salesRestrictionSpecify(request: SalesRestrictionSpecifyRequest) {
		return this.doPut<void>('SalesRestriction/specify', request);
	}
	salesRestrictionUpdate(request: SalesRestrictionUpdateRequest) {
		return this.doPut<SalesRestriction>('SalesRestriction/update', request);
	}

	// Methods from fi.bel.rj.api.positem.StorageUnitAPI
	storageUnitById(request: StorageUnitByIdInternalRequest) {
		return this.doGet<StorageUnit>('StorageUnit/byId', request);
	}
	storageUnitByIdUrl(request: StorageUnitByIdInternalRequest) {
		return this.getUrl('StorageUnit/byId', request);
	}
	storageUnitCopyMasterItems(request: PosItemCopyMasterRequestBase<StorageUnit>) {
		return this.doPut<void>('StorageUnit/copyMasterItems', request);
	}
	storageUnitList(request: StorageUnitListInternalRequest) {
		return this.doGet<StorageUnit[]>('StorageUnit/list', request);
	}
	storageUnitListUrl(request: StorageUnitListInternalRequest) {
		return this.getUrl('StorageUnit/list', request);
	}
	storageUnitListAll() {
		return this.doGet<StorageUnit[]>('StorageUnit/listAll', {});
	}
	storageUnitListAllUrl() {
		return this.getUrl('StorageUnit/listAll', {});
	}
	storageUnitListForBusinessGroup(request: StorageUnitListForBusinessGroupInternalRequest) {
		return this.doGet<{ [key: number]: StorageUnit[] }>('StorageUnit/listForBusinessGroup', request);
	}
	storageUnitListForBusinessGroupUrl(request: StorageUnitListForBusinessGroupInternalRequest) {
		return this.getUrl('StorageUnit/listForBusinessGroup', request);
	}
	storageUnitListMaster() {
		return this.doGet<StorageUnit[]>('StorageUnit/listMaster', {});
	}
	storageUnitListMasterUrl() {
		return this.getUrl('StorageUnit/listMaster', {});
	}
	storageUnitListOnSpecificLevel(request: StorageUnitListOnSpecificLevelInternalRequest) {
		return this.doGet<StorageUnit[]>('StorageUnit/listOnSpecificLevel', request);
	}
	storageUnitListOnSpecificLevelUrl(request: StorageUnitListOnSpecificLevelInternalRequest) {
		return this.getUrl('StorageUnit/listOnSpecificLevel', request);
	}
	storageUnitUpdate(request: StorageUnitUpdateRequest) {
		return this.doPut<StorageUnit>('StorageUnit/update', request);
	}

	// Methods from fi.bel.rj.api.positem.PricingGroupAPI
	pricingGroupById(request: PricingGroupByIdInternalRequest) {
		return this.doGet<PricingGroup>('PricingGroup/byId', request);
	}
	pricingGroupByIdUrl(request: PricingGroupByIdInternalRequest) {
		return this.getUrl('PricingGroup/byId', request);
	}
	pricingGroupCopyMasterItems(request: PosItemCopyMasterRequestBase<PricingGroup>) {
		return this.doPut<void>('PricingGroup/copyMasterItems', request);
	}
	pricingGroupList(request: PricingGroupListInternalRequest) {
		return this.doGet<PricingGroup[]>('PricingGroup/list', request);
	}
	pricingGroupListUrl(request: PricingGroupListInternalRequest) {
		return this.getUrl('PricingGroup/list', request);
	}
	pricingGroupListMaster() {
		return this.doGet<PricingGroup[]>('PricingGroup/listMaster', {});
	}
	pricingGroupListMasterUrl() {
		return this.getUrl('PricingGroup/listMaster', {});
	}
	pricingGroupListOnSpecificLevel(request: PricingGroupListOnSpecificLevelInternalRequest) {
		return this.doGet<PricingGroup[]>('PricingGroup/listOnSpecificLevel', request);
	}
	pricingGroupListOnSpecificLevelUrl(request: PricingGroupListOnSpecificLevelInternalRequest) {
		return this.getUrl('PricingGroup/listOnSpecificLevel', request);
	}
	pricingGroupListRows(request: PricingGroupListRowsInternalRequest) {
		return this.doGet<PricingGroupRowsResponse>('PricingGroup/listRows', request);
	}
	pricingGroupListRowsUrl(request: PricingGroupListRowsInternalRequest) {
		return this.getUrl('PricingGroup/listRows', request);
	}
	pricingGroupListWithRows(request: PricingGroupListWithRowsInternalRequest) {
		return this.doGet<PricingGroupResponse[]>('PricingGroup/listWithRows', request);
	}
	pricingGroupListWithRowsUrl(request: PricingGroupListWithRowsInternalRequest) {
		return this.getUrl('PricingGroup/listWithRows', request);
	}
	pricingGroupSpecify(request: PricingGroupSpecifyRequest) {
		return this.doPut<void>('PricingGroup/specify', request);
	}
	pricingGroupUpdate(request: PricingGroupUpdateRequest) {
		return this.doPut<PricingGroup>('PricingGroup/update', request);
	}

	// Methods from fi.bel.rj.api.positem.ProductAPI
	productById(request: ProductByIdInternalRequest) {
		return this.doGet<Product>('Product/byId', request);
	}
	productByIdUrl(request: ProductByIdInternalRequest) {
		return this.getUrl('Product/byId', request);
	}
	productByIdExtended(request: ProductByIdExtendedInternalRequest) {
		return this.doGet<ProductByIdResponse>('Product/byIdExtended', request);
	}
	productByIdExtendedUrl(request: ProductByIdExtendedInternalRequest) {
		return this.getUrl('Product/byIdExtended', request);
	}
	productCopyMasterItems(request: PosItemCopyMasterRequestBase<Product>) {
		return this.doPut<void>('Product/copyMasterItems', request);
	}
	productImageById(request: ProductImageByIdInternalRequest) {
		return this.doGet<ProductImage | undefined>('Product/imageById', request);
	}
	productImageByIdUrl(request: ProductImageByIdInternalRequest) {
		return this.getUrl('Product/imageById', request);
	}
	productList(request: ProductListInternalRequest) {
		return this.doGet<Product[]>('Product/list', request);
	}
	productListUrl(request: ProductListInternalRequest) {
		return this.getUrl('Product/list', request);
	}
	productListMaster() {
		return this.doGet<Product[]>('Product/listMaster', {});
	}
	productListMasterUrl() {
		return this.getUrl('Product/listMaster', {});
	}
	productListOnSpecificLevel(request: ProductListOnSpecificLevelInternalRequest) {
		return this.doGet<Product[]>('Product/listOnSpecificLevel', request);
	}
	productListOnSpecificLevelUrl(request: ProductListOnSpecificLevelInternalRequest) {
		return this.getUrl('Product/listOnSpecificLevel', request);
	}
	productListPrices(request: ProductListPricesInternalRequest) {
		return this.doGet<ProductPricingGroupResponse>('Product/listPrices', request);
	}
	productListPricesUrl(request: ProductListPricesInternalRequest) {
		return this.getUrl('Product/listPrices', request);
	}
	productListWithPricingGroupRows(request: ProductListWithPricingGroupRowsInternalRequest) {
		return this.doGet<ProductResponseWithPricingGroupRows[]>('Product/listWithPricingGroupRows', request);
	}
	productListWithPricingGroupRowsUrl(request: ProductListWithPricingGroupRowsInternalRequest) {
		return this.getUrl('Product/listWithPricingGroupRows', request);
	}
	productSpecify(request: ProductPricingGroupSpecifyRequest) {
		return this.doPut<void>('Product/specify', request);
	}
	productUpdate(request: ProductUpdateRequest) {
		return this.doPut<Product>('Product/update', request);
	}

	// Methods from fi.bel.rj.api.positem.ProductCategoryAPI
	productCategoryById(request: ProductCategoryByIdInternalRequest) {
		return this.doGet<ProductCategory>('ProductCategory/byId', request);
	}
	productCategoryByIdUrl(request: ProductCategoryByIdInternalRequest) {
		return this.getUrl('ProductCategory/byId', request);
	}
	productCategoryCopyMasterItems(request: PosItemCopyMasterRequestBase<ProductCategory>) {
		return this.doPut<void>('ProductCategory/copyMasterItems', request);
	}
	productCategoryList(request: ProductCategoryListInternalRequest) {
		return this.doGet<ProductCategory[]>('ProductCategory/list', request);
	}
	productCategoryListUrl(request: ProductCategoryListInternalRequest) {
		return this.getUrl('ProductCategory/list', request);
	}
	productCategoryListAll() {
		return this.doGet<ProductCategory[]>('ProductCategory/listAll', {});
	}
	productCategoryListAllUrl() {
		return this.getUrl('ProductCategory/listAll', {});
	}
	productCategoryListMaster() {
		return this.doGet<ProductCategory[]>('ProductCategory/listMaster', {});
	}
	productCategoryListMasterUrl() {
		return this.getUrl('ProductCategory/listMaster', {});
	}
	productCategoryListOnSpecificLevel(request: ProductCategoryListOnSpecificLevelInternalRequest) {
		return this.doGet<ProductCategory[]>('ProductCategory/listOnSpecificLevel', request);
	}
	productCategoryListOnSpecificLevelUrl(request: ProductCategoryListOnSpecificLevelInternalRequest) {
		return this.getUrl('ProductCategory/listOnSpecificLevel', request);
	}
	productCategoryUpdate(request: ProductCategoryUpdateRequest) {
		return this.doPut<ProductCategory>('ProductCategory/update', request);
	}

	// Methods from fi.bel.rj.api.positem.ProductSubCategoryAPI
	productSubCategoryById(request: ProductSubCategoryByIdInternalRequest) {
		return this.doGet<ProductSubCategory>('ProductSubCategory/byId', request);
	}
	productSubCategoryByIdUrl(request: ProductSubCategoryByIdInternalRequest) {
		return this.getUrl('ProductSubCategory/byId', request);
	}
	productSubCategoryCopyMasterItems(request: PosItemCopyMasterRequestBase<ProductSubCategory>) {
		return this.doPut<void>('ProductSubCategory/copyMasterItems', request);
	}
	productSubCategoryList(request: ProductSubCategoryListInternalRequest) {
		return this.doGet<ProductSubCategory[]>('ProductSubCategory/list', request);
	}
	productSubCategoryListUrl(request: ProductSubCategoryListInternalRequest) {
		return this.getUrl('ProductSubCategory/list', request);
	}
	productSubCategoryListAll() {
		return this.doGet<ProductSubCategory[]>('ProductSubCategory/listAll', {});
	}
	productSubCategoryListAllUrl() {
		return this.getUrl('ProductSubCategory/listAll', {});
	}
	productSubCategoryListMaster() {
		return this.doGet<ProductSubCategory[]>('ProductSubCategory/listMaster', {});
	}
	productSubCategoryListMasterUrl() {
		return this.getUrl('ProductSubCategory/listMaster', {});
	}
	productSubCategoryListOnSpecificLevel(request: ProductSubCategoryListOnSpecificLevelInternalRequest) {
		return this.doGet<ProductSubCategory[]>('ProductSubCategory/listOnSpecificLevel', request);
	}
	productSubCategoryListOnSpecificLevelUrl(request: ProductSubCategoryListOnSpecificLevelInternalRequest) {
		return this.getUrl('ProductSubCategory/listOnSpecificLevel', request);
	}
	productSubCategoryUpdate(request: ProductSubCategoryUpdateRequest) {
		return this.doPut<ProductSubCategory>('ProductSubCategory/update', request);
	}

	// Methods from fi.bel.rj.api.positem.LocationAPI
	locationById(request: LocationByIdInternalRequest) {
		return this.doGet<Location>('Location/byId', request);
	}
	locationByIdUrl(request: LocationByIdInternalRequest) {
		return this.getUrl('Location/byId', request);
	}
	locationDeleteLogo(request: LocationLogoDeleteRequest) {
		return this.doPut<void>('Location/deleteLogo', request);
	}
	locationFullPaymentTypeList() {
		return this.doGet<LocalizedPaymentType[]>('Location/fullPaymentTypeList', {});
	}
	locationFullPaymentTypeListUrl() {
		return this.getUrl('Location/fullPaymentTypeList', {});
	}
	locationList(request: LocationListInternalRequest) {
		return this.doGet<Location[]>('Location/list', request);
	}
	locationListUrl(request: LocationListInternalRequest) {
		return this.getUrl('Location/list', request);
	}
	locationListAll() {
		return this.doGet<Location[]>('Location/listAll', {});
	}
	locationListAllUrl() {
		return this.getUrl('Location/listAll', {});
	}
	locationListForBusinessGroup(request: LocationListForBusinessGroupInternalRequest) {
		return this.doGet<{ [key: number]: Location[] }>('Location/listForBusinessGroup', request);
	}
	locationListForBusinessGroupUrl(request: LocationListForBusinessGroupInternalRequest) {
		return this.getUrl('Location/listForBusinessGroup', request);
	}
	locationLogo(request: LocationLogoInternalRequest) {
		return this.doGet<LocationLogo | undefined>('Location/logo', request);
	}
	locationLogoUrl(request: LocationLogoInternalRequest) {
		return this.getUrl('Location/logo', request);
	}
	locationUpdate(request: LocationUpdateRequest) {
		return this.doPut<Location>('Location/update', request);
	}

	// Methods from fi.bel.rj.api.positem.AreaAPI
	areaById(request: AreaByIdInternalRequest) {
		return this.doGet<Area>('Area/byId', request);
	}
	areaByIdUrl(request: AreaByIdInternalRequest) {
		return this.getUrl('Area/byId', request);
	}
	areaByIdWithPaymentTerminals(request: AreaByIdWithPaymentTerminalsInternalRequest) {
		return this.doGet<AreaWithPaymentTerminals>('Area/byIdWithPaymentTerminals', request);
	}
	areaByIdWithPaymentTerminalsUrl(request: AreaByIdWithPaymentTerminalsInternalRequest) {
		return this.getUrl('Area/byIdWithPaymentTerminals', request);
	}
	areaList(request: AreaListInternalRequest) {
		return this.doGet<Area[]>('Area/list', request);
	}
	areaListUrl(request: AreaListInternalRequest) {
		return this.getUrl('Area/list', request);
	}
	areaListAll() {
		return this.doGet<Area[]>('Area/listAll', {});
	}
	areaListAllUrl() {
		return this.getUrl('Area/listAll', {});
	}
	areaListForBusinessGroup(request: AreaListForBusinessGroupInternalRequest) {
		return this.doGet<{ [key: number]: Area[] }>('Area/listForBusinessGroup', request);
	}
	areaListForBusinessGroupUrl(request: AreaListForBusinessGroupInternalRequest) {
		return this.getUrl('Area/listForBusinessGroup', request);
	}
	areaListWithStationsByLocationId(request: AreaListWithStationsByLocationIdInternalRequest) {
		return this.doGet<AreaWithStations[]>('Area/listWithStationsByLocationId', request);
	}
	areaListWithStationsByLocationIdUrl(request: AreaListWithStationsByLocationIdInternalRequest) {
		return this.getUrl('Area/listWithStationsByLocationId', request);
	}
	areaUpdate(request: AreaUpdateRequest) {
		return this.doPut<Area>('Area/update', request);
	}

	// Methods from fi.bel.rj.api.positem.StationAPI
	stationById(request: StationByIdInternalRequest) {
		return this.doGet<Station>('Station/byId', request);
	}
	stationByIdUrl(request: StationByIdInternalRequest) {
		return this.getUrl('Station/byId', request);
	}
	stationByIdWithPaymentTerminals(request: StationByIdWithPaymentTerminalsInternalRequest) {
		return this.doGet<StationWithPaymentTerminals>('Station/byIdWithPaymentTerminals', request);
	}
	stationByIdWithPaymentTerminalsUrl(request: StationByIdWithPaymentTerminalsInternalRequest) {
		return this.getUrl('Station/byIdWithPaymentTerminals', request);
	}
	stationList(request: StationListInternalRequest) {
		return this.doGet<Station[]>('Station/list', request);
	}
	stationListUrl(request: StationListInternalRequest) {
		return this.getUrl('Station/list', request);
	}
	stationUpdate(request: StationUpdateRequest) {
		return this.doPut<Station>('Station/update', request);
	}

	// Methods from fi.bel.rj.api.PaymentTerminalAPI
	paymentTerminalById(request: PaymentTerminalByIdInternalRequest) {
		return this.doGet<PaymentTerminal>('PaymentTerminal/byId', request);
	}
	paymentTerminalByIdUrl(request: PaymentTerminalByIdInternalRequest) {
		return this.getUrl('PaymentTerminal/byId', request);
	}
	paymentTerminalList(request: PaymentTerminalListInternalRequest) {
		return this.doGet<PaymentTerminal[]>('PaymentTerminal/list', request);
	}
	paymentTerminalListUrl(request: PaymentTerminalListInternalRequest) {
		return this.getUrl('PaymentTerminal/list', request);
	}
	paymentTerminalListManufacturers(request: PaymentTerminalListManufacturersInternalRequest) {
		return this.doGet<PaymentTerminalManufacturer[]>('PaymentTerminal/listManufacturers', request);
	}
	paymentTerminalListManufacturersUrl(request: PaymentTerminalListManufacturersInternalRequest) {
		return this.getUrl('PaymentTerminal/listManufacturers', request);
	}
	paymentTerminalListModels(request: PaymentTerminalListModelsInternalRequest) {
		return this.doGet<PaymentTerminalModel[]>('PaymentTerminal/listModels', request);
	}
	paymentTerminalListModelsUrl(request: PaymentTerminalListModelsInternalRequest) {
		return this.getUrl('PaymentTerminal/listModels', request);
	}
	paymentTerminalListSites(request: PaymentTerminalListSitesInternalRequest) {
		return this.doGet<PaymentTerminalSite[]>('PaymentTerminal/listSites', request);
	}
	paymentTerminalListSitesUrl(request: PaymentTerminalListSitesInternalRequest) {
		return this.getUrl('PaymentTerminal/listSites', request);
	}
	paymentTerminalListStatuses(request: PaymentTerminalListStatusesInternalRequest) {
		return this.doGet<PaymentTerminalStatus[]>('PaymentTerminal/listStatuses', request);
	}
	paymentTerminalListStatusesUrl(request: PaymentTerminalListStatusesInternalRequest) {
		return this.getUrl('PaymentTerminal/listStatuses', request);
	}
	paymentTerminalListWithClientData(request: PaymentTerminalListWithClientDataInternalRequest) {
		return this.doGet<PaymentTerminalWithClientData[]>('PaymentTerminal/listWithClientData', request);
	}
	paymentTerminalListWithClientDataUrl(request: PaymentTerminalListWithClientDataInternalRequest) {
		return this.getUrl('PaymentTerminal/listWithClientData', request);
	}
	paymentTerminalManufacturerById(request: PaymentTerminalManufacturerByIdInternalRequest) {
		return this.doGet<PaymentTerminalManufacturer>('PaymentTerminal/manufacturerById', request);
	}
	paymentTerminalManufacturerByIdUrl(request: PaymentTerminalManufacturerByIdInternalRequest) {
		return this.getUrl('PaymentTerminal/manufacturerById', request);
	}
	paymentTerminalModelById(request: PaymentTerminalModelByIdInternalRequest) {
		return this.doGet<PaymentTerminalModel>('PaymentTerminal/modelById', request);
	}
	paymentTerminalModelByIdUrl(request: PaymentTerminalModelByIdInternalRequest) {
		return this.getUrl('PaymentTerminal/modelById', request);
	}
	paymentTerminalSiteById(request: PaymentTerminalSiteByIdInternalRequest) {
		return this.doGet<PaymentTerminalSite>('PaymentTerminal/siteById', request);
	}
	paymentTerminalSiteByIdUrl(request: PaymentTerminalSiteByIdInternalRequest) {
		return this.getUrl('PaymentTerminal/siteById', request);
	}
	paymentTerminalStatusById(request: PaymentTerminalStatusByIdInternalRequest) {
		return this.doGet<PaymentTerminalStatus>('PaymentTerminal/statusById', request);
	}
	paymentTerminalStatusByIdUrl(request: PaymentTerminalStatusByIdInternalRequest) {
		return this.getUrl('PaymentTerminal/statusById', request);
	}
	paymentTerminalUpdate(request: PaymentTerminalUpdateRequest) {
		return this.doPut<PaymentTerminal>('PaymentTerminal/update', request);
	}
	paymentTerminalUpdateManufacturer(request: PaymentTerminalUpdateManufacturerRequest) {
		return this.doPut<PaymentTerminalManufacturer>('PaymentTerminal/updateManufacturer', request);
	}
	paymentTerminalUpdateModel(request: PaymentTerminalUpdateModelRequest) {
		return this.doPut<PaymentTerminalModel>('PaymentTerminal/updateModel', request);
	}
	paymentTerminalUpdateSite(request: PaymentTerminalUpdateSiteOrStatusRequest) {
		return this.doPut<PaymentTerminalSite>('PaymentTerminal/updateSite', request);
	}
	paymentTerminalUpdateStatus(request: PaymentTerminalUpdateSiteOrStatusRequest) {
		return this.doPut<PaymentTerminalStatus>('PaymentTerminal/updateStatus', request);
	}

	// Methods from fi.bel.rj.api.positem.PaymentTerminalClientAPI
	paymentTerminalClientById(request: PaymentTerminalClientByIdInternalRequest) {
		return this.doGet<PaymentTerminalClient>('PaymentTerminalClient/byId', request);
	}
	paymentTerminalClientByIdUrl(request: PaymentTerminalClientByIdInternalRequest) {
		return this.getUrl('PaymentTerminalClient/byId', request);
	}
	paymentTerminalClientList(request: PaymentTerminalClientListInternalRequest) {
		return this.doGet<PaymentTerminalClient[]>('PaymentTerminalClient/list', request);
	}
	paymentTerminalClientListUrl(request: PaymentTerminalClientListInternalRequest) {
		return this.getUrl('PaymentTerminalClient/list', request);
	}
	paymentTerminalClientListByAreaId(request: PaymentTerminalClientListByAreaIdInternalRequest) {
		return this.doGet<UuidName[]>('PaymentTerminalClient/listByAreaId', request);
	}
	paymentTerminalClientListByAreaIdUrl(request: PaymentTerminalClientListByAreaIdInternalRequest) {
		return this.getUrl('PaymentTerminalClient/listByAreaId', request);
	}
	paymentTerminalClientListByLocationId(request: PaymentTerminalClientListByLocationIdInternalRequest) {
		return this.doGet<UuidName[]>('PaymentTerminalClient/listByLocationId', request);
	}
	paymentTerminalClientListByLocationIdUrl(request: PaymentTerminalClientListByLocationIdInternalRequest) {
		return this.getUrl('PaymentTerminalClient/listByLocationId', request);
	}
	paymentTerminalClientUpdate(request: PaymentTerminalClientUpdateRequest) {
		return this.doPut<PaymentTerminalClient>('PaymentTerminalClient/update', request);
	}
	paymentTerminalClientWithAreasById(request: PaymentTerminalClientWithAreasByIdInternalRequest) {
		return this.doGet<PaymentTerminalClientWithAreaIds>('PaymentTerminalClient/withAreasById', request);
	}
	paymentTerminalClientWithAreasByIdUrl(request: PaymentTerminalClientWithAreasByIdInternalRequest) {
		return this.getUrl('PaymentTerminalClient/withAreasById', request);
	}

	// Methods from fi.bel.rj.api.integration.pos.PosClientMigrationAPI
	posClientMigrationCanMigrate(request: PosClientMigrationCanMigrateInternalRequest) {
		return this.doGet<ClientMigrationCanMigrateResponse>('PosClientMigration/canMigrate', request);
	}
	posClientMigrationCanMigrateUrl(request: PosClientMigrationCanMigrateInternalRequest) {
		return this.getUrl('PosClientMigration/canMigrate', request);
	}
	posClientMigrationFinish() {
		return this.doPut<void>('PosClientMigration/finish', {});
	}
	posClientMigrationMarkFullyCompleted(request: ClientMigrationRequest) {
		return this.doPut<ClientMigrationCanMigrateResponse>('PosClientMigration/markFullyCompleted', request);
	}
	posClientMigrationStart(request: ClientMigrationRequest) {
		return this.doPut<ClientMigrationCanMigrateResponse>('PosClientMigration/start', request);
	}

	// Methods from fi.bel.rj.api.positem.PrinterAPI
	printerById(request: PrinterByIdInternalRequest) {
		return this.doGet<Printer>('Printer/byId', request);
	}
	printerByIdUrl(request: PrinterByIdInternalRequest) {
		return this.getUrl('Printer/byId', request);
	}
	printerList(request: PrinterListInternalRequest) {
		return this.doGet<Printer[]>('Printer/list', request);
	}
	printerListUrl(request: PrinterListInternalRequest) {
		return this.getUrl('Printer/list', request);
	}
	printerUpdate(request: PrinterUpdateRequest) {
		return this.doPut<Printer>('Printer/update', request);
	}

	// Methods from fi.bel.rj.api.positem.OrderListAPI
	orderListById(request: OrderListByIdInternalRequest) {
		return this.doGet<OrderList>('OrderList/byId', request);
	}
	orderListByIdUrl(request: OrderListByIdInternalRequest) {
		return this.getUrl('OrderList/byId', request);
	}
	orderListByIdWithDependencies(request: OrderListByIdWithDependenciesInternalRequest) {
		return this.doGet<OrderListByIdResponse>('OrderList/byIdWithDependencies', request);
	}
	orderListByIdWithDependenciesUrl(request: OrderListByIdWithDependenciesInternalRequest) {
		return this.getUrl('OrderList/byIdWithDependencies', request);
	}
	orderListCopyMasterItems(request: PosItemCopyMasterRequestBase<OrderList>) {
		return this.doPut<void>('OrderList/copyMasterItems', request);
	}
	orderListList(request: OrderListListInternalRequest) {
		return this.doGet<OrderList[]>('OrderList/list', request);
	}
	orderListListUrl(request: OrderListListInternalRequest) {
		return this.getUrl('OrderList/list', request);
	}
	orderListListMaster() {
		return this.doGet<OrderList[]>('OrderList/listMaster', {});
	}
	orderListListMasterUrl() {
		return this.getUrl('OrderList/listMaster', {});
	}
	orderListListOnSpecificLevel(request: OrderListListOnSpecificLevelInternalRequest) {
		return this.doGet<OrderList[]>('OrderList/listOnSpecificLevel', request);
	}
	orderListListOnSpecificLevelUrl(request: OrderListListOnSpecificLevelInternalRequest) {
		return this.getUrl('OrderList/listOnSpecificLevel', request);
	}
	orderListListWithDependencies(request: OrderListListWithDependenciesInternalRequest) {
		return this.doGet<OrderListWithDependencies[]>('OrderList/listWithDependencies', request);
	}
	orderListListWithDependenciesUrl(request: OrderListListWithDependenciesInternalRequest) {
		return this.getUrl('OrderList/listWithDependencies', request);
	}
	orderListUpdate(request: OrderListUpdateRequest) {
		return this.doPut<OrderList>('OrderList/update', request);
	}

	// Methods from fi.bel.rj.api.positem.OrderMessageAPI
	orderMessageAvailableInMessagesUpdate(request: OrderMessageAvailableInMessagesUpdate) {
		return this.doPut<void>('OrderMessage/availableInMessagesUpdate', request);
	}
	orderMessageById(request: OrderMessageByIdInternalRequest) {
		return this.doGet<OrderMessage>('OrderMessage/byId', request);
	}
	orderMessageByIdUrl(request: OrderMessageByIdInternalRequest) {
		return this.getUrl('OrderMessage/byId', request);
	}
	orderMessageCopyMasterItems(request: PosItemCopyMasterRequestBase<OrderMessage>) {
		return this.doPut<void>('OrderMessage/copyMasterItems', request);
	}
	orderMessageList(request: OrderMessageListInternalRequest) {
		return this.doGet<OrderMessage[]>('OrderMessage/list', request);
	}
	orderMessageListUrl(request: OrderMessageListInternalRequest) {
		return this.getUrl('OrderMessage/list', request);
	}
	orderMessageListMaster() {
		return this.doGet<OrderMessage[]>('OrderMessage/listMaster', {});
	}
	orderMessageListMasterUrl() {
		return this.getUrl('OrderMessage/listMaster', {});
	}
	orderMessageListOnSpecificLevel(request: OrderMessageListOnSpecificLevelInternalRequest) {
		return this.doGet<OrderMessage[]>('OrderMessage/listOnSpecificLevel', request);
	}
	orderMessageListOnSpecificLevelUrl(request: OrderMessageListOnSpecificLevelInternalRequest) {
		return this.getUrl('OrderMessage/listOnSpecificLevel', request);
	}
	orderMessageListWithPermissions(request: OrderMessageListWithPermissionsInternalRequest) {
		return this.doGet<OrderMessageWithPermission[]>('OrderMessage/listWithPermissions', request);
	}
	orderMessageListWithPermissionsUrl(request: OrderMessageListWithPermissionsInternalRequest) {
		return this.getUrl('OrderMessage/listWithPermissions', request);
	}
	orderMessageUpdate(request: OrderMessageUpdateRequest) {
		return this.doPut<OrderMessage>('OrderMessage/update', request);
	}

	// Methods from fi.bel.rj.api.positem.OrderMessageGroupAPI
	orderMessageGroupById(request: OrderMessageGroupByIdInternalRequest) {
		return this.doGet<OrderMessageGroup>('OrderMessageGroup/byId', request);
	}
	orderMessageGroupByIdUrl(request: OrderMessageGroupByIdInternalRequest) {
		return this.getUrl('OrderMessageGroup/byId', request);
	}
	orderMessageGroupCopyMasterItems(request: PosItemCopyMasterRequestBase<OrderMessageGroup>) {
		return this.doPut<void>('OrderMessageGroup/copyMasterItems', request);
	}
	orderMessageGroupList(request: OrderMessageGroupListInternalRequest) {
		return this.doGet<OrderMessageGroup[]>('OrderMessageGroup/list', request);
	}
	orderMessageGroupListUrl(request: OrderMessageGroupListInternalRequest) {
		return this.getUrl('OrderMessageGroup/list', request);
	}
	orderMessageGroupListMaster() {
		return this.doGet<OrderMessageGroup[]>('OrderMessageGroup/listMaster', {});
	}
	orderMessageGroupListMasterUrl() {
		return this.getUrl('OrderMessageGroup/listMaster', {});
	}
	orderMessageGroupListOnSpecificLevel(request: OrderMessageGroupListOnSpecificLevelInternalRequest) {
		return this.doGet<OrderMessageGroup[]>('OrderMessageGroup/listOnSpecificLevel', request);
	}
	orderMessageGroupListOnSpecificLevelUrl(request: OrderMessageGroupListOnSpecificLevelInternalRequest) {
		return this.getUrl('OrderMessageGroup/listOnSpecificLevel', request);
	}
	orderMessageGroupListWithPermission(request: OrderMessageGroupListWithPermissionInternalRequest) {
		return this.doGet<OrderMessageGroupWithPermission[]>('OrderMessageGroup/listWithPermission', request);
	}
	orderMessageGroupListWithPermissionUrl(request: OrderMessageGroupListWithPermissionInternalRequest) {
		return this.getUrl('OrderMessageGroup/listWithPermission', request);
	}
	orderMessageGroupUpdate(request: OrderMessageGroupUpdateRequest) {
		return this.doPut<OrderMessageGroup>('OrderMessageGroup/update', request);
	}

	// Methods from fi.bel.rj.api.positem.ProductOrderMessageGroupAPI
	productOrderMessageGroupById(request: ProductOrderMessageGroupByIdInternalRequest) {
		return this.doGet<ProductOrderMessageGroup>('ProductOrderMessageGroup/byId', request);
	}
	productOrderMessageGroupByIdUrl(request: ProductOrderMessageGroupByIdInternalRequest) {
		return this.getUrl('ProductOrderMessageGroup/byId', request);
	}
	productOrderMessageGroupCopyMasterItems(request: PosItemCopyMasterRequestBase<ProductOrderMessageGroup>) {
		return this.doPut<void>('ProductOrderMessageGroup/copyMasterItems', request);
	}
	productOrderMessageGroupList(request: ProductOrderMessageGroupListInternalRequest) {
		return this.doGet<ProductOrderMessageGroup[]>('ProductOrderMessageGroup/list', request);
	}
	productOrderMessageGroupListUrl(request: ProductOrderMessageGroupListInternalRequest) {
		return this.getUrl('ProductOrderMessageGroup/list', request);
	}
	productOrderMessageGroupListMaster() {
		return this.doGet<ProductOrderMessageGroup[]>('ProductOrderMessageGroup/listMaster', {});
	}
	productOrderMessageGroupListMasterUrl() {
		return this.getUrl('ProductOrderMessageGroup/listMaster', {});
	}
	productOrderMessageGroupListOnSpecificLevel(request: ProductOrderMessageGroupListOnSpecificLevelInternalRequest) {
		return this.doGet<ProductOrderMessageGroup[]>('ProductOrderMessageGroup/listOnSpecificLevel', request);
	}
	productOrderMessageGroupListOnSpecificLevelUrl(request: ProductOrderMessageGroupListOnSpecificLevelInternalRequest) {
		return this.getUrl('ProductOrderMessageGroup/listOnSpecificLevel', request);
	}
	productOrderMessageGroupListWithPermissions(request: ProductOrderMessageGroupListWithPermissionsInternalRequest) {
		return this.doGet<ProductOrderMessageGroupWithPermission[]>('ProductOrderMessageGroup/listWithPermissions', request);
	}
	productOrderMessageGroupListWithPermissionsUrl(request: ProductOrderMessageGroupListWithPermissionsInternalRequest) {
		return this.getUrl('ProductOrderMessageGroup/listWithPermissions', request);
	}
	productOrderMessageGroupUpdate(request: ProductOrderMessageGroupUpdateRequest) {
		return this.doPut<ProductOrderMessageGroup>('ProductOrderMessageGroup/update', request);
	}

	// Methods from fi.bel.rj.api.positem.VivaWalletPaymentInfoAPI
	vivaWalletPaymentInfoById(request: VivaWalletPaymentInfoByIdInternalRequest) {
		return this.doGet<VivaWalletPaymentInfo>('VivaWalletPaymentInfo/byId', request);
	}
	vivaWalletPaymentInfoByIdUrl(request: VivaWalletPaymentInfoByIdInternalRequest) {
		return this.getUrl('VivaWalletPaymentInfo/byId', request);
	}
	vivaWalletPaymentInfoList(request: VivaWalletPaymentInfoListInternalRequest) {
		return this.doGet<VivaWalletPaymentInfo[]>('VivaWalletPaymentInfo/list', request);
	}
	vivaWalletPaymentInfoListUrl(request: VivaWalletPaymentInfoListInternalRequest) {
		return this.getUrl('VivaWalletPaymentInfo/list', request);
	}
	vivaWalletPaymentInfoUpdate(request: VivaWalletPaymentInfoUpdateRequest) {
		return this.doPut<VivaWalletPaymentInfo>('VivaWalletPaymentInfo/update', request);
	}

	// Methods from fi.bel.rj.api.positem.VivaWalletFeeAPI
	vivaWalletFeeById(request: VivaWalletFeeByIdInternalRequest) {
		return this.doGet<VivaWalletFee>('VivaWalletFee/byId', request);
	}
	vivaWalletFeeByIdUrl(request: VivaWalletFeeByIdInternalRequest) {
		return this.getUrl('VivaWalletFee/byId', request);
	}
	vivaWalletFeeCopyMasterItems(request: PosItemCopyMasterRequestBase<VivaWalletFee>) {
		return this.doPut<void>('VivaWalletFee/copyMasterItems', request);
	}
	vivaWalletFeeList(request: VivaWalletFeeListInternalRequest) {
		return this.doGet<VivaWalletFee[]>('VivaWalletFee/list', request);
	}
	vivaWalletFeeListUrl(request: VivaWalletFeeListInternalRequest) {
		return this.getUrl('VivaWalletFee/list', request);
	}
	vivaWalletFeeListAll() {
		return this.doGet<VivaWalletFee[]>('VivaWalletFee/listAll', {});
	}
	vivaWalletFeeListAllUrl() {
		return this.getUrl('VivaWalletFee/listAll', {});
	}
	vivaWalletFeeListMaster() {
		return this.doGet<VivaWalletFee[]>('VivaWalletFee/listMaster', {});
	}
	vivaWalletFeeListMasterUrl() {
		return this.getUrl('VivaWalletFee/listMaster', {});
	}
	vivaWalletFeeListOnSpecificLevel(request: VivaWalletFeeListOnSpecificLevelInternalRequest) {
		return this.doGet<VivaWalletFee[]>('VivaWalletFee/listOnSpecificLevel', request);
	}
	vivaWalletFeeListOnSpecificLevelUrl(request: VivaWalletFeeListOnSpecificLevelInternalRequest) {
		return this.getUrl('VivaWalletFee/listOnSpecificLevel', request);
	}
	vivaWalletFeeMaster() {
		return this.doGet<VivaWalletFee>('VivaWalletFee/master', {});
	}
	vivaWalletFeeMasterUrl() {
		return this.getUrl('VivaWalletFee/master', {});
	}
	vivaWalletFeeUpdate(request: VivaWalletFeeUpdateRequest) {
		return this.doPut<VivaWalletFee>('VivaWalletFee/update', request);
	}

	// Methods from fi.bel.rj.api.positem.VatCategoryAPI
	vatCategoryById(request: VatCategoryByIdInternalRequest) {
		return this.doGet<VatCategoryByIdResponse>('VatCategory/byId', request);
	}
	vatCategoryByIdUrl(request: VatCategoryByIdInternalRequest) {
		return this.getUrl('VatCategory/byId', request);
	}
	vatCategoryList() {
		return this.doGet<VatCategory[]>('VatCategory/list', {});
	}
	vatCategoryListUrl() {
		return this.getUrl('VatCategory/list', {});
	}
	vatCategoryListWithRows() {
		return this.doGet<VatCategoryWithRows[]>('VatCategory/listWithRows', {});
	}
	vatCategoryListWithRowsUrl() {
		return this.getUrl('VatCategory/listWithRows', {});
	}
	vatCategoryUpdate(request: VatCategoryUpdateRequest) {
		return this.doPut<void>('VatCategory/update', request);
	}

	// Methods from fi.bel.rj.api.WaybillAPI
	waybillCanLogin(request: WaybillCanLoginInternalRequest) {
		return this.doGet<boolean>('Waybill/canLogin', request);
	}
	waybillCanLoginUrl(request: WaybillCanLoginInternalRequest) {
		return this.getUrl('Waybill/canLogin', request);
	}
	waybillLogin(request: WaybillLoginInternalRequest) {
		return this.doGet<WaybillLoginResponse>('Waybill/login', request);
	}
	waybillLoginUrl(request: WaybillLoginInternalRequest) {
		return this.getUrl('Waybill/login', request);
	}
/// GENERATED END ///

  public session?: Session;

  constructor(private readonly notify: Notify) {
  }

  /**
    * Convert a list of objects with numeric id to a hashmap by id.
    */
  public static toHash<T extends { id: number; }>(list: T[]) {
    let result: { [key: number]: T; } = {};
    for (let obj of list) {
      result[obj.id] = obj;
    }
    return result;
  }

  /**
  * Convert a list of objects with string id to a hashmap by id.
  */
  public static toHashStr<T extends { id: string; }>(list: T[]) {
    let result: { [key: string]: T; } = {};
    for (let obj of list) {
      result[obj.id] = obj;
    }
    return result;
  }

  /** HTTP request encoder */
  private encode(params?: { [key: string]: any; }): string {
    if (!params) {
      params = {};
    }
    let body: string[] = [];
    for (let key in params) {
      let value: any = params[key];
      if (value === null || value === undefined) {
        continue;
      }
      if (!(value instanceof Array)) {
        value = [value];
      }

      for (let val of value) {
        let v = val instanceof Date ? val.toJSON() : val;
        body.push(encodeURIComponent(key) + "=" + encodeURIComponent(v));
      }
    }
    return body.join("&");
  }

  private async fetch<T>(url: string, init: RequestInit & { headers: string[][]; }): Promise<T> {
    init.mode = "cors";
    init.credentials = <RequestCredentials>env.credentials || "same-origin";
    init.redirect = "follow";
    init.cache = "no-store";

    let request = new Request(url, init);
    console.log("->", new Request(url, init));
    this.notify.spinner(1);
    try {
      let response = await fetch(request);
      console.log("<-", response);
      if (response.status === 200) {
        let data = await response.text();
        this.notify.spinner(-1);
        return JSON.parse(data, this.parseDate);
      }
      if (response.status === 204) {
        this.notify.spinner(-1);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore When server is expected to return 204, T=void (no body). TS can't prove this, though.
        return;
      }
      throw response;
    }
    catch (response: any) {
      this.notify.spinner(-1);
      /* This code path must result in failed promise.
       * If it is a 403, we render a prettier error.
       * Otherwise, we give a more technical error. */
      if (response.status === 403 && response.json) {
        response.text().then((dataStr: string) => {
          let data = JSON.parse(dataStr, this.parseDate);
          this.notify.info(data.message, data.args);
        });
      } else if (response.status && response.text) {
        response.text().then((reason: any) => {
          this.notify.info("client." + response.status, { reason });
        });
      } else {
        this.notify.info("client.unknown", { response });
      }
      throw response;
    }
  }

  parseDate(key: string, value: any) {
    if (dates[key] && value) {
      if (value instanceof Array) {
        return value.map(x => new Date(x));
      }
      return new Date(value);
    } else {
      return value;
    }
  }

  doGet<T>(url: string, params: { [key: string]: any; }): Promise<T> {
    let fullUrl = this.getUrl(url, params);
    return this.fetch(fullUrl, {
      headers: [],
      method: "get",
    });
  }

  getUrl(url: string, params: { [key: string]: any; }): string {
    let arg = this.encode(params);
    if (arg) {
      url += "?" + arg;
    }
    return env.server + url;
  }

  doPut<T>(url: string, params: { [key: string]: any; }): Promise<T> {
    return this.fetch(env.server + url, {
      headers: [
        ["Content-Type", "application/json"],
      ],
      method: "put",
      body: JSON.stringify(params),
    });
  }
}
