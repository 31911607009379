import { I18N } from 'aurelia-i18n';
import { FormatShortDateValueConverter } from 'resources/value-converters/format-short-date';
import { ClientHealthAndRepositories, Health } from "utils/api";

const formatShortDate = new FormatShortDateValueConverter;

export type ClientAbnormalState = "error" | "alarm" | "down";

export interface HealthStatusResponse {
  status?: ClientAbnormalState;
  message: string;
}

export class HealthUtil {
  formatShortDate = new FormatShortDateValueConverter;

  /** Generate both status and full error message from health-data */
  public static generateStatusAndWarnings(i18n: I18N, chr: ClientHealthAndRepositories): HealthStatusResponse {
    let hs: HealthStatusResponse = {
      status: undefined,
      message: "",
    };
    const health = chr.health
    if (health) {
      this.detectStaleHealth(chr);

      if (health.warningReasons?.length == 0 && health.state === "DOWN") {
        health.warningReasons.push("STATE_IS_DOWN");
      }
      // Check 5 min load average
      if (parseFloat(health.loadavg[1] || "") > 0.9) {
        health.warningReasons.push("LOAD_AVG_HIGH");
      }
      hs.message = this.generateWarningReasonWarnings(i18n, health);
      if (hs.message) { hs.message += "\n\n"; }
      hs.message += this.generateQueueWarnings(i18n, chr);
      if (hs.message) { hs.message += "\n\n"; }
      hs.message += this.generateBackgroundTaskWarnings(i18n, chr);
    }
    this.deduceStatus(i18n, hs, chr);
    return hs;
  }

  /** Client-side only check, if unable to update health for whatever reason, e.g. bad payment-terminal-data */
  private static detectStaleHealth(chr: ClientHealthAndRepositories): void {
    if (!chr.health) {
      return;
    }
    const modifyTime = chr.health?.modifyTime.getTime();
    const modifyTimeThreshold = (new Date()).getTime() - 180_000;

    if (modifyTime && modifyTime < modifyTimeThreshold ) {
      chr.health.warningReasons.unshift('HEALTH_TOO_OLD');
    }
  }

  /** Deduce status from state and full error message */
  private static deduceStatus(i18n: I18N, hs: HealthStatusResponse, chr: ClientHealthAndRepositories) {
    const monitoredTasks = chr.tasks || [];
    const warningReasons = chr.health?.warningReasons || [];
    if (chr.health?.state === "DOWN") {
      hs.status = "down";
    } else if (chr.health?.error || !["DOWN", "UP", ""].includes(chr.health?.state ?? "")) {
      hs.status = "error";
    } else if (warningReasons.length || monitoredTasks.filter(x => x.issues).length) {
      hs.status = "alarm";
    }
  }

  /** Generate warningreason list */
  private static generateWarningReasonWarnings(i18n: I18N, health?: Health): string {
    if (!health) {
      return "";
    }
    let result = "";
    result += health.error ? "Error:\n" + health.error + "\n\n" : "";
    result += health.warningReasons?.length > 0 ? "Warnings:" : "";
    if (health.warningReasons) {
      health.warningReasons.forEach(x => {
        let tr = i18n.tr("WarningReason." + x, {});
        result += "\n" + tr;
      });
    }
    return result;
  }

  /** Generate backGroundtaskWarnings */
  private static generateBackgroundTaskWarnings(i18n: I18N, chr: ClientHealthAndRepositories): string {
    const monitoredTasks = chr.tasks?.filter(x => x.issues) || [];
    if (!monitoredTasks.length) {
      return "";
    }
    let result = "";
    for (const task of monitoredTasks) {
      if (result) { result += "\n"; }
      result += i18n.tr("PosTaskType." + task.type) + ":\n";
      result += formatShortDate.toView(task.eventTime) + "\n";
      result += task.issues + "\n\n";
    }
    return result;
  }

  /** Generate Queue warnings */
  private static generateQueueWarnings(i18n: I18N, chr: ClientHealthAndRepositories, ): string {
    let result = "";
    let health = chr.health;
    if (!health) {
      return result;
    }
    let queue = health.printTimes || [];
    result += this.handleQueue(i18n, queue, "printQ");

    queue = health.terminalTimes || [];
    result += this.handleQueue(i18n, queue, "terminalQ");

    queue = health.emailTimes || [];
    result += this.handleQueue(i18n, queue, "emailQ");

    queue = health.authorizationHoldTimes || [];
    result += this.handleQueue(i18n, queue, "authorizationHoldQ");

    queue = health.receiptHeroTimes || [];
    result += this.handleQueue(i18n, queue, "receiptHeroQ");

    queue = health.dashboardControlledItemTimes || [];
    result += this.handleQueue(i18n, queue, "dashboardControlledItemQ");

    if (health.warningReasons.includes("EXTERNAL_NETTIKAUPPA_COMPLETED_SLOW")) {
      result += i18n.tr("client.externalNettikauppaLastCompletedTime") + "\n";
      const enkStatus = chr.status.filter(x => x.type === 'EXTERNAL_NETTIKAUPPA');
      for (const location of enkStatus) {
        if (location.eventTime) {
          const lastCompletedTime = new Date("" + location.eventTime);
          const now = Date.now();
          if (lastCompletedTime && (now - 15 * 60 * 1000) > lastCompletedTime.getTime()) {
            result += location.objectId + ": " + formatShortDate.toView(lastCompletedTime) + "\n";
          }
        }
      }
    }
    return result;
  }

  /** Handle one queue-item */
  private static handleQueue(i18n: I18N, queue: Date[], trKey: string) {
    let result = "";
    if (queue.length > 0) {
      if (result) { result += "\n"; }
      result += i18n.tr("client." + trKey) + ": \n";
      queue.forEach(x => {
        result += formatShortDate.toView(x) + "\n";
      });
    }
    return result;
  }
}
